import Api from "../services/Api"
import {ref} from "vue"

export default {
    namespaced: true,
    state: {
        data: ref({}),
    },
    getters: {
        getData: (state) => {
            return state.data
        },
    },
    mutations: {
        setData: (state, payload) => {
            state.data = payload["data"]
        },
    },
    actions: {
        setData(state, payload) {
            return new Promise((resolve) => {
                Api.getPoint(payload["ids"], payload["typeId"]).then((resp) => {
                    state.commit('setData', {
                        type: "post",
                        data: resp,
                    })
                }).finally(() => {
                    resolve();
                })
            });
        }
    },
}
