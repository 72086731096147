import RouteRegister from "@/plugins/router/services/RouteRegister"
import routers from '@/modules/promopushka/routers'
import "@/modules/promopushka/styles.css"

export default {
    install: (app) => {
        RouteRegister.handle(routers)
        app.use();
    }
}
