<template>
  <Posts :object="object" :params="params" />
</template>

<script>
import {defineComponent} from "vue"
import Posts from "@/modules/post/pages/posts/components/posts/Posts"
import Drafts from "@/modules/post/pages/posts/services/Drafts"

export default defineComponent({
  name: "Drafts",
  components: {
    Posts
  },
  setup() {
    let object = new Drafts()

    let params = {
      title: "Все черновики",
      h1: "Черновики",
      page: "drafts",
    }

    return {
      object,
      params,
    }
  }
})
</script>

<style scoped>

</style>
