import Api from "../services/Api"
import {ref} from "vue"

export default {
    namespaced: true,
    state: {
        data: ref({}),
    },
    getters: {
        getData: (state) => (type) => {
            return state.data[type]
        },
    },
    mutations: {
        setData: (state, payload) => {
            state.data[payload["type"]] = payload["data"]
        },
    },
    actions: {
        setData(state, payload) {
            let orders = new Promise((resolve) => {
                Api.orders(payload["ids"], payload["typeId"]).then((resp) => {
                    state.commit('setData', {
                        type: "post",
                        data: resp,
                    })
                }).finally(() => {
                    resolve();
                })
            });

            let chains = new Promise((resolve) => {
                Api.chainOrders(payload["ids"], payload["typeId"]).then((resp) => {
                    state.commit('setData', {
                        type: "chain",
                        data: resp,
                    })
                }).finally(() => {
                    resolve();
                })
            });

            return Promise.all([orders, chains])
        }
    },
}
