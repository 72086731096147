<template>
  <div id="categories-list-component">
    <div class="cards row row-cards">
      <template v-if="isNotFound(items) && !busy">
        <span class="items-not-found">Ничего не найдено</span>
      </template>
      <template v-else>
        <div class="col-lg-12">
          <div class="card">
            <div class="table-responsive">
              <table class="table table-vcenter card-table">
                <thead>
                <tr>
                  <th>Название</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in items" :key="row.getId(item)">
                  <td>
                    <a href="#" data-bs-toggle="modal" data-bs-target="#modal-category" data-type="update" :data-id="row.getId(item)">
                      <IconEdit class="me-2 text-muted" />
                      {{ row.getTitle(item) }}
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
      <div class="loader-posts">
        <div class="spinner-border" v-show="busy"></div>
      </div>
    </div>
  </div>
</template>

<script>
import IconEdit from '@/modules/shops/icons/IconEdit.vue'
import {defineComponent, ref, watch} from "vue"
import element from "@/modules/shops/pages/categories/components/list/services/element"
import Helper from "@/services/Helper";
import Api from "@/modules/shops/pages/categories/services/Api"

export default defineComponent({
  name: "List",
  components: {
    IconEdit,
  },
  props: {
    query: {
      type: Object,
      required: true
    },
    clearResult: {
      type: Boolean,
      required: true
    }
  },
  setup(props, {emit}) {
    let busy = ref(false)
    let items = ref([])
    let eofItems = ref(false)
    let countItems = ref(0)

    let row = {
      getId: (item) => {
        return element.getId(item)
      },

      getTitle: (item) => {
        return element.getTitle(item)
      },
    }

    function isNotFound(items) {
      return Helper.isEmptyObject(items)
    }

    watch(() => props.query, (query) => {
      busy.value = true

      if (props.clearResult) {
        clearResult()
      }

      load(query)
    })

    function clearResult() {
      eofItems.value = false
      countItems.value = 0
      items.value = []
    }

    function load(query, offset = null) {
      if (offset !== null) {
        query.offset = offset;
      }

      Api.items(query).then((resp) => {
        let data = resp.data

        items.value.push(...data.items)

        if (data.items.length === 0) {
          eofItems.value = true
        }

        countItems.value = data.count
      }).finally(() => {
        busy.value = false

        emit("onRender", {
          "oef_items": eofItems.value,
          "count_items": countItems.value
        })
      })
    }

    return {
      busy,
      items,
      row,
      isNotFound,
    }
  }
})
</script>
