export default {
    validations: {
        required: "Заполните это поле",
        //required: "Поле {property} обязательно",
        //email: "Field {attribute} is not a proper email address"
        between: "Должно быть от {min} до {max}",
        url: "Это поле должно быть url адресом",
        fileSize: "Картинка должна не превышать {maxFileSizeInMB} мб.",
    }
}
