<template>
  <div>
    <metainfo></metainfo>

    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row g-2 align-items-center">
          <div class="col">
            <Title :title="params.title" :count="countUsers" />
          </div>
        </div>
      </div>
    </div>

    <div class="page-body">
      <div class="container-xl ">
        <div class="mb-3">
          <Filter @onFilter="onFilter" :enable="filter.enable" :params="filter.params" />
        </div>

        <div ref="scrollComponent">
          <List :query="query" @onRender="onRender" :clear-result="clearResult" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from "vue"
import Title from "@/modules/user/components/Title"
import Filter from "@/modules/user/components/filter/Index"
import List from "@/modules/user/components/list/Index"
import Helper from "@/services/Helper"
import {useMeta} from "vue-meta"

export default defineComponent({
  name: "Users",
  components: {
    Title,
    Filter,
    List
  },
  setup() {
    let params = {
      title: "Пользователи",
      page: "users",
    }
    useMeta({title: params.title})

    let filter = {
      params: {
        user_name: '',
        user_email: '',
        user_login: '',
        permission_ids: [],
        user_active: true,
        user_fantom: false,
      },
      enable: true,
    }

    let query = ref({})
    let clearResult = ref(false)
    let offset = ref(0)
    let eofResult = ref(false)
    let countUsers = ref(0)
    let isLoading = ref(false)
    const scrollComponent = ref(null)

    function onFilter(q) {
      clearResult.value = true
      offset.value = 0
      eofResult.value = false
      countUsers.value = 0

      q = Helper.mergeObject(q, {
        "limit": 20,
        "offset": offset.value,
      })

      query.value = q
    }
  
    function onLoadMore(offset) {
      if (Helper.isEmptyObject(query.value)) return false;

      let q = Helper.clone(query.value)

      query.value = Helper.mergeObject(q, {
        "offset": offset,
      })
    }

    function onRender(payload) {
      eofResult.value = payload["oef_items"] === true
      if (payload["count_items"] > 0) {
        countUsers.value = payload["count_items"];
      }
      clearResult.value = false
      isLoading.value = false
    }

    onMounted(() => {
      window.addEventListener("scroll", handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll)
    })

    let scroll = () => {
      isLoading.value = true
      offset.value += 20

      onLoadMore(offset.value)
    }

    const handleScroll = () => {
      if (eofResult.value) return

      let element = scrollComponent.value

      if (!isLoading.value && element.getBoundingClientRect().bottom < window.innerHeight) {
        scroll()
      }
    }

    return {
      params,
      filter,
      onFilter,
      query,
      clearResult,
      onRender,
      scrollComponent,
      countUsers
    }
  },
})
</script>
