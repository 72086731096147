import {createStore} from "vuex"

const index = createStore({
    state: {
        loadingApp: false,
        common: {},
    },
    getters: {
        get: (state) => (property) => {
            return state[property]
        }
    },
    mutations: {
        set(state, [property, value]) {
            state[property] = value
        }
    },
    actions: {
        set(state, [property, value]) {
            state.commit("set", [property, value])
        }
    },
})

export default index
