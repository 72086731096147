<template>
  <div class="statistic" v-if="isView()">
    <div class="statistic-item badge bg-green" v-if="hasValue(countClick)">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-pointer" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M7.904 17.563a1.2 1.2 0 0 0 2.228 .308l2.09 -3.093l4.907 4.907a1.067 1.067 0 0 0 1.509 0l1.047 -1.047a1.067 1.067 0 0 0 0 -1.509l-4.907 -4.907l3.113 -2.09a1.2 1.2 0 0 0 -.309 -2.228l-13.582 -3.904l3.904 13.563z"></path>
      </svg>
      <span class="count">{{ countClick }}</span>
    </div>
    <div class="statistic-item badge bg-green" v-if="hasValue(countSell)">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-shopping-cart" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
        <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
        <path d="M17 17h-11v-14h-2"></path>
        <path d="M6 5l14 1l-1 7h-13"></path>
      </svg>
      <span class="count">{{ countSell }}</span>
    </div>
    <div class="statistic-item badge bg-yellow" v-if="hasValue(countChain)">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-share" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M6 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
        <path d="M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
        <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
        <path d="M8.7 10.7l6.6 -3.4" />
        <path d="M8.7 13.3l6.6 3.4" />
      </svg>
      <span class="count">{{ countChain }}</span>
    </div>
  </div>
</template>

<script>
import {defineComponent, watch, ref, toRaw} from "vue"
import StoreRegister from "@/plugins/store/services/StoreRegister"
import stores from "./stores"
import {
  getData as statisticGetData, storeName as statisticStoreName
} from "./services/statistic"

StoreRegister.handle(statisticStoreName, stores)

export default defineComponent({
  name: "StatisticSale",
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    let countClick = ref(0)
    let countSell = ref(0)
    let countChain = ref(0)

    watch(() => statisticGetData("post"), (val) => {
      render("post", toRaw(val))
    })

    watch(() => statisticGetData("chain"), (val) => {
      render("chain", toRaw(val))
    })

    function render(type, payload) {
      if (type === "post" && payload[props.data["post_id"]] !== undefined) {
        countClick.value = payload[props.data["post_id"]]["count_click"]
        countSell.value = payload[props.data["post_id"]]["count_sell"]
      }

      if (type === "chain" && payload[props.data["post_id"]] !== undefined) {
        countChain.value = payload[props.data["post_id"]]["count"]
      }
    }

    function isView() {
      return countClick.value !== 0 || countSell.value !== 0
    }

    function hasValue(val) {
      return val !== 0
    }

    return {
      countClick,
      countSell,
      countChain,
      isView,
      hasValue,
    }
  }
})
</script>

<style scoped src="./css/style.css"></style>