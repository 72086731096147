export default class Url {
    constructor(link) {
        this.url = new URL(link)
    }

    getPathName() {
        return this.url.pathname
    }

    getPart(num) {
        let path = this.getPathName().split("/")

        if (path.length === 0) {
            return null
        }

        if (num > path.length) {
            return null
        }

        return path[num]
    }

    getLastPart() {
        let path = this.getPathName().split("/")

        if (path.length === 0) {
            return null
        }

        return path[path.length - 1]
    }
}
