<template>
  <div>
    <router-link :to="{name: 'Post', params: {id: getPostId()}}" class="btn btn-primary">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
        <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
      </svg>
      Смотреть
    </router-link>
  </div>
</template>

<script>
import {defineComponent} from "vue"

export default defineComponent({
  name: "ViewBtn",
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    function getPostId() {
      return props["data"]["post_id"];
    }

    return {
      getPostId,
    }
  },
})
</script>

<style scoped>

</style>
