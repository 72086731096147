<template>
  <div class="card" id="scheduled-removed">
    <div class="card-body">
      <div class="divide-y">
        <label class="row">
          <span class="col">
            <span class="title">Автоматическое удаление</span>
          </span>
          <span class="col-auto">
            <label class="form-check form-check-single form-switch">
              <input class="form-check-input" type="checkbox" v-model="isEnable">
            </label>
          </span>
        </label>
      </div>
      <template v-if="isEnable">
        <div class="input-icon mb-2 mt-2">
          <Datepicker auto-apply @update:modelValue="onChangeDate" v-model="fields.date" locale="ru" format="dd.MM.yyyy" placeholder="Выбрать дату" :enable-time-picker="false" @cleared="onClear" :class="{'is-invalid': v$.date.$invalid && v$.date.$dirty}"/>
          <div class="invalid-feedback" v-for="error of v$.date.$silentErrors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
        <template v-if="isTimeShow()">
          <div>
            <label class="form-label">Выбор времени</label>
            <div class="row g-2">
              <div class="col-6">
                <div class="row g-2">
                  <div class="col-12">
                    <select class="form-select" v-model="v$.hours.$model" @change="onChangeHours" :class="{'is-invalid': v$.hours.$invalid && v$.hours.$dirty}">
                      <Hours />
                    </select>
                    <div class="invalid-feedback" v-for="error of v$.hours.$silentErrors" :key="error.$uid">
                      {{ error.$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <select class="form-select" v-model="v$.minutes.$model" @change="onChangeMinutes" :class="{'is-invalid': v$.minutes.$invalid && v$.minutes.$dirty}">
                  <Minutes />
                </select>
                <div class="invalid-feedback" v-for="error of v$.minutes.$silentErrors" :key="error.$uid">
                  {{ error.$message }}
                </div>
              </div>
            </div>
          </div>
        </template>

        <span :class="{'is-invalid': v$.scheduled_removed.$invalid && v$.scheduled_removed.$dirty}"></span>
        <div class="invalid-feedback" v-for="error of v$.scheduled_removed.$silentErrors" :key="error.$uid">
          {{ error.$message }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import {defineComponent, ref, watch, reactive, onMounted} from "vue"
import Datepicker from "@/plugins/datepicker"
import DT from "@/services/DT"
import Helper from "@/services/Helper"
import {useVuelidate} from "@vuelidate/core"
import {required, between, minLength} from "@/plugins/vuelidator"
import Hours from "./components/hours/Hours"
import Minutes from "./components/minutes/Minutes"

export default defineComponent({
  name: "ScheduledRemoved",
  components: {
    Datepicker,
    Hours,
    Minutes,
  },
  props: {
    datetime: {
      type: String,
      default: null,
    },
    validations: {
      //type: Object,
    },
  },
  setup(props, {emit}) {
    let isEnable = ref(false)

    let datetime = ref(props.datetime)

    let $externalResults = reactive({})

    const fields = reactive({
      scheduled_removed: null,
      date: null,
      hours: null,
      minutes: null,
    })

    const rules = {
      scheduled_removed: {
        minLength: minLength(0)
      },
      date: {},
      hours: {
        required,
        between: between(0, 23)
      },
      minutes: {
        required,
        between: between(0, 59)
      },
    }

    const v$ = useVuelidate(rules, fields, {$externalResults})

    onMounted(() => {
      if (datetime.value === null) {
        return false
      }

      enableStatus()
      initDate()
    })
    
    watch(() => props.validations, (val) => {
      validate(() => {
        if (val?.scheduled_removed !== undefined) {
          $externalResults.scheduled_removed = val["scheduled_removed"][0]
        }
      })
    })

    function enableStatus() {
      isEnable.value = true
    }

    function initDate() {
      let d = new DT(datetime.value)

      fields.date = new Date(d.getFullYear(), d.getMonthNatural(), d.getDay(), 0, 0, 0)
      fields.hours = d.getHour()
      fields.minutes = d.getMinutes()
    }

    watch(() => isEnable.value, (status) => {
      if (status === false) {
        resetAllFields()
        onChangeDatetime(false)
      }
    })

    function onClear() {
      resetTime()
      onChangeDatetime(false)
    }

    function resetAllFields() {
      resetDate()
      resetTime()
    }

    function resetDate() {
      fields.date = null
    }

    function resetTime() {
      fields.hours = null
      fields.minutes = null
    }

    function isTimeShow() {
      return fields.date !== null
    }

    function onChangeDate(val) {
      fields.date = val
      onChangeDatetime()
    }

    function onChangeHours(e) {
      fields.hours = e.target.value
      onChangeDatetime()
    }

    function onChangeMinutes(e) {
      fields.minutes = e.target.value
      onChangeDatetime()
    }

    function onChangeDatetime(checkValidate = true) {
      if (checkValidate === true) {
        validate(change)
      } else {
        change()
      }
    }

    function validate(fn) {
      v$.value.$clearExternalResults()
      return v$.value.$validate().then(() => {
        fn()
      })
    }

    function change() {
      if (fields.date === null) {
        emit("onChange", null)
      } else if (Helper.isInt(fields.hours) && Helper.isInt(fields.minutes)) {
        let datetime = new DT(fields.date).startTimeOfDay()

        datetime.setHour(fields.hours)
        datetime.setMinute(fields.minutes)
        datetime.setSec(0)

        emit("onChange", datetime.get("%Y-%M-%D %H:%I:%S"))
      }
    }

    return {
      isEnable,
      onClear,
      isTimeShow,
      onChangeDate,
      onChangeHours,
      onChangeMinutes,
      v$,
      fields,
    }
  }
})
</script>

<style scoped>

</style>
