<template>
    <div class="modal show" @click.self="close" :style="{ display: 'block', background: 'rgba(0,0,0,0.5)'}" id="user-edit">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <template v-if="getUser()">
                    <div class="modal-header">
                        <h5 class="modal-title">Пользователь: <span v-text="getUser().name"/></h5>
                        <h6 class="modal-subtitle mb-0">Зарегистрирован: <span v-text="getDate(getUser().created)"/></h6>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click.self="close"></button>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <ul class="nav nav-tabs card-header-tabs" data-bs-toggle="tabs">
                                <li class="nav-item">
                                    <button class="nav-link active" id="tabs-profile" data-bs-toggle="tab" data-bs-target="#tabs-profile-pane" type="button" role="tab" aria-controls="tabs-profile-pane" aria-selected="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon me-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <circle cx="12" cy="7" r="4" />
                                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                        </svg>
                                        Профиль
                                    </button>
                                </li>
                                <li class="nav-item">
                                    <button class="nav-link" id="tabs-permissions" data-bs-toggle="tab" data-bs-target="#tabs-permissions-pane" type="button" role="tab" aria-controls="tabs-permissions-pane" aria-selected="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-manual-gearbox me-2">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M5 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                            <path d="M12 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                            <path d="M19 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                            <path d="M5 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                            <path d="M12 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                            <path d="M5 8l0 8" />
                                            <path d="M12 8l0 8" />
                                            <path d="M19 8v2a2 2 0 0 1 -2 2h-12" />
                                        </svg>
                                        Разрешения
                                    </button>
                                    </li>
                                <li class="nav-item ms-auto">
                                    <button class="nav-link" id="tabs-security" data-bs-toggle="tab" data-bs-target="#tabs-security-pane" type="button" role="tab" aria-controls="tabs-security-pane" aria-selected="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-fingerprint">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M18.9 7a8 8 0 0 1 1.1 5v1a6 6 0 0 0 .8 3" />
                                            <path d="M8 11a4 4 0 0 1 8 0v1a10 10 0 0 0 2 6" />
                                            <path d="M12 11v2a14 14 0 0 0 2.5 8" />
                                            <path d="M8 15a18 18 0 0 0 1.8 6" />
                                            <path d="M4.9 19a22 22 0 0 1 -.9 -7v-1a8 8 0 0 1 12 -6.95" />
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div class="card-body">
                            <div class="tab-content">
                                <div class="tab-pane fade active show" id="tabs-profile-pane" role="tabpanel" aria-labelledby="tabs-profile" tabindex="0">
                                    <Profile :user="getUser()" />
                                </div>
                                <div class="tab-pane fade" id="tabs-permissions-pane" role="tabpanel" aria-labelledby="tabs-permissions" tabindex="0">
                                    <Permissions :user="getUser()" />
                                </div>
                                <div class="tab-pane fade" id="tabs-security-pane" role="tabpanel" aria-labelledby="tabs-security" tabindex="0">
                                    <Security :user="getUser()" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="loader-posts p-5 text-center">
                        <div class="spinner-border"></div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>


<script>
import {defineComponent, onMounted, ref} from "vue"
import Profile from "@/modules/user/components/edit/components/Profile"
import Permissions from "@/modules/user/components/edit/components/Permissions"
import Security from "@/modules/user/components/edit/components/Security"
import Api from "@/modules/user/components/edit/services/Api"
import DTBackend from "@/services/DTBackend"
  
export default defineComponent({
    name: "Edit",
    components: {
        Profile,
        Permissions,
        Security
    },
    props: {
        user: {
            type: Number,
            required: true
        }
    },
    setup(props, {emit}) {
        let user = ref(false)
        const body = document.getElementsByTagName('body')[0];

        onMounted(() => {
            setUser()
            body.classList.add("overflow-hidden");
        })

        function setUser() {
            Api.user(props.user).then((resp) => {
                user.value = resp.item
            })
        }

        function getUser() {
            return user.value
        }

        function close() {
            if (confirm('Вы уверены что хотите закрыть окно?')) {
                body.classList.remove("overflow-hidden");
                emit('close')
            }
        }

        function getDate(date) {
            let result, dt, dateAt = date

            dt = new DTBackend(dateAt)
            result = dt.get("%D.%M.%y %H:%I")

            return result
        }

      
        return {
            close,
            getUser,
            getDate,
        }
    }
})
</script>
  
<style scoped>
#user-edit .card-header {
    background: var(--tblr-card-cap-bg);
}
</style>
  