<template>
  <span class="form-label" v-if="process">
    Сохранение...
  </span>
  <span class="form-label" v-else>
    Сохранено
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M5 12l5 5l10 -10"></path>
    </svg>
  </span>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "ProcessSave",
  props: {
    process: {
      type: Boolean,
      default: false
    },
  },
  setup() {},
})
</script>

<style scoped>

</style>
