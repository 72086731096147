import PromopushkaAiBase from "@/modules/promopushka/services/ApiBase"

export default class Api {
    static items(query) {
        return PromopushkaAiBase.items(query).then((resp) => {
            return resp
        })
    }

    static users() {
        return PromopushkaAiBase.users().then((resp) => {
            return resp.data["items"]
        })
    }

    static shops() {
        return PromopushkaAiBase.shop().then((resp) => {
            return resp.data.data["items"]
        })
    }
}
