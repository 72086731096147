import $ from "../../../../packages/editor/src/components/dom";
/**
 *
 */
export default class moveDown {
    /**
     * MoveDownTune constructor
     *
     * @param {API} api — Editor's API
     */
    constructor({ api }) {
        /**
         * Styles
         *
         * @type {{wrapper: string}}
         */
        this.CSS = {
            button: 'ce-settings__item',
            wrapper: 'ce-tune-move-down',
            animation: 'wobble',
        };
        this.api = api;
    }
    /**
     * Return 'move down' button
     *
     * @returns {HTMLElement}
     */
    render() {
        let moveDownButton = this.createBtn();
        this.api.listeners.on(moveDownButton, 'click', (event) => this.handleClick(event, moveDownButton), false);
        /**
         * Enable tooltip module on button
         */
        this.api.tooltip.onHover(moveDownButton, this.api.i18n.t('Move down'), {
            hidingDelay: 300,
        });
        return moveDownButton;
    }
    createBtn() {
        const moveDownButton = $.make('div', [this.CSS.button, this.CSS.wrapper], {});
        let text = $.make('span', null, {
            innerHTML: this.api.i18n.t('Переместить вниз')
        });
        let icon = $.make('span', null, {
            innerHTML: '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
                '  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.707 20.707a1 1 0 01-1.414 0l-7-7a1 1 0 111.414-1.414L11 17.586V4a1 1 0 112 0v13.586l5.293-5.293a1 1 0 011.414 1.414l-7 7z" fill="#000"></path>' +
                '</svg>'
        });
        moveDownButton.appendChild(icon);
        moveDownButton.appendChild(text);
        return moveDownButton;
    }
    /**
     * Handle clicks on 'move down' button
     *
     * @param {MouseEvent} event - click event
     * @param {HTMLElement} button - clicked button
     */
    handleClick(event, button) {
        const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
        const nextBlock = this.api.blocks.getBlockByIndex(currentBlockIndex + 1);
        // If Block is last do nothing
        if (!nextBlock) {
            button.classList.add(this.CSS.animation);
            window.setTimeout(() => {
                button.classList.remove(this.CSS.animation);
            }, 500);
            return;
        }
        const nextBlockElement = nextBlock.holder;
        const nextBlockCoords = nextBlockElement.getBoundingClientRect();
        let scrollOffset = Math.abs(window.innerHeight - nextBlockElement.offsetHeight);
        /**
         * Next block ends on screen.
         * Increment scroll by next block's height to save element onscreen-position
         */
        if (nextBlockCoords.top < window.innerHeight) {
            scrollOffset = window.scrollY + nextBlockElement.offsetHeight;
        }
        window.scrollTo(0, scrollOffset);
        /** Change blocks positions */
        this.api.blocks.move(currentBlockIndex + 1);
        this.api.toolbar.toggleBlockSettings(true);
        /** Hide the Tooltip */
        this.api.tooltip.hide();
    }
}
/**
 * Set Tool is Tune
 */
moveDown.isTune = true;
