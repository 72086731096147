import Dashboard from "@/modules/post/pages/select_channel/SelectChannel"
import authMiddleware from "@/plugins/router/middlewares/auth"

export default [{
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
        middleware: authMiddleware,
    }
}]