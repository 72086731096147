import './common.css'

import './styles/block.css'
import './styles/inline-toolbar.css'
import './styles/paragraph.css'
import './styles/settings.css'
import './styles/toolbar.css'
import './styles/ui.css'

import {ref} from "vue"
//import cfg from "@/services/Config"
import EditorJS from "@/packages/editor/dist/editor"
//import EditorJS from "@editorjs/editorjs"

import DeleteTune from "@/plugins/editor/tunes/delete"
import MoveUpTune from "@/plugins/editor/tunes/moveUp"
import MoveDownTune from "@/plugins/editor/tunes/moveDown"
//import ConvertToHeaderTune from "@/plugins/editor/tunes/convertToHeader"
//import ConvertToParagraphTune from "@/plugins/editor/tunes/convertToParagraph"

//import Image from "@/plugins/editor/plugins/image/dist/bundle"
//import Header from "@/plugins/editor/plugins/heading"
//import List from "@/plugins/editor/plugins/list/dist/bundle"
//import Composite from "@/plugins/editor/plugins/composite"

import CompositeInlineTool from "@/plugins/editor/inlines/composite"
import CodeInlineTool from "@/plugins/editor/inlines/code"
import StrikeInlineTool from "@/plugins/editor/inlines/strike"

export let statusSaving = ref(false)

export function statusOnSaving() {
    statusSaving.value = true;
}

export function statusOffSaving() {
    statusSaving.value = false;
}

const defaultOptions = {
    id: 'editorjs',
    data: {},
}

export const editor = (options = defaultOptions) => {
    return new EditorJS({
        autofocus: true,
        inlineToolbar: ['link', 'bold', 'italic', 'strike', 'composite', 'code'],
        placeholder: 'Начните писать...',
        defaultBlock: 'paragraph',

        onReady: () => options.onReady(),

        /**
         * Id of Element that should contain Editor instance
         */
        holder: options.id,

        /**
         * Available Tools list.
         * Pass Tool's class or Settings object for each Tool you want to use
         */
        tools: {
            //ConvertToHeaderTune,
            //ConvertToParagraphTune,

            paragraph: {
                //tunes: ['ConvertToHeaderTune'],
                config: {
                    preserveBlank: true,
                    tagsSeparateSpace: ["composite", "code"],
                }
            },

            delete: {
                class: DeleteTune,
                isInternal: true,
            },

            moveUp: {
                class: MoveUpTune,
                isInternal: true,
            },

            moveDown: {
                class: MoveDownTune,
                isInternal: true,
            },

            /*header: {
                class: Header,
                //tunes: ['ConvertToParagraphTune'],
                config: {
                    placeholder: 'Заголовок',
                    levels: [2],
                    defaultLevel: 2,
                },
            },*/

            /*list: {
                class: List,
                inlineToolbar: ['link', 'bold', 'italic'],
                config: {
                    defaultStyle: 'unordered'
                }
            },
*/
            composite: {
                class: CompositeInlineTool,
            },

            code: {
                class: CodeInlineTool,
            },

            strike: {
                class: StrikeInlineTool,
            },

            /*composite: {
                class: Composite,
                inlineToolbar: ['link', 'bold', 'italic'],
            },*/

            /*image: {
                class: Image,
                config: {
                    endpoints: {
                        byFile: cfg._('editor', 'api_address_upload_image'),
                    },
                    captionPlaceholder: 'Подпись для изображения'
                }
            }*/
        },

        /**
         * Internationalzation config
         */
        i18n: {
            /**
             * @type {I18nDictionary}
             */
            messages: {
                ui: {
                    "blockTunes": {
                        "toggler": {
                            "Click to tune": "Нажмите, чтобы настроить",
                            "or drag to move": "или перетащите"
                        },
                    },
                    "toolbar": {
                        "toolbox": {
                            "Filter": "Фильтр",
                            "Nothing found": "Ничего не найдено",
                            "Add": "Добавить"
                        }
                    }
                },

                /**
                 * Section for translation Tool Names: both block and inline tools
                 */
                toolNames: {
                    "Text": "Текст",
                    "Heading": "Заголовок",
                    "List": "Список",
                    //"Image": "Картинка",
                },

                tools: {
                    "link": {
                        "Add a link": "Вставьте ссылку"
                    },
                    "list": {
                        "Unordered": "Неупорядоченный",
                        "Ordered": "Упорядоченный",
                    },
                    "composite": {
                        "Add a link": "Ссылка композита",
                    },
                },

                /**
                 * Section allows to translate Block Tunes
                 */
                blockTunes: {
                    /**
                     * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
                     * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
                     *
                     * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
                     */
                    "delete": {
                        "Delete a block": "Удалить блок",
                        "Are you sure delete?": "Точно удалить?",
                    },
                    "moveUp": {
                        "Move up": "Переместить вверх"
                    },
                    "moveDown": {
                        "Move down": "Переместить вниз"
                    },
                    /*"ConvertToHeaderTune": {
                        "Make a header": "Сделать заголовком"
                    },*/
                    /*"ConvertToParagraphTune": {
                        "Make text": "Сделать текстом"
                    },*/
                },
            }
        },

        tunes: [
            //ConvertToHeaderTune
        ],

        /**
         * Previously saved data that should be rendered
         */
        data: options.data || {},

        onChange(data) {
            //console.log(data)
            //data.blocks.clear()
            //let h = data.selection.findParentTag('COMPOSITE')
            //console.log(h.innerHTML)

            //console.log(data);
            // pass in function from component to run on change
            options.onChange(data)
        }
    })
};

export default {editor, statusOnSaving, statusOffSaving, statusSaving}
