import Image from "@/services/Image"

export default class PostImage {
    static handle(
        host,
        name,
        w = null,
        h = null,
        hasVideo = false,
    ) {
        if (host && name) {
            return Image.handle(host, name, w, h)
        } else {
            return PostImage.default(hasVideo)
        }
    }

    static default(hasVideo) {
        if (hasVideo) {
            return "/assets/static/post/no_image_video.jpg"
        } else {
            return "/assets/static/post/no_image.jpg"
        }
    }
}
