<template>
  <div class="navbar-nav flex-row order-md-last" v-if="isAuth">
    <div class="nav-item dropdown">
      <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
        <span class="avatar avatar-sm" :style="{ backgroundImage: 'url('+ getImage(user['avatar']['host'], user['avatar']['name'], 64) +')'}"></span>
        <div class="d-none d-xl-block ps-2">
          <div>{{ user['name'] }}</div>
          <div class="mt-1 small text-muted">{{ user['role'] }}</div>
        </div>
      </a>
      <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
        <div class="datagrid-content">
          <a href="#" @click.stop.prevent="onLogout" class="dropdown-item">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-logout me-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"></path>
              <path d="M7 12h14l-3 -3m0 6l3 -3"></path>
            </svg>
            Выход
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthStore from "@/modules/auth/services/Store"
import {reactive, ref} from "vue"
import Loader from "@/services/Loader"
import Auth from "@/modules/auth/services/Auth"
import {useRouter} from "vue-router/dist/vue-router"
import Image from "@/services/Image"

export default {
  name: "UserWindow",
  setup() {
    const isAuth = ref(AuthStore.isAuth())

    const user = reactive(AuthStore.getUserData());

    const router = useRouter()

    function onLogout() {
      if (confirm("Вы действительно хотите выйти?")) {
        Loader.start();

        Auth.logout().then(() => {
          router.push({name: "Login"})
        }).finally(() => {
          Loader.complete();
        })
      }
    }

    function getImage(host, name, w = null, h = null) {
      return Image.handle(host, name, w, h)
    }

    return {
      user,
      onLogout,
      isAuth,
      getImage,
    };
  }
}
</script>

<style scoped>

</style>