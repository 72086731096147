<template>
  <div id="post-channel">
    <span v-html="getTitle()"></span>
  </div>
</template>

<script>
import "./css/style.css"
import {defineComponent, onMounted, ref, watch} from "vue"
import Image from "@/services/Image"
import {getChannels} from "@/modules/post/plugins/title/services/channels"
import {useRouter} from "vue-router/dist/vue-router"

export default defineComponent({
  name: "Channel",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    let titleChannel = ref("")
    let channels = ref(null)
    const router = useRouter()

    watch(() => getChannels(), (val) => {
      setChannels(val)
      renderTitle()
    })

    onMounted(() => {
      setChannels(getChannels())
      renderTitle()
    })

    function setChannels(val) {
      channels.value = val
    }

    function renderTitle() {
      loadTitleChannel(props.data["channel_id"])
    }

    function loadTitleChannel(channelId) {
      if (channels.value === null) {
        return false
      }

      let result = channels.value[channelId]

      if (result["image_host"] === null || result["image_name"] === null) {
        throw Error("Отсутствует иконка")
      }

      let src = Image.handle(result["image_host"], result["image_name"], 30, 30);

      let href = router.resolve({
        name: 'Posts',
        params: {
          channel_id: channelId,
        },
      }).href

      let img = `<img width="20" class="rounded" src="${src}" alt="">`
      let link = `<a href="${href}">${result["title"]}</a>`

      titleChannel.value = img + " " + link
    }

    function getTitle() {
      let result = ""

      if (titleChannel.value) {
        result += titleChannel.value
      }

      return result
    }

    return {
      getTitle,
    }
  }
})
</script>

<style scoped></style>