import PostApiBase from "@/modules/post/services/ApiBase"

export default class Api {
    static item(postId) {
        return PostApiBase.post({
            post_id: postId
        }).then((resp) => {
            return resp.data["item"]
        })
    }

    static saveDraft(q) {
        return PostApiBase.saveDraft(q).then((resp) => {
            return resp.data["item"]
        })
    }

    static createDraft(q) {
        return PostApiBase.createDraft(q).then((resp) => {
            return resp.data["id"]
        })
    }

    static published(q) {
        return PostApiBase.published(q)
    }

    static scheduled(q) {
        return PostApiBase.scheduled(q)
    }

    static save(q) {
        return PostApiBase.save(q)
    }

    static saveDelete(q) {
        return PostApiBase.saveDelete(q)
    }

    static chainBindInPost(thisObjectId, thatObjectId, thisTypeId, thatTypeId) {
        return PostApiBase.chainBindInPost({
            this_object_id: thisObjectId,
            that_object_id: thatObjectId,
            this_type_id: thisTypeId,
            that_type_id: thatTypeId,
        }).then((resp) => {
            return resp.data
        })
    }
}
