import PostApiBase from "@/modules/post/services/ApiBase";

export default {
    namespaced: true,
    state: {
        channels: null,
    },
    getters: {
        getChannels: (state) => {
            return state.channels
        },
    },
    mutations: {
        setChannels: (state, payload) => {
            state.channels = payload
        },
    },
    actions: {
        setChannels(state) {
            return new Promise((resolve) => {
                PostApiBase.channels({}).then((resp) => {
                    state.commit('setChannels', resp.data["items"])
                    resolve();
                });
            });
        }
    },
}
