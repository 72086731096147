export default {
    getPostId: (item) => {
        return item["post_id"]
    },
    getPostPublishedAt: (item) => {
        return item["post_published_at"]
    },
    getPostContent: (item) => {
        return item["post_content"]
    },
    getPostChannelImageHost: (item) => {
        return item["post_channel_image_host"]
    },
    getPostChannelImageName: (item) => {
        return item["post_channel_image_name"]
    },
    getPostUserAvatarHost: (item) => {
        return item["post_user_avatar_host"]
    },
    getPostUserAvatarName: (item) => {
        return item["post_user_avatar_name"]
    },
    getPostAuthorName: (item) => {
        return item["post_username"]
    },
    getPostStatus: (item) => {
        return item["post_status"]
    },
    getShow: (item) => {
        return item["show"]
    },
    setShow: (item, val) => {
        item["show"] = val
    },
    getPostChannelName: (item) => {
        return item["post_channel_name"]
    },
    getObjectTypeId: (item) => {
        return item["object_type_id"]
    },
}
