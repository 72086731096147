<template>
  <metainfo></metainfo>

  <div id="post-card">
    <template v-if="isReadyPage()">
      <div class="container-xl">
        <div class="page-header d-print-none">
          <div class="row g-2 align-items-center">
            <div class="col-sm">
              <h2 class="page-title">
                <Title :data="{
                  post_id: getPostId(),
                  channel_id: bootData.channel_id,
                  status: bootData.status,
                  scheduled_published: bootData.scheduled_published
                }" @onCountSellStatistic="onCountSellTitle" @onClickChainStatistic="onClickChainTitle"
                />
              </h2>
            </div>

            <div class="col-auto">
              <div class="me-1">
                <UpdateBtn :data="{
                  post_id: getPostId(),
                  post_channel_id: bootData.channel_id,
                }" />
              </div>
            </div>

            <div class="col-auto" v-if="isShowPostInChain()">
              <div class="me-1">
                <PostInChain :post-id="chainObject.getId()" :type-id="chainObject.getTypeId()" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-body">
        <div class="container-xl">
          <div class="row">
            <div class="col-md-8 mb-3">
              <div class="mb-3">
                <Transactions :data="transactions" />
              </div>

              <div class="chat">
                <div class="message">
                  <div class="content" :class="{ 'img-lower': data.getImagePosition() === 'Внизу' }">
                    <div class="img-ctn">
                      <div class="img-lower-ctn">
                        <Lightbox :images="{ thumb: data.getImage(500), origin: data.getImage() }"></Lightbox>
                      </div>
                    </div>
                    <div class="text" v-html="data.getContent()"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <div class="mb-3">
                <Info :post-id="getPostId()" />
              </div>

              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Инфо</h3>
                </div>
                <div class="card-body">
                  <div class="datagrid">
                    <div class="datagrid-item">
                      <div class="datagrid-title">Картинка</div>

                      <div class="datagrid-content mt-1">
                        <span class="datagrid-title">Позиция картинки:</span> <b>{{ data.getImagePosition() }}</b>
                      </div>
                    </div>

                    <div class="datagrid-item">
                      <div class="datagrid-title">Дата публикации</div>

                      <div class="datagrid-content" v-html="data.getPublishedAt()"></div>
                    </div>

                    <div class="datagrid-item">
                      <div class="datagrid-title">Запланирован на удаление</div>

                      <div class="datagrid-content" v-html="data.getScheduledRemoved()"></div>
                    </div>

                    <div class="datagrid-item">
                      <div class="datagrid-title">Запланирован на публикацию</div>

                      <div class="datagrid-content" v-html="data.getScheduledPublished()"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="mb-3">
              <PostRefPosts :data="{
                id: chainObject.getId(),
                channel_id: chainObject.getChannelId(),
                status: chainObject.getStatus(),
              }" :type-id="chainObject.getTypeId()" />
            </div>

            <div class="mb-3">
              <Composites :data="{
                postId: getPostId(),
                hasInChain: bootData.has_in_hain,
              }
                " />
            </div>
            <div class="mb-3">
              <PostRefLinks :data="{
                postId: getPostId()
              }
                " />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="page-body">
        <div class="container-xl">
          <div class="row">
            <div class="mb-3">
              <div class="loader-posts">
                <div class="spinner-border"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, ref} from "vue"
import Api from "@/modules/post/pages/post_viewer/services/Api"
import Info from "@/modules/post/components/info/Index.vue"
import {useRoute} from "vue-router"
import {useMeta} from "vue-meta"
import PostInChain from "@/components/post_in_chain/PostInChain"
import Title from "../../components/post_title/Title"
import Lightbox from "@/components/lightbox/Lightbox"
import DTBackend from "@/services/DTBackend"
import PostRefPosts from "@/components/object_chains/PostRefPosts"
import Composites from "@/modules/post/components/composites/Composites"
import PostRefLinks from "@/modules/post/components/post_ref_links/PostRefLinks"
import UpdateBtn from "@/modules/post/components/update_btn/UpdateBtn";
import Transactions from "@/modules/post/components/transactions/Transactions"
import PostImage from "@/modules/post/services/PostImage";
import cfg from "@/services/Config";

export default defineComponent({
  name: "Index",
  components: {
    Transactions,
    UpdateBtn,
    Title,
    PostInChain,
    Lightbox,
    PostRefPosts,
    Composites,
    PostRefLinks,
    Info,
  },
  setup() {
    useMeta({title: "Просмотр поста"})

    let chainObject = {
      getId: () => {
        return getPostId()
      },
      getChannelId: () => {
        return bootData.channel_id
      },
      getStatus: () => {
        if (bootData.status === "published") {
          return "published"
        } else if (bootData.status === "draft") {
          return "draft"
        } else if (bootData.status === "deleted") {
          return "deleted"
        } else if (bootData.status === "scheduled") {
          return "scheduled"
        } else if (bootData.status === "prePublished") {
          return "prePublished"
        } else if (bootData.status === "preDeleted") {
          return "preDeleted"
        } else if (bootData.status === "canceled") {
          return "canceled"
        } else {
          throw Error("Неопределен статус")
        }
      },
      getDel: () => {
        if (bootData.del === 1) {
          return 1
        } else if (bootData.del === 0) {
          return 0
        } else {
          throw Error("Неопределен признак удаления")
        }
      },
      getTypeId: () => {
        return cfg._("object_chains", "types", "post")
      }
    }

    const route = useRoute()
    let readyPage = ref(false)
    let postId = ref(null)
    let bootData = reactive({
      content: null,
      image: null,
      image_position: null,
      scheduled_removed: null,
      scheduled_published: null,
      status: null,
      channel_id: null,
      published_at: null,
      has_in_hain: null,
      video: null,
    })

    let transactions = reactive({
      element: {},
      post_id: null,
    })

    let data = {
      getContent: () => {
        return bootData.content
      },
      getImage: (w = null, h = null) => {
        return [
            PostImage.handle(bootData.image?.host, bootData.image?.name, w, h, !!bootData.video)
        ]
      },
      getImagePosition: () => {
        return bootData.image_position === 1 ? "Вверху" : "Внизу"
      },
      getScheduledRemoved: () => {
        let result, dt, scheduledRemoved = bootData.scheduled_removed

        if (scheduledRemoved === null) {
          result = "Не запланирован"
        } else {
          dt = new DTBackend(scheduledRemoved)
          result = dt.get("%D.%M.%y <span>%H:%I</span>")
        }

        return result
      },
      getScheduledPublished: () => {
        let result, dt, scheduledPublished = bootData.scheduled_published

        if (scheduledPublished === null) {
          result = "Не запланирован"
        } else {
          dt = new DTBackend(scheduledPublished)
          result = dt.get("%D.%M.%y <span>%H:%I</span>")
        }

        return result
      },
      getPublishedAt: () => {
        let result, dt, publishedAt = bootData.published_at

        if (publishedAt === null) {
          result = "Не опубликован"
        } else {
          dt = new DTBackend(publishedAt)
          result = dt.get("%D.%M.%y <span>%H:%I</span>")
        }

        return result
      },
      getStatus: () => {
        return bootData.status
      },
      getChannelId: () => {
        return bootData.channel_id
      },
    }

    function getPostId() {
      return postId.value
    }

    function isReadyPage() {
      return readyPage.value === true
    }

    function getItem(ID) {
      return Api.item(ID)
    }

    function setPostId(post_id) {
      postId.value = post_id
    }

    onMounted(() => {
      if (!route.params.id) {
        throw new Error("Неопределен post_id")
      }

      setPostId(parseInt(route.params.id))

      getItem(getPostId()).then(async (resp) => {
        await setBootData(resp)
        readyPageOn()
      })
    })

    function setBootData(data) {
      bootData.status = data["post_status"]

      bootData.content = data["post_content_generate"]

      if (data["post_image_host"] !== null && data["post_image_name"] !== null) {
        bootData.image = {
          host: data["post_image_host"],
          name: data["post_image_name"],
        }
      }

      bootData.image_position = data["post_image_position"]

      bootData.scheduled_removed = data["post_scheduled_removed"]

      bootData.scheduled_published = data["post_scheduled_published"]

      bootData.channel_id = data["post_channel_id"]

      bootData.published_at = data["post_published_at"]

      bootData.has_in_hain = data["post_has_in_hain"]

      if (data["post_video_host"] !== null && data["post_video_name"] !== null) {
        bootData.video = {
          host: data["post_video_host"],
          name: data["post_video_name"],
        }
      }
    }

    function readyPageOn() {
      readyPage.value = true
    }

    function isDeleted() {
      return bootData.status === "deleted"
    }

    function isShowPostInChain() {
      return !isDeleted()
    }

    function onCountSellTitle(payload) {
      transactions.element = payload["element"]
      transactions.post_id = payload["post_id"]
    }

    function onClickChainTitle(payload) {
      transactions.element = payload["element"]
      transactions.post_id = payload["post_id"]
    }

    return {
      isReadyPage,
      data,
      isShowPostInChain,
      getPostId,
      bootData,
      onCountSellTitle,
      transactions,
      onClickChainTitle,
      chainObject,
    }
  }
})
</script>

<style src="./css/style.css" scoped></style>

