import UserApiBase from "@/modules/user/services/ApiBase"

export default class Api {
    static async users(query) {
        const resp = await UserApiBase.users(query)
        return resp.data
    }

    static async permissions(query) {
        const resp = await UserApiBase.permissions(query)
        return resp.data
    }
}
