import PostApiBase from "@/modules/post/services/ApiBase"

export default class Api {
    static cancel(postId) {
        return PostApiBase.cancel({
            post_id: postId,
        }).then((resp) => {
            return resp.data
        })
    }
}
