<template>
  <Coupons :object="object" :params="params" />
</template>

<script>
import {defineComponent} from "vue"
import Coupons from "@/modules/promopushka/pages/coupons/components/Coupons"
import Options from "@/modules/promopushka/pages/coupons/Disabled/Options"

export default defineComponent({
  name: "Disabled",
  components: {
    Coupons
  },
  setup() {
    let object = new Options()

    let params = {
      title: "Отключенные купоны",
      page: "disabled",
    }

    return {
      object,
      params,
    }
  }
})
</script>

<style scoped>

</style>
