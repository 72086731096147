<template>
  <span id="promopushka-list-title">
    <span v-html="getTitle()" class="title"></span><span v-html="getCountCouponsTitle()" class="count"></span>
  </span>
</template>

<script>
import "./css/style.css"
import {defineComponent, onMounted, ref, watch} from "vue"

export default defineComponent({
  name: "Title",
  props: {
    substring: {
      type: String,
      required: false
    },
    countCoupons: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    let countCoupons = ref(0)
    let substring = ref('')

    watch(() => props.substring, (val) => {
      substring.value = val ?? ''
    })

    watch(() => props.countCoupons, (val) => {
      setCountCouponsTitle(val)
    })

    onMounted(() => {
      setCountCouponsTitle(props.countCoupons)
      substring.value = props.substring ?? ''
    })

    function setCountCouponsTitle(val) {
      countCoupons.value = val
    }

    function getTitle() {
      let img = `<img width="30" class="rounded" src="/assets/static/promopushka/logo.jpg" alt="">`
      let link = `<a href="/promopushka">Промопушка</a>`

      return img + " " + link + (substring.value !== '' ? " - " + substring.value : '')
    }

    function getCountCouponsTitle() {
      let result = ""

      if (countCoupons.value > 0) {
        result += ": <span>" + countCoupons.value + "</span>"
      }

      return result
    }

    return {
      getTitle,
      getCountCouponsTitle,
    }
  },
})
</script>

<style scoped>

</style>
