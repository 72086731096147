export default {
    getCreatedAtTransact: (item) => {
        return item["created_at_transact"]
    },
    getHashSell: (item) => {
        return item["hash_sell"]
    },
    getPrice: (item) => {
        return item["price"]
    },
}
