import Helper from "@/services/Helper";

export default class ConvertQuery {
    static handle(query) {
        let q = {}
        let statuses = []

        if (query["date"]["from"] !== null) {
            q = Helper.mergeObject(q, {
                "from_date": query["date"]["from"],
            })
        }

        if (query["date"]["to"] !== null) {
            q = Helper.mergeObject(q, {
                "to_date": query["date"]["to"],
            })
        }

        if (query["users"].length > 0) {
            q = Helper.mergeObject(q, {
                "user__id": query["users"],
            })
        }

        if (query["types_commerce"].length > 0) {
            q = Helper.mergeObject(q, {
                "types_commerce_id": query["types_commerce"],
            })
        }

        if (query["search"] !== "") {
            q = Helper.mergeObject(q, {
                "query_string": query["search"],
            })
        }

        if (query.statuses?.published) {
            statuses.push("published")
        }

        if (query.statuses?.removed) {
            statuses.push("removed")
        }

        if (query.statuses?.scheduled) {
            statuses.push("scheduled")
        }

        if (query.statuses?.draft) {
            statuses.push("draft")
        }

        if (statuses.join(",")) {
            q = Helper.mergeObject(q, {
                "statuses": statuses.join(","),
            })
        }

        return Helper.mergeObject(q, {
            "limit": query["limit"],
            "offset": query["offset"],
            "channel_id": query["channel_id"],
        })
    }
}