<template>
  <option :value="index" v-for="(item, index) in items" :key="index">{{ item }}</option>
</template>

<script>
import {defineComponent} from "vue"

export default defineComponent({
  name: "Hours",
  setup() {
    let items = []

    let i = 0;
    while (i <= 23) {
      items[i] = i.toString().padStart(2, "0")
      i++;
    }

    return {
      items
    }
  },
})
</script>

<style scoped>

</style>