import PostApiBase from "@/modules/post/services/ApiBase"

export default class Api {
    static info(postId) {
        return PostApiBase.info({
            "post_id": postId
        }).then((resp) => {
            return resp.data["item"]
        })
    }
}
