import PromopushkaApiBase from "@/modules/promopushka/services/ApiBase"

export default class Api {
    static async item(couponId) {
        return PromopushkaApiBase.item({
            id: couponId
        }).then((resp) => {
            return resp.data.data
        })
    }

    static async create(query) {
        return PromopushkaApiBase.create(query).then((resp) => {
            return resp.data.data
        })
    }

    static async update(query) {
        return PromopushkaApiBase.update(query).then((resp) => {
            return resp.data.data
        })
    }

    static async delete(couponId) {
        return PromopushkaApiBase.delete({
            id: couponId
        }).then((resp) => {
            return resp.data.data
        })
    }

    static async shop() {
        return PromopushkaApiBase.shop().then((resp) => {
            return resp.data.data
        })
    }

    static async uploadCouponList(query) {
        return PromopushkaApiBase.uploadCouponList(query).then((resp) => {
            return resp.data.data
        })
    }

    static async statCouponList(query) {
        return PromopushkaApiBase.statCouponList(query).then((resp) => {
            return resp.data.data
        })
    }

    static async deleteCouponList(query) {
        return PromopushkaApiBase.deleteCouponList(query).then((resp) => {
            return resp.data.data
        })
    }

    
}
