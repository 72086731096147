<template>
  <div class="type-commerce" v-if="value">
    <div class="statistic-item badge bg-blue">
      <span class="count">{{ value }}</span>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue"

export default defineComponent({
  name: "TypeCommerce",
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    let value = ref(null);

    onMounted(() => {
      let val = props.data["value"]

      if (val) {
        value.value = "t" + val
      }
    })

    return {
      value
    }
  }
})
</script>

<style scoped>

</style>