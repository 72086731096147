import store from "@/plugins/store"

export default class Loader {
    static start() {
        store.commit('set', ['loadingApp', true])
    }

    static complete() {
        store.commit('set', ['loadingApp', false])
    }
}
