import SelectionUtils from '../../../../packages/editor/src/components/selection';
import $ from "../../../../packages/editor/src/components/dom";
import * as _ from "../../../../packages/editor/src/components/utils";
import './style.css';
import HTMLJanitor from "html-janitor";
/**
 * Link Tool
 *
 * Inline Toolbar Tool
 *
 * Wrap selected text with <a> tag
 */
export default class CompositeInlineTool {
    /**
     * @param {API} api - Editor.js API
     */
    constructor({ api }) {
        /**
         * Enter key code
         */
        this.ENTER_KEY = 13;
        /**
         * Styles
         */
        this.CSS = {
            button: 'ce-inline-tool-next',
            buttonActive: 'ce-inline-tool-next--active',
            buttonModifier: 'ce-inline-tool-next--composite',
            buttonUnlink: 'ce-inline-tool-next--uncomposite',
            input: 'ce-inline-tool-next-input',
            inputShowed: 'ce-inline-tool-next-input--showed',
            block: 'ce-inline-tool-next-input-block',
            btn: 'ce-inline-tool-next-input-button'
        };
        /**
         * Elements
         */
        this.nodes = {
            button: null,
            input: null,
            block: null,
        };
        /**
         * Input opening state
         */
        this.inputOpened = false;
        this.toolbar = api.toolbar;
        this.inlineToolbar = api.inlineToolbar;
        this.notifier = api.notifier;
        this.i18n = api.i18n;
        this.selection = new SelectionUtils();
    }
    /**
     * Sanitizer Rule
     * Leave <a> tags
     *
     * @returns {object}
     */
    static get sanitize() {
        return {
            composite: {
                link: true,
            },
        };
    }
    /**
     * Create button for Inline Toolbar
     */
    render() {
        this.nodes.button = document.createElement('button');
        this.nodes.button.type = 'button';
        this.nodes.button.classList.add(this.CSS.button, this.CSS.buttonModifier);
        let icon = $.make('span', ['icon--composite'], {
            innerHTML: '<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> ' +
                '<path stroke="none" d="M0 0h24v24H0z" fill="none"></path> ' +
                '<path d="M19 8.268a2 2 0 0 1 1 1.732v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h3"></path> ' +
                '<path d="M5.003 15.734a2 2 0 0 1 -1.003 -1.734v-8a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-3"></path> ' +
                '</svg>'
        });
        this.nodes.button.appendChild(icon);
        let icon2 = $.make('span', ['icon--uncomposite'], {
            innerHTML: '<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> ' +
                '<path stroke="none" d="M0 0h24v24H0z" fill="none"></path> ' +
                '<rect x="4" y="4" width="16" height="16" rx="2"></rect> ' +
                '<path d="M10 10l4 4m0 -4l-4 4"></path> ' +
                '</svg>'
        });
        this.nodes.button.appendChild(icon2);
        return this.nodes.button;
    }
    /**
     * Input for the link
     */
    renderActions() {
        this.nodes.block = document.createElement('div');
        this.nodes.block.classList.add(this.CSS.block);
        this.nodes.input = document.createElement('input');
        this.nodes.input.placeholder = this.i18n.t('Add a link');
        this.nodes.input.classList.add(this.CSS.input);
        let button = document.createElement('button');
        button.type = 'button';
        button.classList.add(this.CSS.btn, 'btn', 'btn-primary');
        button.innerHTML = 'ok';
        this.nodes.block.append(this.nodes.input);
        this.nodes.block.append(button);
        button.addEventListener('click', (event) => {
            this.enterPressed();
        });
        this.nodes.input.addEventListener('keydown', (event) => {
            if (event.keyCode === this.ENTER_KEY) {
                this.enterPressed(event);
            }
        });
        return this.nodes.block;
    }
    /**
     * Handle clicks on the Inline Toolbar icon
     *
     * @param {Range} range - range to wrap with link
     */
    surround(range) {
        /**
         * Range will be null when user makes second click on the 'link icon' to close opened input
         */
        if (range) {
            /**
             * Save selection before change focus to the input
             */
            if (!this.inputOpened) {
                /** Create blue background instead of selection */
                this.selection.setFakeBackground();
                this.selection.save();
            }
            else {
                this.selection.restore();
                this.selection.removeFakeBackground();
            }
            const parentAnchor = this.selection.findParentTag('COMPOSITE');
            /**
             * Unlink icon pressed
             */
            if (parentAnchor) {
                this.selection.expandToTag(parentAnchor);
                parentAnchor.outerHTML = parentAnchor.innerHTML;
                this.closeActions();
                this.checkState();
                this.toolbar.close();
                return;
            }
        }
        this.toggleActions();
    }
    /**
     * Check selection and set activated state to button if there are <a> tag
     *
     * @param {Selection} selection - selection to check
     */
    checkState(selection) {
        const parentAnchor = this.selection.findParentTag('COMPOSITE');
        if (parentAnchor) {
            this.nodes.button.classList.add(this.CSS.buttonUnlink);
            this.nodes.button.classList.add(this.CSS.buttonActive);
            this.openActions();
            /**
             * Fill input value with link href
             */
            const hrefAttr = parentAnchor.getAttribute('link');
            this.nodes.input.value = hrefAttr !== 'null' ? hrefAttr : '';
            this.selection.save();
        }
        else {
            this.nodes.button.classList.remove(this.CSS.buttonUnlink);
            this.nodes.button.classList.remove(this.CSS.buttonActive);
        }
        return !!parentAnchor;
    }
    /**
     * Function called with Inline Toolbar closing
     */
    clear() {
        this.closeActions();
    }
    /**
     * Set a shortcut
     */
    get shortcut() {
        return 'CMD+K';
    }
    /**
     * Show/close link input
     */
    toggleActions() {
        if (!this.inputOpened) {
            this.openActions(true);
        }
        else {
            this.closeActions(false);
        }
    }
    /**
     * @param {boolean} needFocus - on link creation we need to focus input. On editing - nope.
     */
    openActions(needFocus = false) {
        // this.nodes.input.classList.add(this.CSS.inputShowed);
        this.nodes.block.classList.add(this.CSS.inputShowed);
        if (needFocus) {
            this.nodes.input.focus();
        }
        this.inputOpened = true;
    }
    /**
     * Close input
     *
     * @param {boolean} clearSavedSelection — we don't need to clear saved selection
     *                                        on toggle-clicks on the icon of opened Toolbar
     */
    closeActions(clearSavedSelection = true) {
        if (this.selection.isFakeBackgroundEnabled) {
            // if actions is broken by other selection We need to save new selection
            const currentSelection = new SelectionUtils();
            currentSelection.save();
            this.selection.restore();
            this.selection.removeFakeBackground();
            // and recover new selection after removing fake background
            currentSelection.restore();
        }
        // this.nodes.input.classList.remove(this.CSS.inputShowed);
        this.nodes.block.classList.remove(this.CSS.inputShowed);
        this.nodes.input.value = '';
        if (clearSavedSelection) {
            this.selection.clearSaved();
        }
        this.inputOpened = false;
    }
    /**
     * Enter pressed on input
     *
     * @param {KeyboardEvent} event - enter keydown event
     */
    enterPressed(event) {
        let value = this.nodes.input.value || '';
        if (!this.validateURL(value)) {
            this.notifier.show({
                message: 'Невалидная ссылка',
                style: 'error',
            });
            _.log('Incorrect Link pasted', 'warn', value);
            return;
        }
        value = this.prepareLink(value);
        this.selection.restore();
        //this.selection.removeFakeBackground();
        this.insertLink(value);
        /**
         * Preventing events that will be able to happen
         */
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        this.selection.collapseToEnd();
        this.inlineToolbar.close();
    }
    /**
     * Detects if passed string is URL
     *
     * @param {string} str - string to validate
     * @returns {boolean}
     */
    validateURL(str) {
        /**
         * Don't allow spaces
         */
        return !/\s/.test(str);
    }
    /**
     * Process link before injection
     * - sanitize
     * - add protocol for links like 'google.com'
     *
     * @param {string} link - raw user input
     */
    prepareLink(link) {
        link = link.trim();
        link = this.addProtocol(link);
        return link;
    }
    /**
     * Add 'http' protocol to the links like 'vc.ru', 'google.com'
     *
     * @param {string} link - string to process
     */
    addProtocol(link) {
        /**
         * If protocol already exists, do nothing
         */
        if (/^(\w+):(\/\/)?/.test(link)) {
            return link;
        }
        /**
         * We need to add missed HTTP protocol to the link, but skip 2 cases:
         *     1) Internal links like "/general"
         *     2) Anchors looks like "#results"
         *     3) Protocol-relative URLs like "//google.com"
         */
        const isInternal = /^\/[^/\s]/.test(link), isAnchor = link.substring(0, 1) === '#', isProtocolRelative = /^\/\/[^/\s]/.test(link);
        if (link && !isInternal && !isAnchor && !isProtocolRelative) {
            link = 'https://' + link;
        }
        return link;
    }
    stripHTML(string) {
        return string.replace(/(<([^>]+)>)/ig, '');
    }
    getSelectionHtml() {
        let result = "";
        let sel = window.getSelection();
        if (sel.rangeCount) {
            let container = document.createElement("div");
            for (let i = 0, len = sel.rangeCount; i < len; ++i) {
                container.appendChild(sel.getRangeAt(i).cloneContents());
            }
            result = container.innerHTML;
        }
        return result;
    }
    /**
     * Inserts <a> tag with "href"
     *
     * @param {string} link - "href" value
     */
    insertLink(link) {
        let selection = this.getSelectionHtml();
        const sanitizerConfig = {
            tags: {
                a: { href: true },
                b: {},
                i: {},
                code: {},
                br: {},
            },
        };
        /**
         * API client can use custom config to manage sanitize process
         */
        const sanitizerInstance = new HTMLJanitor(sanitizerConfig);
        let selectionClean = sanitizerInstance.clean(selection);
        const composite = this.selection.findParentTag('COMPOSITE');
        if (composite) {
            this.changeLinkComposite(composite, link);
        }
        else {
            let selection = window.getSelection();
            let range = selection.getRangeAt(0);
            let composite = this.createComposite(selectionClean, link);
            this.removeFakeBackground();
            range.deleteContents();
            range.insertNode(composite);
            range.collapse(false);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }
    removeFakeBackground() {
        const span = this.selection.findParentTag('SPAN');
        span.remove();
    }
    changeLinkComposite(composite, link) {
        composite.setAttribute('link', link);
        return composite;
    }
    createComposite(content, link) {
        const composite = document.createElement('COMPOSITE');
        composite.innerHTML = content;
        if (link) {
            this.changeLinkComposite(composite, link);
        }
        return composite;
    }
}
/**
 * Specifies Tool as Inline Toolbar Tool
 *
 * @returns {boolean}
 */
CompositeInlineTool.isInline = true;
/**
 * Title for hover-tooltip
 */
CompositeInlineTool.title = 'Link';
