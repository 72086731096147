import PostApiBase from "@/modules/post/services/ApiBase"

export default class Api {
    static postChains(objectId) {
        return PostApiBase.chainItems({
            object_id: objectId,
        }).then((resp) => {
            return resp.data["items"]
        })
    }

    static bind(thisObjectId, thatObjectId, thisTypeId, thatTypeId) {
        let api
        
        if (thisTypeId === 1) {
            api = PostApiBase.chainBind
        } else if (thisTypeId === 2) {
            api = PostApiBase.promopushkaChainBind
        } else {
            throw Error("Неверный тип")
        }

        return api({
            this_object_id: thisObjectId,
            that_object_id: thatObjectId,
            this_type_id: thisTypeId,
            that_type_id: thatTypeId,
        }).then((resp) => {
            return resp.data
        })
    }

    static unbind(objectId, typeId) {
        let api

        if (typeId === 1) {
            api = PostApiBase.chainUnbind
        } else if (typeId === 2) {
            api = PostApiBase.promopushkaChainUnbind
        } else {
            throw Error("Неверный тип")
        }

        return api({
            object_id: objectId,
            type_id: typeId,
        }).then((resp) => {
            return resp.data
        })
    }
}
