<template>
  <div class="card" id="is-partner-links">
    <div class="card-body">
      <div class="divide-y">
        <label class="row">
          <span class="col">
            <span class="title">Партнерить ссылки</span>
          </span>
          <span class="col-auto">
            <label class="form-check form-check-single form-switch">
              <input class="form-check-input" type="checkbox" v-model="isEnable" @change="onChange">
            </label>
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import {defineComponent, ref, onMounted} from "vue"

export default defineComponent({
  name: "IsPartnerLinks",
  props: {
    data: {
      type: Object,
      default: null,
    },
    validations: {},
  },
  setup(props, {emit}) {
    let isEnable = ref(false)

    onMounted(() => {
      if (props.data["enable"] === 1) {
        enableStatus()
      } else {
        disableStatus()
      }
    })

    function enableStatus() {
      isEnable.value = true
    }

    function disableStatus() {
      isEnable.value = false
    }

    function onChange() {
      changeEmit(isEnable.value)
    }

    function changeEmit(val) {
      let value = val === true ? 1 : 0
      emit("onChange", value)
    }

    return {
      isEnable,
      onChange,
    }
  }
})
</script>

<style scoped>

</style>
