<template>
  <button class="dropdown-item text-danger" @click="onUnbind">Отвязать</button>
</template>

<script>
import {defineComponent, ref} from "vue"
import Api from "@/components/object_chains/services/Api"
import Notify from "@/services/Notify"

export default defineComponent({
  name: "UnbindPost",
  props: {
    post_id: {
      type: Number,
      required: true,
    },
    type_id: {
      type: Number,
      required: true,
    },
  },
  setup(props, {emit}) {
    let postId = ref(props.post_id)
    let typeId = ref(props.type_id)

    function onUnbind() {
      if (confirm("Вы действительно хотите отвязать этот объект")) {

        Api.unbind(postId.value, typeId.value).then(() => {
          Notify.success("Объект удален из цепочки")
          emit("unbindSuccess")
        }).catch((resp) => {
          let data = resp.response.data

          if (data?.validations !== undefined) {
            validationServer(getErrors(data.validations))
          }
        })
      }
    }

    function getErrors(validations) {
      let result = []
      Object.values(validations).forEach(value => {
        result.push(value[0])
      })
      return result
    }

    function validationServer(errors) {
      Object.values(errors).forEach((val) => {
        Notify.error(val)
      })
    }

    return {
      onUnbind,
    }
  },
})
</script>

<style scoped>

</style>
