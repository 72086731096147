import AuthStore from "@/modules/auth/services/Store"
import ApiBase from "@/modules/auth/services/ApiBase"
import AuthToken from "@/modules/auth/services/Token"
import AuthClient from "@/modules/auth/services/Client"

export default new class Auth {
    recoverySession() {
        if (!AuthStore.isAuth() && !AuthStore.isTryAuth()) {
            return this.authenticate()
        } else {
            return new Promise((resolve) => {
                resolve()
            });
        }
    }

    authenticate() {
        return new Promise((resolve, reject) => {
            ApiBase.session().then((response) => {
                const item = response.data["item"]

                this.setDataLogin(item)

                resolve(response);
            }).catch((error) => {
                if (error.response.status !== 401) {
                    reject(error)
                    return
                }

                let token = this.getToken()
                let client = this.getClient()

                if (!token || !client) {
                    reject(error)
                    return
                }

                ApiBase.refresh({
                    token,
                    client,
                }).then((response) => {
                    const item = response.data["item"]

                    this.setToken(item["token"]);
                    this.setDataLogin(item)

                    resolve(response);
                }).catch(() => {
                    reject(error)
                })
            }).finally(() => {
                this.markTryAuth()
            });
        })
    }

    logout() {
        return new Promise((resolve, reject) => {
            let client = this.getClient()

            ApiBase.logout({
                client,
            }).then(() => {
                this.removeToken()
                this.removeDataLogin()

                resolve();
            }).catch((resp) => {
                reject(resp);
            });
        })
    }

    markTryAuth() {
        AuthStore.markTryAuth()
    }

    setToken(token) {
        AuthToken.set(token);
    }

    getToken() {
        return AuthToken.get();
    }

    removeToken() {
        AuthToken.remove()
    }

    setClient(client) {
        AuthClient.set(client);
    }

    getClient() {
        return AuthClient.get()
    }
    setDataLogin(data) {
        AuthStore.setDataLogin({
            id: data["id"],
            name: data["name"],
            role: data["role"],
            avatar: {
                "host": data["avatar_host"],
                "name": data["avatar_name"],
            },
        })
    }

    removeDataLogin() {
        AuthStore.clearDataLogin()
    }
}