import axios from "axios"
import {ref} from "vue"
import {defineStore} from "pinia"
import Helper from "@/services/Helper"
import cfg from "@/services/Config";

export default defineStore('click-sell-chart', () => {
    let data = ref({})
    let statistic = ref({})
    let postId = ref(0)
    let type = ref("")
    let loading = ref(false)

    function getStatistic(postId, type) {
        start(postId, type)
        axios.post(getUrl(), {
            "post_id": postId,
            "type_id": getStatisticTypeId(),
        })
            .then(function (response) {
                let resp = response.data.items;

                if (resp.length) {
                    statistic = resp.map((item) => {
                        return {
                            click: item["ticker_click_cnt"],
                            sell: item["ticker_sell_cnt"],
                            dateTicker: item["ticker_datetime"],
                        }
                    })

                    data.value = {
                        label: statistic.map(item => item.dateTicker),
                        click: statistic.map(item => item.click),
                        sell: statistic.map(item => item.sell),
                    }
                }
            }).finally(() => {
            loadingOff()
        })
    }

    function start(postId, type) {
        loadingOn()
        clearData()
        setInit(postId, type)
    }

    function clearData() {
        data.value = {}
    }

    function loadingOn() {
        loading.value = true
    }

    function loadingOff() {
        loading.value = false
    }

    function setInit(postId, type) {
        setPostId(postId)
        setType(type)
    }

    function setPostId(val) {
        postId.value = val
    }

    function setType(val) {
        if (!Helper.inArray(val, ["post", "chain"])) {
            throw new Error("Неверный тип")
        }

        type.value = val
    }

    function isPostType() {
        return type.value === "post"
    }

    function isChainType() {
        return type.value === "chain"
    }

    function getUrl() {
        if (isPostType()) {
            return `/api/v1/statistic/post-ticker`
        } else if (isChainType()) {
            return `/api/v1/statistic/post-chain-ticker`
        } else {
            throw new Error("Неверный тип для определения url адреса")
        }
    }

    let f = {
        hasLabel: () => {
            return data.value.label
        },
        getLabel: () => {
            return data.value.label
        },
        hasClick: () => {
            return data.value.click && f.hasLabel()
        },
        getClick: () => {
            return data.value.click
        },
        hasSell: () => {
            return data.value.sell && f.hasLabel()
        },
        getSell: () => {
            return data.value.sell
        },
        getColor: () => {
            if (isPostType()) {
                return "#2fb344";
            } else if (isChainType()) {
                return "#d29400";
            }
        },
        getLoading: () => {
            return loading.value
        }
    }

    function getStatisticTypeId() {
        return cfg._("statistic", "types", "post")
    }

    return Helper.mergeObject({
        getStatistic,
    }, f)
})
