<template>
    <div class="card" id="shop-select">
        <div class="card-body">
            <div class="divide-y">
                <div class="row">
                    <span class="col-4">
                        <label class="form-label">Магазин</label>
                    </span>
                    <span class="col-8">
                        <Multiselect @change="onChange" v-model="fields.shop_id" mode="single" placeholder="Не выбрано" noResultsText="Результатов не найдено" :searchable="true" :close-on-select="true" :options="getValues()" :trackBy="'title'" max="1">
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label option-user">
                                    <div class="user-image-wrap">
                                        <img class="user-image" :src="value.image">
                                    </div>
                                    <div class="user-name"> {{ value.title }}</div>
                                </div>
                            </template>
                            <template v-slot:option="{ option }">
                                <div class="option-user">
                                    <div class="user-image-wrap">
                                    <img class="user-image" :src="option.image">
                                    </div>
                                    <div class="user-name"> {{ option.title }}</div>
                                </div>
                            </template>
                        </Multiselect>
                    </span>
                </div>
                <div v-if="v$.shop_id.$invalid">
                    <span :class="{'is-invalid': v$.shop_id.$invalid && v$.shop_id.$dirty}"></span>
                    <div class="invalid-feedback" v-for="error of v$.shop_id.$silentErrors" :key="error.$uid">
                        {{ error.$message }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, watch, ref, onMounted, reactive} from "vue"
import {useVuelidate} from "@vuelidate/core/dist/index.esm"
import Multiselect from "@/plugins/multiselect"
import {minLength} from "@/plugins/vuelidator"
import Image from "@/services/Image"

export default defineComponent({
    name: "Shop",
    components: {
        Multiselect,
    },
    props: {
        data: {
            type: [Object, null],
            default: null,
        },
    },
    setup(props, {emit}) {
        let shopsValues = ref([])

        const fields = reactive({
            shop_id: props.data.val,
        })

        onMounted(() => {
            fields.shop_id = props.data.val;
            setValues(props.data.shops);
        })

        watch(() => props.data.val, (val) => {
            fields.shop_id = val
        })

        watch(() => props.data.shops, (val) => {
            setValues(val);
        })

        watch(() => props.data["validations"], (val) => {
            validate(() => {
                if (val?.shop_id !== undefined) {
                    $externalResults.shop_id = val["shop_id"][0]
                }
            })
        })

        function setValues(items) {
            let filtered = []
            for (let prop in items) {
                let obj = items[prop]
                filtered.push({
                    value: obj["id"],
                    title: obj["title"],
                    search: obj["search"],
                    image: Image.handle(obj["host"], obj["image"], 60)
                })
            }
            shopsValues.value = filtered
        }

        function getValues() {
            return shopsValues.value;
        }

        const rules = {
            shop_id: {
                minLength: minLength(0),
            },
        }

        let $externalResults = reactive({})    

        const v$ = useVuelidate(rules, fields, {$externalResults})

        function validate(fn) {
            v$.value.$clearExternalResults()

            return v$.value.$validate().then(async () => {
                await fn()
            })
        }

        function onChange(value) {
            fields.shop_id = value
            validate(() => {})
            emit("onChange", fields.shop_id)
        }

        return {
            onChange,
            getValues,
            v$,
            fields,
        }
    }
})
</script>

<style scoped>

</style>
