import Helper from "@/services/Helper"

export default class Options {
    filterParams() {
        return {
            params: {
                search: "",
                date: null,
                users: [],
                statuses: {
                    enabled: true,
                    disabled: false,
                    removed: false
                },
            },
            enable: true,
        }
    }

    prepareQuery(q) {
        return  Helper.clone(q)
    }
}
