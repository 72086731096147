import StorageBase from "@/services/StorageBase"

export default class Storage {
    constructor(name) {
        this.name = name
    }

    set(token) {
        StorageBase.set(this.name, token)
    }

    get() {
        return StorageBase.get(this.name)
    }

    remove() {
        StorageBase.remove(this.name)
    }
}