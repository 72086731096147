<template>
  <li class="nav-item" v-if="isView()">
    <router-link class="nav-link" :to="{ name: 'Catalog' }">
      <span class="nav-link-icon d-md-none d-lg-inline-block">
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-folder"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 4h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2" /></svg>
      </span>
      <span class="nav-link-title">
        Каталог
      </span>
    </router-link>
  </li>
</template>

<script>
import Permission from "@/services/Permission";
import {onMounted, ref} from "vue";

export default {
  name: "Catalog",
  setup() {
    let noPermission = ref(false)

    onMounted(() => {
      checkPermission()
    })

    function checkPermission() {
      if (!Permission.hasPermission("catalog")) {
        setNoPermission()
      }
    }

    function setNoPermission() {
      noPermission.value = true
    }

    function isView() {
      return !noPermission.value
    }

    return {
      isView,
    }
  },
}
</script>

<style scoped>

</style>