<template>
  <div @click="clickBlock()">
    <div class="card" :class="{'card-inactive': isDisable()}">
      <div class="card-header">
        <h3 class="card-title">Видео</h3>
        <template v-if="hasVideo()">
          <div class="card-actions">
            <div @click="onDeleteVideo()">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24"
                   height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                   stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M18 6l-12 12"></path>
                <path d="M6 6l12 12"></path>
              </svg>
            </div>
          </div>
          <div class="mb-3">
            <span :class="{'is-invalid': v$.video.$invalid && v$.video.$dirty}"></span>
            <div class="invalid-feedback" v-for="error of v$.video.$silentErrors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </template>
      </div>

      <div class="card-body">
        <template v-if="!hasVideo()">
          <div class="mb-3">
            <div class="fallback">
              <input type="file" @change="onVideoChange"/>
            </div>
          </div>
        </template>

        <template v-if="hasVideo()">
          <a :href="getVideo()" target="_blank">Посмотреть видео</a>
        </template>

        <template v-if="isLoadVideo()">
          <div class="mb-3">
            <div class="progress progress-sm">
              <div class="progress-bar progress-bar-indeterminate"></div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "./services/Api"
import {defineComponent, reactive, ref, watch} from "vue"
import Video from "@/services/Video"
import {minLength} from "@/plugins/vuelidator";
import {useVuelidate} from "@vuelidate/core"
import Notify from "@/services/Notify";

export default defineComponent({
  name: "Video",
  components: {},
  props: {
    postId: {
      type: Number,
    },
    src: {
      type: Object,
    },
    validations: {
      //type: Object,
    },
    hasImage: {
      type: Boolean,
      required: true,
    }
  },
  setup(props, {emit}) {
    let postId = ref(props.postId)
    let video = ref(props.src)
    let loadVideo = ref(false)
    let hasImage = ref(props.hasImage)

    watch(() => props.hasImage, (val) => {
      hasImage.value = val
    })

    let $externalResults = reactive({})

    const fields = reactive({
      video: props.src,
    })

    const rules = {
      video: {
        between: minLength(0)
      },
    }

    const v$ = useVuelidate(rules, fields, {$externalResults})

    watch(() => props.postId, (val) => {
      postId.value = val
    })

    watch(() => props.validations, (val) => {
      validate(() => {
        if (val?.video !== undefined) {
          $externalResults.video = val["video"][0]
        }
      })
    })

    function validate(fn) {
      v$.value.$clearExternalResults()
      return v$.value.$validate().then(() => {
        fn()
      })
    }

    function isActive() {
      return hasPostId()
    }

    function hasPostId() {
      return postId.value !== null
    }

    function hasVideo() {
      return video.value !== null
    }

    function deleteVideo() {
      deleteVideoApi(getPayload({
        post_id: postId.value,
        video_id: video.value["id"],
      })).then(() => {
        return video.value = null
      })
    }

    function onDeleteVideo() {
      if (confirm("Вы действительно хотите удалить видео?")) {

        deleteVideo()

        emit("onDelete")
      }
    }

    function isLoadVideo() {
      return loadVideo.value === true
    }

    function getVideo(w = null, h = null) {
      return [
        Video.handle(video.value["host"], video.value["name"], w, h)
      ]
    }

    function onVideoChange(e) {
      fields.video = e
      change(e)
    }

    function change(e) {
      loadVideo.value = true

      if (e.target.files[0].size > 5242880) {
        Notify.error("Размер видео не должен превышать 5мб");
        loadVideo.value = false
        return;
      }

      uploadVideoApi(getPayload({
        post_id: postId.value,
        video: e.target.files[0],
      })).then((resp) => {
        setVideo({
          "host": resp["host"],
          "name": resp["name"],
          "id": resp["video_id"],
        })

        emit("onChange", {
          host: resp["host"],
          name: resp["name"],
        })
      }).finally(() => {
        loadVideo.value = false
      })
    }

    function setVideo(val) {
      video.value = val
    }

    function uploadVideoApi(payload) {
      return Api.addVideo(payload)
    }

    function deleteVideoApi(payload) {
      return Api.deleteVideo(payload)
    }

    function getPayload(obj) {
      const formData = new FormData()

      Object.entries(obj).forEach(([key, val]) => {
        formData.append(key, val)
      })

      return formData
    }

    function isDisable() {
      return (!isActive() && !hasVideo()) || hasImage.value;
    }

    function clickBlock() {
      if (hasImage.value) {
        alert("Так как загружена картинка, видео загрузить нельзя");
      }
    }

    return {
      isActive,
      hasVideo,
      onDeleteVideo,
      isLoadVideo,
      getVideo,
      onVideoChange,
      v$,
      fields,
      isDisable,
      clickBlock,
    }
  }
})
</script>

<style scoped>

</style>
