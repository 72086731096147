import PostApiBase from "@/modules/role/services/ApiBase";

export default {
    namespaced: true,
    state: {
        permissions: [],
    },
    getters: {
        getPermissions: (state) => {
            return state.permissions
        },
    },
    mutations: {
        setPermissions: (state, payload) => {
            state.permissions = payload
        },
    },
    actions: {
        setPermissions(state) {
            return new Promise((resolve) => {
                PostApiBase.allPermissions().then((resp) => {
                    state.commit('setPermissions', resp.data["items"])
                    resolve();
                });
            });
        }
    },
}
