<template>
  <div class="divide-y-2 actions">
    <span class="spinner-border spinner-border-sm me-2" v-if="isLoad()"></span>
    <template v-else>
      <template v-if="!hasItems()">
        <p>Данных пока нет</p>
      </template>
      <template v-else>
        <div :key="data.getDatetime(item)" v-for="item in items">
          <div v-html="data.getDatetime(item)"></div>
          <img alt="" class="user-avatar" width="20" :src="data.getUserAvatar(item, 40)"> <b>{{ data.getUserName(item) }}</b> - {{ data.getActionName(item) }}
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue"
import Api from "./services/Api"
import element from "./services/element";
import Image from "@/services/Image";
import DTBackend from "@/services/DTBackend";

export default defineComponent({
  name: "Actions",
  props: {
    postId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    let load = ref(true)
    let items = ref([])

    onMounted(() => {
      Api.actions(props.postId).then((resp) => {
        setItems(resp)
        startLoad()
      }).finally(() => {
        stopLoad()
      })
    })

    function setItems(val) {
      items.value = val
    }

    function startLoad() {
      load.value = true
    }

    function stopLoad() {
      load.value = false
    }

    function isLoad() {
      return load.value === true
    }

    function hasItems() {
      return items.value.length > 0
    }

    let data = {
      getUserName: (item) => {
        return element.getUserName(item)
      },

      getUserAvatar: (item, w) => {
        return Image.handle(
            element.getUserAvatarHost(item),
            element.getUserAvatarName(item),
            w,
        )
      },

      getDatetime: (item) => {
        let datetime = element.getDatetime(item)
        let dt = new DTBackend(datetime)
        return dt.get("%D.%M.%y <span>%H:%I</span>")
      },

      getActionName: (item) => {
        let type = element.getType(item)
        let name = ""

        switch (type) {
          case 1:
            name = "Удален"
            break;

          case 2:
            name = "Опубликован"
            break;

          case 3:
            name = "Отредактирован"
            break;

          case 4:
            name = "Создан черновик"
            break;

          case 5:
            name = "Просмотрен в боте"
            break;

          case 6:
            name = "Удален по расписанию"
            break;

          case 7:
            name = "Опубликован по расписанию"
            break;

          case 8:
            name = "Отредактирован в удаленном посте"
            break;

          case 9:
            name = "Повторно опубликован"
            break;

          case 10:
            name = "Повторно удален"
            break;

          default:
            break;
        }

        return name;
      },
    }

    return {
      isLoad,
      items,
      data,
      hasItems,
    }
  },
})
</script>

<style scoped></style>
