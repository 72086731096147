import authMiddleware from "@/plugins/router/middlewares/auth"
import Complaints from "@/modules/complaints/pages/Complaints"


export default [{
    path: "/complaints",
    name: "Complaints",
    component: Complaints,
    meta: {
        middleware: authMiddleware,
    }
},]
