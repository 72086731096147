import Helper from "@/services/Helper"

export default class Default {
    filterParams() {
        return {
            params: {
                search: "",
                date: null,
                users: [],
                statuses: {
                    published: true,
                    scheduled: true,
                    removed: false,
                    draft: false,
                },
            },
            saveForm: true,
            enable: true,
        }
    }

    prepareQuery(q) {
        let query = Helper.clone(q)

        return query
    }
}
