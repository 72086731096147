export default {
    getUserName: (item) => {
        return item["user"]["name"]
    },

    getUserAvatarHost: (item) => {
        return item["user"]["avatar_host"]
    },

    getUserAvatarName: (item) => {
        return item["user"]["avatar_name"]
    },

    getDatetime: (item) => {
        return item["datetime"]
    },

    getType: (item) => {
        return item["type"]
    },
}