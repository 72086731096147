<template>
  <header class="navbar navbar-expand-md navbar-dark sticky-top d-print-none">
    <div class="container-xl">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu" ref="toggleMenu">
        <span class="navbar-toggler-icon"></span>
      </button>
      <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
        <router-link :to="{path: '/dashboard'}">
          <img src="@/assets/static/logo-white.svg" width="110" height="32" alt="Tabler" class="navbar-brand-image">
        </router-link>
      </h1>
      <UserWindow></UserWindow>
    </div>
  </header>
</template>

<script>
import UserWindow from "@/modules/auth/components/UserWindow"
import {defineComponent, ref, watch} from "vue"
import {useRoute} from "vue-router"
import "./css/style.css"

export default defineComponent({
  name: "Header",
  components: {
    UserWindow
  },
  setup() {
    const route = useRoute()
    let toggleMenu = ref()

    watch(
        () => route.fullPath,
        async () => {
          let isOpen = toggleMenu.value.getAttribute("aria-expanded") === 'true'

          if (isOpen) {
            toggleMenu.value.click()
          }
        }
    );

    return {
      toggleMenu
    }
  },
})
</script>

<style scoped>

</style>