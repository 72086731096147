export default class DTFormat {
    constructor(date) {
        let year = parseInt(date.slice(0, 4))
        let month = parseInt(date.slice(5, 7))
        let dt = parseInt(date.slice(8, 10))
        let hour = parseInt(date.slice(11, 13))
        let minutes = parseInt(date.slice(14, 16))
        let seconds = parseInt(date.slice(17, 19))

        this.d = new Date(year, month - 1, dt, hour, minutes, seconds)
    }

    getObject() {
        return this.d
    }
}