import Login from "@/modules/auth/pages/Login"
import guestMiddleware from "@/plugins/router/middlewares/guest"

export default [{
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
        layout: "loginLayout",
        middleware: guestMiddleware,
    }
}]