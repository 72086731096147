import {axios as http} from "@/plugins/http"

const host = 'http://87.249.37.193:1007'

export default class ApiBase {
    static users(query) {
        let config = {
            params: query,
        }
        return http.get(host + "/api/v1/user", config);
    }

    static user(id) {
        return http.get(host + "/api/v1/user/" + id);
    }

    static userEdit(id, query) {
        return http.put(host + "/api/v1/user/"  + id, query);
    }

    static userPassword(id, query) {
        return http.post(host + "/api/v1/user/change-password/"  + id, query);
    }

    static userDelete(id) {
        return http.delete(host + "/api/v1/user/" + id);
    }

    static userActivate(id, query) {
        return http.post(host + "/api/v1/user/active/" + id, query);
    }

    static userAvatar(query) {
        return http.post(host + "/api/v1/user/avatar", query);
    }

    static userAvatarDelete(id) {
        return http.delete(host + "/api/v1/user/avatar/" + id);
    }

    static permissions(query) {
        let config = {
            params: query,
        }
        return http.get(host + "/api/v1/permission", config);
    }

    static permissionsUser(id) {
        return http.get(host + "/api/v1/permission/user/" + id);
    }

    static permissionsUserSet(query) {
        return http.post(host + "/api/v1/permission/user", query);
    }

    static permissionsUserDelete(userId, permissionId, sourceId) {
        return http.delete(host + "/api/v1/permission/user/" + userId + '/' + permissionId + '/' + sourceId);
    }
}
