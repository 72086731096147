<template>
    <div>
        <div class="card" id="type-of-coupon">
            <div class="card-body">
                <div class="divide-y">
                    <label class="row">
                        <span class="col-4">
                            <span class="form-label">Тип скидки</span>
                        </span>
                        <span class="col-8">
                            <select class="form-select" v-model="fields.type_of_coupon" @change="onChange">
                                <option :key="item.id" :value="item.id" v-for="(item) in getValuesTypeCoupon()">{{ item.name }}</option>
                            </select>
                            <div v-if="v$.type_of_coupon.$invalid">
                                <span :class="{'is-invalid': v$.type_of_coupon.$invalid && v$.type_of_coupon.$dirty}"></span>
                                <div class="invalid-feedback" v-for="error of v$.type_of_coupon.$silentErrors" :key="error.$uid">
                                    {{ error.$message }}
                                </div>
                            </div>
                        </span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, ref, watch, onMounted, reactive} from "vue"
import values from "@/modules/promopushka/services/values"
import {useVuelidate} from "@vuelidate/core/dist/index.esm"
import {minLength} from "@/plugins/vuelidator"

export default defineComponent({
    name: "TypeCoupon",
    props: {
        data: {
            type: [Object, null],
            default: null,
        },
    },
    setup(props, {emit}) {
        let valueTypeSteps = ref(0)

        const fields = reactive({
            type_of_coupon: 0,
        })

        onMounted(() => {
            valueTypeSteps.value = props.data.type_of_steps ?? 0;
            fields.type_of_coupon = props.data.val ?? 0;
        })

        watch(() => props.data.type_of_steps, (val) => {
            valueTypeSteps.value = val ?? 0;
        })

        watch(() => props.data.val, (val) => {
            fields.type_of_coupon = val
        })

        const rules = {
            type_of_coupon: {
                minLength: minLength(0),
            },
        }

        let $externalResults = reactive({})    

        const v$ = useVuelidate(rules, fields, {$externalResults})

        function validate(fn) {
            v$.value.$clearExternalResults()

            return v$.value.$validate().then(async () => {
                await fn()
            })
        }

        watch(() => props.data["validations"], (val) => {
            validate(() => {
                if (val?.type_of_coupon !== undefined) {
                    $externalResults.type_of_coupon = val["type_of_coupon"][0]
                }
            })
        })

        function onChange() {
            validate(() => {})
            emit("onChange", fields.type_of_coupon)
        }

        function getValuesTypeCoupon() {
            let typeOfSteps = values.types.steps.find((type) => type.id === valueTypeSteps.value)
            let availableTypes = typeOfSteps?.coupon ?? [];
            let returnValues = values.types.coupons.filter((type) => availableTypes.includes(type.id));
            return returnValues;
        }

        return {
            getValuesTypeCoupon,
            onChange,
            v$,
            fields,
        }
    }
})
</script>

<style scoped>

</style>
