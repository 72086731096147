import $ from "../../../../packages/editor/src/components/dom";
import SelectionUtils from "../../../../packages/editor/src/components/selection";
import "./style.css";
/**
 * Bold Tool
 *
 * Inline Toolbar Tool
 *
 * Makes selected text bolder
 */
export default class StrikeInlineTool {
    constructor({ api }) {
        this.state = false;
        /**
         * Styles
         */
        this.CSS = {
            button: 'ce-inline-tool',
            buttonActive: 'ce-inline-tool--active',
            buttonModifier: 'ce-inline-tool--strike',
        };
        /**
         * Elements
         */
        this.nodes = {
            button: undefined,
        };
        this.api = api;
        this.selection = new SelectionUtils();
    }
    /**
     * Sanitizer Rule
     * Leave <b> tags
     *
     * @returns {object}
     */
    static get sanitize() {
        return {
            s: {},
        };
    }
    /**
     * Create button for Inline Toolbar
     */
    render() {
        this.nodes.button = document.createElement('button');
        this.nodes.button.type = 'button';
        this.nodes.button.classList.add(this.CSS.button, this.CSS.buttonModifier);
        let icon = $.make('span', ['icon--strike'], {
            innerHTML: '<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-strikethrough" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">' +
                '<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>' +
                '<path d="M5 12l14 0"></path>' +
                '<path d="M16 6.5a4 2 0 0 0 -4 -1.5h-1a3.5 3.5 0 0 0 0 7h2a3.5 3.5 0 0 1 0 7h-1.5a4 2 0 0 1 -4 -1.5"></path>' +
                '</svg>'
        });
        this.nodes.button.appendChild(icon);
        return this.nodes.button;
    }
    /**
     * Wrap range with <b> tag
     *
     * @param {Range} range - range to wrap
     */
    surround(range) {
        if (this.state) {
            this.unwrap(range);
            return;
        }
        this.wrap(range);
    }
    wrap(range) {
        const selectedText = range.extractContents();
        const mark = document.createElement('S');
        mark.appendChild(selectedText);
        range.insertNode(mark);
        this.api.selection.expandToTag(mark);
    }
    unwrap(range) {
        const mark = this.api.selection.findParentTag('S');
        const text = range.extractContents();
        mark.remove();
        range.insertNode(text);
    }
    /**
     * Check selection and set activated state to button if there are <b> tag
     *
     * @param {Selection} selection - selection to check
     *
     * @returns {boolean}
     */
    checkState(selection) {
        const mark = this.api.selection.findParentTag("S");
        this.state = !!mark;
        this.nodes.button.classList.toggle(this.CSS.buttonActive, this.state);
        return this.state;
    }
}
/**
 * Specifies Tool as Inline Toolbar Tool
 *
 * @returns import BoldInlineTool from "../../../editor/src/components/inline-tools/inline-tool-bold";{boolean}
 */
StrikeInlineTool.isInline = true;
/**
 * Title for hover-tooltip
 */
StrikeInlineTool.title = 'Strike';
