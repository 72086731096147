<template>
    <div>
        <div v-for="item in getPermissions()" :key="item.permission_id">
            <div class="form-check form-switch d-inline-block">
                <input class="form-check-input" type="checkbox" role="switch" 
                    :id="'pm' + item.permission_id" 
                    :data-permission_id="item.permission_id"
                    :data-source_id="item.source_id"
                    :checked="props.user.permissions.includes(item.permission_code)" 
                    @change="handleChange"
                />
                <label class="form-check-label" :for="'pm' + item.permission_id" v-text="item.permission_name" />
            </div>
        </div>
    </div>
</template>


<script>
import {defineComponent, onMounted, ref} from "vue"
import Api from "@/modules/user/components/edit/services/Api"
import Notify from "@/services/Notify"
  
export default defineComponent({
    name: "Permissions",
    components: {
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        let permissions = ref([])

        onMounted(() => {
            setPermissions()
        })

        function setPermissions() {
            Api.permissions().then((resp) => {
                permissions.value = resp.item;
            })
        }

        function getPermissions() {
            return permissions.value
        }

        function handleChange(e) {
            let source_id = parseInt(e.target.dataset.source_id)
            let permission_id = parseInt(e.target.dataset.permission_id)

            if (e.target.checked) {
                Api.permissionsUserSet({
                    source_id: source_id,
                    permission_id: permission_id,
                    user_id: props.user.id
                }).then(() => {
                    Notify.success("Разрешение выдано");
                }).catch(() => {
                    e.target.checked = false
                    Notify.error('Не удалось выдать разрешение')
                })
            } else {
                Api.permissionsUserDelete(props.user.id, permission_id, source_id).then(() => {
                    Notify.success("Разрешение отнято");
                }).catch(() => {
                    e.target.checked = true
                    Notify.error('Не удалось отнять разрешение')
                })
            }
        }

        return {
            props,
            getPermissions,
            handleChange
        }
    }
})
</script>
  
<style scoped></style>
  