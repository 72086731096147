import Api from "@/modules/shops/pages/categories/services/Api"

export default {
    data: {
        title: "Редактирование категории",
        btn: "Редактировать",
        page: "update",
    },
    action: ({modal, payload}) => {
        return new Promise((resolve, reject) => {
            return Api.update(payload).then(() => {
                modal.hide();
                resolve("Отредактирован")
            }).catch(() => {
                reject("Ошибка при редактировании")
            }).finally(() => {})
        })
    }
}