import PermissionBase from "@/modules/post/services/permissions/PermissionBase"

export default new class Permission extends PermissionBase {
    isUpdate(channelId) {
        return this.hasPermission(channelId, "update")
    }

    isCreate(channelId) {
        return this.hasPermission(channelId, "update")
    }

    isDelete(channelId) {
        return this.hasPermission(channelId, "update")
    }

    isShowPermissionBtn() {
        return false
    }
}
