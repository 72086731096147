import Image from "@/services/Image"

export default class ShopImage {
    static handle(
        host,
        name,
        w = null,
        h = null,
    ) {
        if (host && name) {
            return Image.handle(host, name, w, h)
        } else {
            return ShopImage.default()
        }
    }

    static default() {
        return "/assets/static/post/no_image.jpg"
    }
}
