import PostApiBase from "@/modules/post/services/ApiBase"

export default class Api {
    static item(postId) {
        return PostApiBase.item({
            post_id: postId
        }).then((resp) => {
            return resp.data["item"]
        })
    }
}
