<template>
  <div>
    <img :src="image" v-for="(image, index) in imagesThumb" :key="index" @click="onShow(index)" class="lightbox-image">

    <vue-easy-lightbox
        :visible="visible"
        :imgs="imagesOrigin"
        :index="indexRef"
        @hide="onHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
//https://github.com/XiongAmao/vue-easy-lightbox
import './css/style.css'
// If VueApp is already registered with VueEasyLightbox, there is no need to register it here.
import VueEasyLightbox from 'vue-easy-lightbox'
import { ref, defineComponent } from 'vue'

export default defineComponent({
  components: {
    VueEasyLightbox
  },
  props: {
    images: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    }
  },
  setup(props) {
    const visible = ref(false)
    const indexRef = ref(props.index)
    const imagesThumb = ref(props.images.thumb)
    const imagesOrigin = ref(props.images.origin)

    const onShow = (index) => {
      indexRef.value = index
      visible.value = true
    }

    const onHide = () => {
      visible.value = false
    }

    return {
      visible,
      indexRef,
      imagesThumb,
      imagesOrigin,
      onShow,
      onHide
    }
  }
})
</script>