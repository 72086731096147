import Shops from "@/modules/shops/pages/shops/Shops"
import Categories from "@/modules/shops/pages/categories/Categories"
import authMiddleware from "@/plugins/router/middlewares/auth"

export default [{
    path: "/shops",
    name: "Shops",
    component: Shops,
    meta: {
        middleware: authMiddleware,
    }
}, {
    path: "/shops/categories",
    name: "ShopsCategories",
    component: Categories,
    meta: {
        middleware: authMiddleware,
    }
},]
