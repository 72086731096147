import store from "@/plugins/store"

export default new class Store {
    setPermissions() {
        return store.dispatch("role/setPermissions")
    }

    getPermissions() {
        return store.getters["role/getPermissions"]
    }
}
