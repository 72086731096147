<template>
    <div class="card" id="type-of-steps">
        <div class="card-body">
            <div class="divide-y">
                <label class="row">
                <span class="col-4">
                    <span class="form-label">Тип выдачи</span>
                </span>
                <span class="col-8">
                    <select class="form-select" v-model="fields.type_of_steps" @change="onChange">
                        <option :key="item.id" :value="item.id" v-for="(item) in getValues()">{{ item.name }}</option>
                    </select>
                    <div v-if="v$.type_of_steps.$invalid">
                        <span :class="{'is-invalid': v$.type_of_steps.$invalid && v$.type_of_steps.$dirty}"></span>
                        <div class="invalid-feedback" v-for="error of v$.type_of_steps.$silentErrors" :key="error.$uid">
                            {{ error.$message }}
                        </div>
                    </div>
                </span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, watch, onMounted, reactive} from "vue"
import values from "@/modules/promopushka/services/values"
import {useVuelidate} from "@vuelidate/core/dist/index.esm"
import {minLength} from "@/plugins/vuelidator"

export default defineComponent({
    name: "TypeSteps",
    props: {
        data: {
            type: [Object, null],
            default: null,
        },
    },
    setup(props, {emit}) {
        const fields = reactive({
            type_of_steps: 0,
        })

        onMounted(() => {
            fields.type_of_steps = props.data.val ?? 0;
        })

        watch(() => props.data.val, (val) => {
            fields.type_of_steps = val
        })

        const rules = {
            type_of_steps: {
                minLength: minLength(0),
            },
        }

        let $externalResults = reactive({})    

        const v$ = useVuelidate(rules, fields, {$externalResults})

        function validate(fn) {
            v$.value.$clearExternalResults()

            return v$.value.$validate().then(async () => {
                await fn()
            })
        }

        watch(() => props.data["validations"], (val) => {
            validate(() => {
                if (val?.type_of_steps !== undefined) {
                    $externalResults.type_of_steps = val["type_of_steps"][0]
                }
            })
        })

        function onChange() {
            validate(() => {})
            emit("onChange", fields.type_of_steps)
        }

        function getValues() {
            return values.types.steps;
        }

        return {
            getValues,
            onChange,
            v$,
            fields,
        }
    }
})
</script>

<style scoped>

</style>
