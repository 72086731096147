export default new class StorageBase {
    set(key, val, isObject) {
        let result = isObject ? JSON.stringify(val) : val
        localStorage.setItem(key, result)
        return this
    }

    get(key, isObject) {
        try {
            let result = localStorage.getItem(key)
            return isObject ? JSON.parse(result) : result
        } catch (err) {
            return null
        }
    }

    remove(key) {
        return localStorage.removeItem(key)
    }
}
