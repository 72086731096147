/**
 * TextRange interface for IE9-
 */
import * as _ from './utils';
import $ from './dom';
/**
 * Working with selection
 *
 * @typedef {SelectionUtils} SelectionUtils
 */
export default class SelectionUtils {
    constructor() {
        /**
         * Selection instances
         *
         * @todo Check if this is still relevant
         */
        this.instance = null;
        this.selection = null;
        /**
         * This property can store SelectionUtils's range for restoring later
         *
         * @type {Range|null}
         */
        this.savedSelectionRange = null;
        /**
         * Fake background is active
         *
         * @returns {boolean}
         */
        this.isFakeBackgroundEnabled = false;
        /**
         * Native Document's commands for fake background
         */
        this.commandBackground = 'backColor';
        this.commandRemoveFormat = 'removeFormat';
    }
    /**
     * Editor styles
     *
     * @returns {{editorWrapper: string, editorZone: string}}
     */
    static get CSS() {
        return {
            editorWrapper: 'codex-editor',
            editorZone: 'codex-editor__redactor',
        };
    }
    /**
     * Returns selected anchor
     * {@link https://developer.mozilla.org/ru/docs/Web/API/Selection/anchorNode}
     *
     * @returns {Node|null}
     */
    static get anchorNode() {
        const selection = window.getSelection();
        return selection ? selection.anchorNode : null;
    }
    /**
     * Returns selected anchor element
     *
     * @returns {Element|null}
     */
    static get anchorElement() {
        const selection = window.getSelection();
        if (!selection) {
            return null;
        }
        const anchorNode = selection.anchorNode;
        if (!anchorNode) {
            return null;
        }
        if (!$.isElement(anchorNode)) {
            return anchorNode.parentElement;
        }
        else {
            return anchorNode;
        }
    }
    /**
     * Returns selection offset according to the anchor node
     * {@link https://developer.mozilla.org/ru/docs/Web/API/Selection/anchorOffset}
     *
     * @returns {number|null}
     */
    static get anchorOffset() {
        const selection = window.getSelection();
        return selection ? selection.anchorOffset : null;
    }
    /**
     * Is current selection range collapsed
     *
     * @returns {boolean|null}
     */
    static get isCollapsed() {
        const selection = window.getSelection();
        return selection ? selection.isCollapsed : null;
    }
    /**
     * Check current selection if it is at Editor's zone
     *
     * @returns {boolean}
     */
    static get isAtEditor() {
        return this.isSelectionAtEditor(SelectionUtils.get());
    }
    /**
     * Check if passed selection is at Editor's zone
     *
     * @param selection - Selectoin object to check
     */
    static isSelectionAtEditor(selection) {
        if (!selection) {
            return false;
        }
        /**
         * Something selected on document
         */
        let selectedNode = (selection.anchorNode || selection.focusNode);
        if (selectedNode && selectedNode.nodeType === Node.TEXT_NODE) {
            selectedNode = selectedNode.parentNode;
        }
        let editorZone = null;
        if (selectedNode && selectedNode instanceof Element) {
            editorZone = selectedNode.closest(`.${SelectionUtils.CSS.editorZone}`);
        }
        /**
         * SelectionUtils is not out of Editor because Editor's wrapper was found
         */
        return editorZone ? editorZone.nodeType === Node.ELEMENT_NODE : false;
    }
    /**
     * Check if passed range at Editor zone
     *
     * @param range - range to check
     */
    static isRangeAtEditor(range) {
        if (!range) {
            return;
        }
        let selectedNode = range.startContainer;
        if (selectedNode && selectedNode.nodeType === Node.TEXT_NODE) {
            selectedNode = selectedNode.parentNode;
        }
        let editorZone = null;
        if (selectedNode && selectedNode instanceof Element) {
            editorZone = selectedNode.closest(`.${SelectionUtils.CSS.editorZone}`);
        }
        /**
         * SelectionUtils is not out of Editor because Editor's wrapper was found
         */
        return editorZone ? editorZone.nodeType === Node.ELEMENT_NODE : false;
    }
    /**
     * Methods return boolean that true if selection exists on the page
     */
    static get isSelectionExists() {
        const selection = SelectionUtils.get();
        return !!selection.anchorNode;
    }
    /**
     * Return first range
     *
     * @returns {Range|null}
     */
    static get range() {
        return this.getRangeFromSelection(this.get());
    }
    /**
     * Returns range from passed Selection object
     *
     * @param selection - Selection object to get Range from
     */
    static getRangeFromSelection(selection) {
        return selection && selection.rangeCount ? selection.getRangeAt(0) : null;
    }
    /**
     * Calculates position and size of selected text
     *
     * @returns {DOMRect | ClientRect}
     */
    static get rect() {
        let sel = document.selection, range;
        let rect = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        };
        if (sel && sel.type !== 'Control') {
            sel = sel;
            range = sel.createRange();
            rect.x = range.boundingLeft;
            rect.y = range.boundingTop;
            rect.width = range.boundingWidth;
            rect.height = range.boundingHeight;
            return rect;
        }
        if (!window.getSelection) {
            _.log('Method window.getSelection is not supported', 'warn');
            return rect;
        }
        sel = window.getSelection();
        if (sel.rangeCount === null || isNaN(sel.rangeCount)) {
            _.log('Method SelectionUtils.rangeCount is not supported', 'warn');
            return rect;
        }
        if (sel.rangeCount === 0) {
            return rect;
        }
        range = sel.getRangeAt(0).cloneRange();
        if (range.getBoundingClientRect) {
            rect = range.getBoundingClientRect();
        }
        // Fall back to inserting a temporary element
        if (rect.x === 0 && rect.y === 0) {
            const span = document.createElement('span');
            if (span.getBoundingClientRect) {
                // Ensure span has dimensions and position by
                // adding a zero-width space character
                span.appendChild(document.createTextNode('\u200b'));
                range.insertNode(span);
                rect = span.getBoundingClientRect();
                const spanParent = span.parentNode;
                spanParent.removeChild(span);
                // Glue any broken text nodes back together
                spanParent.normalize();
            }
        }
        return rect;
    }
    /**
     * Returns selected text as String
     *
     * @returns {string}
     */
    static get text() {
        return window.getSelection ? window.getSelection().toString() : '';
    }
    /**
     * Returns window SelectionUtils
     * {@link https://developer.mozilla.org/ru/docs/Web/API/Window/getSelection}
     *
     * @returns {Selection}
     */
    static get() {
        return window.getSelection();
    }
    /**
     * Set focus to contenteditable or native input element
     *
     * @param element - element where to set focus
     * @param offset - offset of cursor
     *
     * @returns {DOMRect} of range
     */
    static setCursor(element, offset = 0) {
        const range = document.createRange();
        const selection = window.getSelection();
        /** if found deepest node is native input */
        if ($.isNativeInput(element)) {
            if (!$.canSetCaret(element)) {
                return;
            }
            element.focus();
            element.selectionStart = element.selectionEnd = offset;
            return element.getBoundingClientRect();
        }
        range.setStart(element, offset);
        range.setEnd(element, offset);
        selection.removeAllRanges();
        selection.addRange(range);
        return range.getBoundingClientRect();
    }
    /**
     * Adds fake cursor to the current range
     *
     * @param [container] - if passed cursor will be added only if container contains current range
     */
    static addFakeCursor(container) {
        const range = SelectionUtils.range;
        const fakeCursor = $.make('span', 'codex-editor__fake-cursor');
        fakeCursor.dataset.mutationFree = 'true';
        if (!range || (container && !container.contains(range.startContainer))) {
            return;
        }
        range.collapse();
        range.insertNode(fakeCursor);
    }
    /**
     * Removes fake cursor from a container
     *
     * @param container - container to look for
     */
    static removeFakeCursor(container = document.body) {
        const fakeCursor = $.find(container, `.codex-editor__fake-cursor`);
        fakeCursor && fakeCursor.remove();
    }
    /**
     * Removes fake background
     */
    removeFakeBackground() {
        if (!this.isFakeBackgroundEnabled) {
            return;
        }
        const selection = window.getSelection();
        selection.anchorNode.parentElement.classList.remove('ce-isFakeBackground');
        this.isFakeBackgroundEnabled = false;
        document.execCommand(this.commandRemoveFormat);
    }
    /**
     * Sets fake background
     */
    setFakeBackground() {
        document.execCommand(this.commandBackground, false, '#a8d6ff');
        const selection = window.getSelection();
        selection.anchorNode.parentElement.classList.add('ce-isFakeBackground');
        this.isFakeBackgroundEnabled = true;
    }
    /**
     * Save SelectionUtils's range
     */
    save() {
        this.savedSelectionRange = SelectionUtils.range;
    }
    /**
     * Restore saved SelectionUtils's range
     */
    restore() {
        if (!this.savedSelectionRange) {
            return;
        }
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(this.savedSelectionRange);
    }
    /**
     * Clears saved selection
     */
    clearSaved() {
        this.savedSelectionRange = null;
    }
    /**
     * Collapse current selection
     */
    collapseToEnd() {
        const sel = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(sel.focusNode);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
    }
    /**
     * Looks ahead to find passed tag from current selection
     *
     * @param  {string} tagName       - tag to found
     * @param  {string} [className]   - tag's class name
     * @param  {number} [searchDepth] - count of tags that can be included. For better performance.
     *
     * @returns {HTMLElement|null}
     */
    findParentTag(tagName, className, searchDepth = 10) {
        const selection = window.getSelection();
        let parentTag = null;
        /**
         * If selection is missing or no anchorNode or focusNode were found then return null
         */
        if (!selection || !selection.anchorNode || !selection.focusNode) {
            return null;
        }
        /**
         * Define Nodes for start and end of selection
         */
        const boundNodes = [
            /** the Node in which the selection begins */
            selection.anchorNode,
            /** the Node in which the selection ends */
            selection.focusNode,
        ];
        /**
         * For each selection parent Nodes we try to find target tag [with target class name]
         * It would be saved in parentTag variable
         */
        boundNodes.forEach((parent) => {
            /** Reset tags limit */
            let searchDepthIterable = searchDepth;
            while (searchDepthIterable > 0 && parent.parentNode) {
                /**
                 * Check tag's name
                 */
                if (parent.tagName === tagName) {
                    /**
                     * Save the result
                     */
                    parentTag = parent;
                    /**
                     * Optional additional check for class-name mismatching
                     */
                    if (className && parent.classList && !parent.classList.contains(className)) {
                        parentTag = null;
                    }
                    /**
                     * If we have found required tag with class then go out from the cycle
                     */
                    if (parentTag) {
                        break;
                    }
                }
                /**
                 * Target tag was not found. Go up to the parent and check it
                 */
                parent = parent.parentNode;
                searchDepthIterable--;
            }
        });
        /**
         * Return found tag or null
         */
        return parentTag;
    }
    /**
     * Expands selection range to the passed parent node
     *
     * @param {HTMLElement} element - element which contents should be selcted
     */
    expandToTag(element) {
        const selection = window.getSelection();
        selection.removeAllRanges();
        const range = document.createRange();
        range.selectNodeContents(element);
        selection.addRange(range);
    }
}
