<template>
  <div class="card" id="is-partner-links">
    <div class="card-body">
      <div class="divide-y">
        <div class="row">
          <span class="col">
            <label class="form-label">Партнерить ссылки</label>
          </span>
          <span class="col-auto">
            <label class="form-check form-check-single form-switch">
              <input class="form-check-input" type="checkbox" v-model="isEnable" @change="onChange">
            </label>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {defineComponent, ref, watch, onMounted} from "vue"

export default defineComponent({
  name: "IsPartnerLinks",
  props: {
    data: {
      type: Object,
      default: null,
    },
    validations: {},
  },
  setup(props, {emit}) {
    let isEnable = ref(false)

    onMounted(() => {
      if (props.data.enable === 1) {
        isEnable.value = true
      } else {
        isEnable.value = false
      }
    })

    watch(() => props.data.enable, (val) => {
      isEnable.value = val === 1
    })

    function onChange() {
      let value = isEnable.value === true ? 1 : 0
      emit("onChange", value)
    }

    return {
      isEnable,
      onChange,
    }
  }
})
</script>

<style scoped>

</style>
