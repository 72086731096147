<template>
    <div class="card" id="status">
        <div class="card-body">
            <div class="divide-y">
                <div class="row">
                    <span class="col-4">
                        <label class="form-label">Статус</label>
                    </span>
                    <span class="col-8">
                        <select class="form-select" v-model="fields.status" @change="onChange">
                            <option :key="item.id" :value="item.id" v-for="(item) in getValues()">{{ item.name }}</option>
                        </select>
                        <div v-if="v$.status.$invalid">
                            <span :class="{'is-invalid': v$.status.$invalid && v$.status.$dirty}"></span>
                            <div class="invalid-feedback" v-for="error of v$.status.$silentErrors" :key="error.$uid">
                                {{ error.$message }}
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, watch, ref, onMounted, reactive} from "vue"
import values from "@/modules/promopushka/services/values"
import {useVuelidate} from "@vuelidate/core/dist/index.esm"
import {minLength} from "@/plugins/vuelidator"

export default defineComponent({
    name: "Status",
    props: {
        data: {
            type: [Object, null],
            default: null,
        },
    },
    setup(props, {emit}) {
        let onlyDisable = ref(false)

        const fields = reactive({
            status: 0,
        })

        onMounted(() => {
            onlyDisable.value = props.data.onlyDisable
            fields.status = props.data.val ?? 0;
        })

        watch(() => props.data.val, (val) => {
            fields.status = val
        })

        watch(() => props.data.onlyDisable, (val) => {
            onlyDisable.value = val
        })

        const rules = {
            status: {
                minLength: minLength(0),
            },
        }

        let $externalResults = reactive({})    

        const v$ = useVuelidate(rules, fields, {$externalResults})

        function validate(fn) {
            v$.value.$clearExternalResults()

            return v$.value.$validate().then(async () => {
                await fn()
            })
        }

        watch(() => props.data["validations"], (val) => {
            validate(() => {
                if (val?.status !== undefined) {
                    $externalResults.status = val["status"][0]
                }
            })
        })

        function onChange() {
            validate(() => {})
            emit("onChange", fields.status)
        }

        function getValues() {
            let statusValues = [];
            if (onlyDisable.value) {
                statusValues = values.status.filter((status) => status.id === 0);
            } else {
                statusValues = values.status
            }
            return statusValues;
        }

        return {
            onChange,
            getValues,
            v$,
            fields,
        }
    }
})
</script>

<style scoped>

</style>
