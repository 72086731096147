import store from "@/plugins/store"

export default new class Store {
    setChannels() {
        return store.dispatch("post/setChannels")
    }

    getChannels() {
        return store.getters["post/getChannels"]
    }
}
