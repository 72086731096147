<template>
    <div class="card" id="code_to_check">
        <div class="card-body">
            <div class="divide-y">
                <div class="row">
                    <span class="col-4">
                        <label class="form-label">Код для проверки</label>
                    </span>
                    <span class="col-8">
                        <input type="text" class="form-control" v-model="fields.code_to_check" placeholder="Код для проверки" @change="onChange" />
                        <div v-if="v$.code_to_check.$invalid">
                            <span :class="{'is-invalid': v$.code_to_check.$invalid && v$.code_to_check.$dirty}"></span>
                            <div class="invalid-feedback" v-for="error of v$.code_to_check.$silentErrors" :key="error.$uid">
                                {{ error.$message }}
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, watch, onMounted, reactive} from "vue"
import {useVuelidate} from "@vuelidate/core/dist/index.esm"
import {minLength} from "@/plugins/vuelidator"

export default defineComponent({
    name: "CodeCheck",
    props: {
        data: {
            type: [Object, null],
            default: null,
        },
    },
    setup(props, {emit}) {
        const fields = reactive({
            code_to_check: null,
        })

        onMounted(() => {
            fields.code_to_check = props.data.val;
        })

        watch(() => props.data.val, (val) => {
            fields.code_to_check = val
        })

        const rules = {
            code_to_check: {
                minLength: minLength(0),
            },
        }

        let $externalResults = reactive({})    

        const v$ = useVuelidate(rules, fields, {$externalResults})

        function validate(fn) {
            v$.value.$clearExternalResults()

            return v$.value.$validate().then(async () => {
                await fn()
            })
        }

        watch(() => props.data["validations"], (val) => {
            validate(() => {
                if (val?.code_to_check !== undefined) {
                    $externalResults.code_to_check = val["code_to_check"][0]
                }
            })
        })

        function onChange() {
            validate(() => {})
            emit("onChange", fields.code_to_check)
        }

        return {
            onChange,
            v$,
            fields,
        }
    }
})
</script>

<style scoped>

</style>
