export default class Image {
    static handle(host, name, w = null, h = null) {
        let link

        if (w && h === null) {
            link = host + '/i/w' + w + '/' + name
        } else if (w === null && h) {
            link = host + '/i/h' + h + '/' + name
        } else if (w && h) {
            link = host + '/i/' + w + 'x' + h + '/' + name
        } else {
            link = host + '/i/' + name
        }

        return link
    }
}
