<template>
  <div id="promopushka-channel">
    <span v-html="getTitle()"></span>
  </div>
</template>

<script>
import "./css/style.css"
import {defineComponent} from "vue"

export default defineComponent({
  name: "Channel",
  props: {},
  setup() {
    function getTitle() {
      let img = `<img width="20" class="rounded" src="/assets/static/promopushka/logo.jpg" alt="">`
      let link = `<a href="/promopushka">Промопушка</a>`

      return img + " " + link
    }

    return {
      getTitle,
    }
  }
})
</script>

<style scoped></style>