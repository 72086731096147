import {axios as http} from "@/plugins/http"

export default class Api {
    static item(query) {
        let config = {
            params: query,
        }
        return http.get("/api/v1/shop/shop/item", config);
    }

    static items(query) {
        let config = {
            params: query,
        }
        return http.get("/api/v1/shop/shop/items", config);
    }

    static create(query) {
        return http.post("/api/v1/shop/shop/create", query);
    }

    static update(query) {
        return http.post("/api/v1/shop/shop/update", query);
    }

    static categories(query) {
        let config = {
            params: query,
        }
        return http.get("/api/v1/shop/shop/categories", config);
    }

    static delete(query) {
        let config = {
            params: query,
        }
        return http.get("/api/v1/shop/shop/delete", config);
    }
}
