<template>
  <div class="page">
    <div class="sticky-top">
      <Header />
      <Menu />
    </div>
    <div class="page-wrapper">

      <router-view :key="$route.fullPath"></router-view>

      <footer class="footer footer-transparent d-print-none">
        <div class="container-xl">
          <div class="row text-center align-items-center flex-row-reverse">
            <div class="col-12 col-lg-auto mt-3 mt-lg-0">
              <ul class="list-inline list-inline-dots mb-0">
                <li class="list-inline-item">
                  Copyright &copy; 2023
                  Все права защищены.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header/Header"
import Menu from "@/components/menu/Menu"
import {useRoute} from "vue-router"
import {defineComponent} from "vue"

export default defineComponent({
  name: "Default.vue",
  components: {
    Header,
    Menu,
  },
  setup() {
    const route = useRoute()

    return {
      route,
    }
  }
})
</script>
