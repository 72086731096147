import authMiddleware from "@/plugins/router/middlewares/auth"
import Catalog from "@/modules/catalog/pages/Catalog"

export default [{
    path: "/catalog",
    name: "Catalog",
    component: Catalog,
    meta: {
        middleware: authMiddleware,
    }
},]
