import $ from "../../../../packages/editor/src/components/dom";
export default class moveUp {
    /**
     * MoveUpTune constructor
     *
     * @param {API} api - Editor's API
     */
    constructor({ api }) {
        /**
         * Styles
         *
         * @type {{wrapper: string}}
         */
        this.CSS = {
            button: 'ce-settings__item',
            wrapper: 'ce-tune-move-up',
            animation: 'wobble',
        };
        this.api = api;
    }
    /**
     * Create "MoveUp" button and add click event listener
     *
     * @returns {HTMLElement}
     */
    render() {
        let moveUpButton = this.createBtn();
        this.api.listeners.on(moveUpButton, 'click', (event) => this.handleClick(event, moveUpButton), false);
        /**
         * Enable tooltip module on button
         */
        this.api.tooltip.onHover(moveUpButton, this.api.i18n.t('Move up'), {
            hidingDelay: 300,
        });
        return moveUpButton;
    }
    createBtn() {
        const moveUpButton = $.make('div', [this.CSS.button, this.CSS.wrapper], {});
        let text = $.make('span', null, {
            innerHTML: this.api.i18n.t('Переместить вверх')
        });
        let icon = $.make('span', null, {
            innerHTML: '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
                '  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.293 3.293a1 1 0 011.414 0l7 7a1 1 0 01-1.414 1.414L13 6.414V20a1 1 0 11-2 0V6.414l-5.293 5.293a1 1 0 01-1.414-1.414l7-7z" fill="#000"></path>' +
                '</svg>'
        });
        moveUpButton.appendChild(icon);
        moveUpButton.appendChild(text);
        return moveUpButton;
    }
    /**
     * Move current block up
     *
     * @param {MouseEvent} event - click event
     * @param {HTMLElement} button - clicked button
     */
    handleClick(event, button) {
        const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
        const currentBlock = this.api.blocks.getBlockByIndex(currentBlockIndex);
        const previousBlock = this.api.blocks.getBlockByIndex(currentBlockIndex - 1);
        if (currentBlockIndex === 0 || !currentBlock || !previousBlock) {
            button.classList.add(this.CSS.animation);
            window.setTimeout(() => {
                button.classList.remove(this.CSS.animation);
            }, 500);
            return;
        }
        const currentBlockElement = currentBlock.holder;
        const previousBlockElement = previousBlock.holder;
        /**
         * Here is two cases:
         *  - when previous block has negative offset and part of it is visible on window, then we scroll
         *  by window's height and add offset which is mathematically difference between two blocks
         *
         *  - when previous block is visible and has offset from the window,
         *      than we scroll window to the difference between this offsets.
         */
        const currentBlockCoords = currentBlockElement.getBoundingClientRect(), previousBlockCoords = previousBlockElement.getBoundingClientRect();
        let scrollUpOffset;
        if (previousBlockCoords.top > 0) {
            scrollUpOffset = Math.abs(currentBlockCoords.top) - Math.abs(previousBlockCoords.top);
        }
        else {
            scrollUpOffset = window.innerHeight - Math.abs(currentBlockCoords.top) + Math.abs(previousBlockCoords.top);
        }
        window.scrollBy(0, -1 * scrollUpOffset);
        /** Change blocks positions */
        this.api.blocks.move(currentBlockIndex - 1);
        this.api.toolbar.toggleBlockSettings(true);
        /** Hide the Tooltip */
        this.api.tooltip.hide();
    }
}
/**
 * Set Tool is Tune
 */
moveUp.isTune = true;
