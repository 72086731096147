import AuthStore from '@/modules/auth/services/Store'
import Redirect from '@/services/Redirect'

export default function guest() {
    if (AuthStore.isAuth()) {
        return new Redirect().setRoute({
            name: 'Dashboard',
        });
    }

    return true;
}