<template>
  <div id="post-card-title" class="page-title flex-wrap row g-2">
    <div class="datetime col-auto" v-html="getTitle()"></div>
    <Statistic
        @onCountSell="onCountSellStatistic"
        @onClickChain="onClickChainStatistic"
        :data="{ post_id: data['post_id'] }"
        class="col-auto"
    />
  </div>
</template>

<script>
import "./css/style.css"
import {defineComponent, onMounted, ref, watch} from "vue"
import Image from "@/services/Image"
import {getChannels} from "@/modules/post/plugins/title/services/channels"
import DTBackend from "@/services/DTBackend"
import {useRouter} from "vue-router/dist/vue-router"
import Statistic from "./components/statistic/Statistic"

export default defineComponent({
  name: "Title",
  components: {
    Statistic,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    let statusPost = ref("")
    let titleChannel = ref("")
    let suffixPost = ref("")
    let channels = ref(null)
    const router = useRouter()

    watch(() => [
      props.data["status"],
      props.data["is_del"],
      props.data["post_id"],
      props.data["scheduled_published"]
    ], () => {
      renderTitle()
    })

    watch(() => getChannels(), (val) => {
      setChannels(val)
      renderTitle()
    })

    onMounted(() => {
      setChannels(getChannels())
      renderTitle()
    })

    function setChannels(val) {
      channels.value = val
    }

    function renderTitle() {
      loadChannel(props.data["channel_id"])
      loadStatusPost(props.data["status"])
      createSuffix(props.data["status"])
    }

    function loadChannel(channelId) {
      if (channels.value === null) {
        return false
      }

      let result = channels.value[channelId]

      if (result["image_host"] === null || result["image_name"] === null) {
        throw Error("Отсутствует иконка")
      }

      let src = Image.handle(result["image_host"], result["image_name"], 30, 30);

      let href = router.resolve({
        name: 'Posts',
        params: {
          channel_id: channelId,
        },
      }).href

      let img = `<img width="30" class="rounded" src="${src}" alt="">`
      let link = `<a href="${href}">${result["title"]}</a>`

      titleChannel.value = img + " " + link
    }

    function loadStatusPost(status) {
      if (postStatuses.isDeletedStatus(status)) {
        statusPost.value = "Удален"
      } else if (postStatuses.isPublished(status)) {
        statusPost.value = ""
      } else if (postStatuses.isScheduled(status)) {
        statusPost.value = "Запланирован"
      } else if (postStatuses.isDraft(status)) {
        statusPost.value = "Черновик"
      } else if (postStatuses.isPrePublished(status)) {
        statusPost.value = "Публикуется..."
      } else if (postStatuses.isPreDeleted(status)) {
        statusPost.value = "Удаляется..."
      } else if (postStatuses.isCanceled(status)) {
        statusPost.value = "Отменен"
      }
    }

    function createSuffix(status) {
      if (postStatuses.isDeletedStatus(status)) {
        suffixPost.value = props.data["post_id"]
      } else if (postStatuses.isPublished(status)) {
        suffixPost.value = "Пост " + props.data["post_id"]
      } else if (postStatuses.isScheduled(status)) {
        if (props.data["scheduled_published"] !== null) {
          let dt = new DTBackend(props.data["scheduled_published"])

          suffixPost.value = dt.get("%D.%M.%y <span>%H:%I</span>")
        } else {
          suffixPost.value = ""
        }
      } else if (postStatuses.isDraft(status)) {
        let postId = props.data["post_id"] !== null ? props.data["post_id"] : ""
        suffixPost.value = postId
      } else if (postStatuses.isPrePublished(status)) {
        suffixPost.value = "Пост " + props.data["post_id"]
      } else if (postStatuses.isPreDeleted(status)) {
        suffixPost.value = "Пост " + props.data["post_id"]
      }
    }

    function getTitle() {
      let result = ""

      if (titleChannel.value) {
        result += titleChannel.value + ", "
      }

      if (statusPost.value) {
        result += statusPost.value + " "
      }

      if (suffixPost.value) {
        result += suffixPost.value
      }

      return result
    }

    let postStatuses = {
      isDeletedStatus: (status) => {
        return status === "deleted"
      },
      isPublished: (status) => {
        return status === "published"
      },
      isPrePublished: (status) => {
        return status === "prePublished"
      },
      isScheduled: (status) => {
        return status === "scheduled"
      },
      isPreDeleted: (status) => {
        return status === "preDeleted"
      },
      isCanceled: (status) => {
        return status === "canceled"
      },
      isDraft: (status) => {
        return status === "draft" || status === null
      },
    }

    function onCountSellStatistic(payload) {
      emit("onCountSellStatistic", {
        "post_id": payload["post_id"],
        "element": payload["element"],
      })
    }

    function onClickChainStatistic(payload) {
      emit("onClickChainStatistic", {
        "post_id": payload["post_id"],
        "element": payload["element"],
      })
    }

    return {
      getTitle,
      channels,
      onCountSellStatistic,
      onClickChainStatistic,
    }
  },
})
</script>

<style scoped></style>
