<template>
  <div class="" width="100%">
    <Line :data="chartData" />
  </div>
</template>

<script setup>
import {Line} from 'vue-chartjs'
import {Chart as ChartJS, Title, Legend, CategoryScale, LineElement, PointElement, LinearScale} from 'chart.js'

const props = defineProps(['labelGraph', 'nameGraph', 'datasets', 'color']);

ChartJS.register(Title, Legend, CategoryScale, LinearScale, LineElement, PointElement);

let chartData = {
  labels: props.labelGraph,
  datasets: [
    {
      label: props.nameGraph,
      data: props.datasets,
      pointStyle: 'line',
      backgroundColor: props.color,
      borderColor: props.color,
    }
  ],
}

</script>