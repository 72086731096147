import {ref} from "vue"
import Helper from "@/services/Helper"
import Api from "./Api"

let isSaving = ref(false)
let stackChanges = ref([])
let config = ref([])
let interval = ref(null)

function autosaveInit(config) {
    setConfig(config)

    setStackChanges(config["stack"])

    interval.value = setInterval(() => {
        if (isSavingProcess()) {
            return false
        }

        if (!hasStackChanges()) {
            return false
        }

        autosave().then(() => {
            clearStackChanges()
        })
    }, 3000)
}

function autosaveStop() {
    clearInterval(interval.value)
    clearStackChanges()
    clearConfig()
}

function clearConfig() {
    config.value = []
}

function clearStackChanges() {
    stackChanges.value = []
}

function autoSaveApi(payload) {
    return Api.saveDraft(payload)
}

function autosave() {
    return new Promise((resolve, reject) => {
        savingOn()

        autoSaveApi(getStack(getID())).then(() => {
            resolve()
        }).catch(() => {
            reject()
        }).finally(() => {
            savingOff()
        })
    })
}

function getStack(ID) {
    let formData = {}

    formData["post_id"] = ID

    Object.entries(getStackChanges()).forEach(([key, val]) => {
        formData[key] = val
    })

    return formData
}

function savingOn() {
    isSaving.value = true
}

function savingOff() {
    isSaving.value = false
}

function hasStackChanges() {
    return !Helper.isEmptyObject(stackChanges.value);
}

function getStackChanges() {
    return stackChanges.value
}

function setStackChanges(payload) {
    Object.entries(getConfig("stackMap")).forEach(([key, val]) => {
        stackChanges.value[key] = payload[val]
    })
}

function isSavingProcess() {
    return isSaving.value === true
}

function addStackChanges(object) {
    let key = Object.keys(object)[0]
    stackChanges.value[key] = object[key] !== null ? object[key] : null
}

function setConfig(cfg) {
    config.value = cfg
}

function getID() {
    return getConfig("ID")
}

function getConfig(key) {
    return config.value[key]
}

export {isSavingProcess, autosaveInit, addStackChanges, autosaveStop}
