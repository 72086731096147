export default class Redirect {
    constructor() {
        this.route = ''
    }

    setRoute(route) {
        this.route = route
        return this;
    }

    getRedirect() {
        return this.route
    }
}
