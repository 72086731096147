import Default from "@/modules/post/pages/posts/Default"
import Drafts from "@/modules/post/pages/posts/Drafts"
import SelectChannel from "@/modules/post/pages/select_channel/SelectChannel"
import authMiddleware from "@/plugins/router/middlewares/auth"
import CreateUpdate from "@/modules/post/pages/post_editor/Index"
import Post from "@/modules/post/pages/post_viewer/Index"
import Search from "@/modules/post/pages/search/Search"
import Ranking from "@/modules/post/pages/ranking/Ranking"

export default [{
    path: "/posts",
    name: "SelectChannel",
    component: SelectChannel,
    meta: {
        middleware: authMiddleware,
    },
}, {
    path: "/post/edit/:id(\\d+)",
    name: "Update",
    component: CreateUpdate,
    meta: {
        middleware: authMiddleware,
    },
}, {
    path: "/post/view/:id(\\d+)",
    name: "Post",
    component: Post,
    meta: {
        middleware: authMiddleware,
    },
}, {
    path: "/post/posts/:channel_id(\\d+)",
    name: "Posts",
    component: Default,
    meta: {
        middleware: authMiddleware,
    },
}, {
    path: "/post/drafts/:channel_id(\\d+)",
    name: "PostsDrafts",
    component: Drafts,
    meta: {
        middleware: authMiddleware,
    },
}, {
    path: "/post/create/:channel_id(\\d+)",
    name: "Create",
    component: CreateUpdate,
    meta: {
        middleware: authMiddleware,
    },
}, {
    path: "/post/search",
    name: "Search",
    component: Search,
    meta: {
        middleware: authMiddleware,
    },
}, {
    path: "/post/ranking",
    name: "Ranking",
    component: Ranking,
    meta: {
        middleware: authMiddleware,
    },
}]
