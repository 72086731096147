import PostApiBase from "@/modules/post/services/ApiBase"

export default class Api {
    static seeInBot(query) {
        return PostApiBase.seeInBot(query).
        then((resp) => {
            return resp.data
        })
    }
}
