export default {
    status: [
        {
            id: 0,
            name: 'Черновик'
        },
        {
            id: 10,
            name: 'Опубликовать'
        },
    ],
    types: {
        steps: [
            {
                id: 0,
                name: 'Виден сразу',
                coupon: [0, 20]
            },
            {
                id: 10,
                name: 'По кнопке',
                coupon: [0, 10]
            },
            {
                id: 20,
                name: 'С проверкой',
                coupon: [0, 10]
            }
        ],
        coupons: [
            {
                id: 0,
                name: 'Общий'
            },
            {
                id: 10,
                name: 'Одноразовый'
            },
            {
                id: 20,
                name: 'По ссылке'
            }
        ]
    },
}
