export default {
    getPostId: (item) => {
        return item["post_id"]
    },
    getPostAuthorName: (item) => {
        return item["link_user_name"]
    },
    getCreatedAt: (item) => {
        return item["link_created_at"]
    },
    getLinkPartner: (item) => {
        return item["link_partner"]
    },
    getChannelImageHost: (item) => {
        return item["link_channel_image_host"]
    },
    getChannelImageName: (item) => {
        return item["link_channel_image_name"]
    },
    getChannelName: (item) => {
        return item["link_channel_name"]
    },
    getAuthorAvatarHost: (item) => {
        return item["link_user_avatar_host"]
    },
    getAuthorAvatarName: (item) => {
        return item["link_user_avatar_name"]
    },
    getLinkSourceId: (item) => {
        return item["link_source_id"]
    },
}
