<template>
    <div class="card" id="url_for_check">
        <div class="card-body">
            <div class="divide-y">
                <div class="row">
                    <span class="col-4">
                        <label class="form-label">Ссылка для проверки</label>
                    </span>
                    <span class="col-8">
                        <input type="text" class="form-control" v-model="fields.url_for_check" placeholder="Ссылка для проверки" @change="onChange" />
                        <div v-if="v$.url_for_check.$invalid">
                            <span :class="{'is-invalid': v$.url_for_check.$invalid && v$.url_for_check.$dirty}"></span>
                            <div class="invalid-feedback" v-for="error of v$.url_for_check.$silentErrors" :key="error.$uid">
                                {{ error.$message }}
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {defineComponent, watch, onMounted, reactive} from "vue"
import {useVuelidate} from "@vuelidate/core/dist/index.esm"
import {minLength} from "@/plugins/vuelidator"

export default defineComponent({
    name: "UrlCheck",
    props: {
        data: {
            type: [Object, null],
            default: null,
        },
    },
    setup(props, {emit}) {
        const fields = reactive({
            url_for_check: null,
        })

        onMounted(() => {
            fields.url_for_check = props.data.val;
        })

        watch(() => props.data.val, (val) => {
            fields.url_for_check = val
        })

        const rules = {
            url_for_check: {
                minLength: minLength(0),
            },
        }

        let $externalResults = reactive({})    

        const v$ = useVuelidate(rules, fields, {$externalResults})

        function validate(fn) {
            v$.value.$clearExternalResults()

            return v$.value.$validate().then(async () => {
                await fn()
            })
        }

        watch(() => props.data["validations"], (val) => {
            validate(() => {
                if (val?.url_for_check !== undefined) {
                    $externalResults.url_for_check = val["url_for_check"][0]
                }
            })
        })

        function onChange() {
            validate(() => {})
            emit("onChange", fields.url_for_check)
        }

        return {
            onChange,
            v$,
            fields,
        }
    }
})
</script>

<style scoped>

</style>
