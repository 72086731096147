import PromopushkaApiBase from "@/modules/promopushka/services/ApiBase"
import ConvertQuery from "@/modules/promopushka/components/list/services/ConvertQuery"

export default class Api {
    static items(query) {
        let q = ConvertQuery.handle(query)
        return PromopushkaApiBase.items(q)
    }

    static users() {
        return PromopushkaApiBase.users()
    }
}
