import {axios as http} from "@/plugins/http";

export default class Api {
    static getPoint(ids, typeId) {
        return http.post("/api/v1/statistic/posts-point", {
            post_id: ids,
            type_id: typeId,
        }).then((resp) => {
            return resp.data["items"]
        })
    }
}
