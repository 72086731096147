<template>
  <div>
    <span class="spinner-border spinner-border-sm me-2" v-if="isShowLoading()"></span>
    <svg @click="onRemove(data['post_id'])" xmlns="http://www.w3.org/2000/svg" :class="{'disabled': !isShowDeleteBtn()}" class="post-remove__fast icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <line x1="4" y1="7" x2="20" y2="7"></line>
      <line x1="10" y1="11" x2="10" y2="17"></line>
      <line x1="14" y1="11" x2="14" y2="17"></line>
      <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
      <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
    </svg>
  </div>
</template>

<script>
import {defineComponent, ref} from "vue"
import "./css/style.css"
import Api from "@/modules/post/components/post_list/services/Api"
import Notify from "@/services/Notify"

export default defineComponent({
  name: "PostRemove",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    let deleting = ref(false)

    function startDeleting() {
      deleting.value = true
    }

    function stopDeleting() {
      deleting.value = false
    }

    function isDeleting() {
      return deleting.value === true
    }

    function isShowDeleteBtn() {
      return !isDeleting()
    }

    function isShowLoading() {
      return isDeleting()
    }

    function onRemove(postId) {
      if (confirm("Вы действительно хотите удалить пост?")) {
        startDeleting()

        Api.delete(postId).then(() => {
          emit('onRemove', true)
        }).then(() => {
          Notify.success("Поставлена задача на удаление")
        }).catch(() => {
          emit('onRemove', false)
        }).catch(() => {
          Notify.success("Возникла ошибка при удалении")
        }).finally(() => {
          stopDeleting()
        })
      }
    }

    return {
      onRemove,
      isShowDeleteBtn,
      isShowLoading,
    }
  }
})
</script>

<style scoped>

</style>