<template>
  <div class="container-xl">
    <div class="page-header d-print-none">
      <div class="row g-2 align-items-center">
        <div class="col-sm">
          <h2 class="page-title">Доступ запрещен</h2>
        </div>
      </div>
    </div>
  </div>

  <div class="card-body">
    <div class="container-xl">
      <div class="row align-items-center">
        <div class="col-10">
          <div class="markdown text-muted"></div>
          <div class="mt-3">
            <a href="#" class="btn btn-primary" @click="onBack()">Вернуться назад</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import {useRouter} from "vue-router/dist/vue-router";

export default defineComponent({
  name: "NoPermission",
  setup() {
    const router = useRouter()

    function onBack() {
      router.go(-1)
    }

    return {
      onBack
    }
  }
})
</script>

<style scoped>

</style>