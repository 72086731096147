import {createRouter, createWebHistory} from "vue-router"
import Index from "@/modules/index/Index"
import PathNotFound from "@/modules/PathNotFound"
import Loader from "@/services/Loader"
import Auth from "@/modules/auth/services/Auth"
import Redirect from "@/services/Redirect"
import RoleStore from "@/modules/role/services/Store"

const routes = [
    {
        path: "/",
        name: 'Index',
        component: Index,
        meta: {
            layout: 'defaultLayout',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        component: PathNotFound,
        meta: {
            layout: 'loginLayout',
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
})

router.beforeEach((to, from, next) => {
    try {
        if (from.name === undefined) {
            Loader.start()
        }

        const middleware = to.meta.middleware;

        if (!middleware || middleware({to, from})) {
            return next();
        }

        Auth.recoverySession()
            .then(async () => {
                let resultMiddleware = middleware({to, from})

                await RoleStore.setPermissions()

                if (typeof resultMiddleware === 'object' && resultMiddleware instanceof Redirect) {
                    return next(resultMiddleware.getRedirect())
                } else if (resultMiddleware) {
                    return next()
                } else {
                    return next({name: 'Login'})
                }
            })
            .catch(() => {
                return next({name: 'Login'})
            })
    } catch (error) {
        return next({name: 'Login'})
    }
})

router.afterEach((to, from) => {
    if (from.name === undefined) {
        Loader.complete()
    }
})

export default router
