import PostApiBase from "@/modules/post/services/ApiBase"

export default class Api {
    static actions(postId) {
        return PostApiBase.actions({
            post_id: postId,
        }).then((resp) => {
            return resp.data["items"]
        })
    }
}
