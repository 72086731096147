<template>
  <draggableComponent class="drop-area" v-bind="dragOptions" :class="{ 'collapsed': !collapse.collapse, ' drop-area_empty pb-4': draggableCatalog && folders.length === 0, 'pb-2': draggableCatalog && collapse.collapse }" ghost-class="ghost" tag="ul" handle=".handle" :list="folders" :id="catalogId" :group="{ name: 'catalog' }" item-key="id">
    <template #item="{ element }">
      <li class="accordion-item" :id="element.id">
        <div class=" d-flex align-items-center accordion-header p-1 hover-button">
          <svg v-if="draggableCatalog" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-2  me-2 handle cursor-move" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 6l16 0" />
            <path d="M4 12l16 0" />
            <path d="M4 18l16 0" />
          </svg>

          <a class="btn btn-collapse me-2" @click="item.toggleCollapse(element)" type="button" :class="{ 'hidden': element.folders.length === 0, 'collapsed': item.getCollapse(element).collapse }"></a>

          <div class="me-3">{{ item.getName(element) }} </div>

          <a title="Изменить элемент" class="me-1 btn btn-icon btn-ghost-dark" @click="$emit('edit-item', element)" data-bs-toggle="modal" data-bs-target="#modalStore">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit icon-catalog" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
              <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
              <path d="M16 5l3 3" />
            </svg>
          </a>

          <a title="Создать внутри элемент" class="me-1 btn btn-icon btn-ghost-dark me-5" @click="$emit('create-item', element)" data-bs-toggle="modal" data-bs-target="#modalStore">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-playlist-add icon-catalog" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M19 8h-14" />
              <path d="M5 12h9" />
              <path d="M11 16h-6" />
              <path d="M15 16h6" />
              <path d="M18 13v6" />
            </svg>
          </a>

          <a title="Удалить элемент" class="me-1 btn btn-icon btn-ghost-dark" @click="$emit('delete-item', element)" data-bs-toggle="modal" data-bs-target="#modal-danger">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash icon-catalog" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 7l16 0" />
              <path d="M10 11l0 6" />
              <path d="M14 11l0 6" />
              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
            </svg>
          </a>
        </div>
        <nested-draggable :editCatalog="editCatalog" :draggableCatalog="draggableCatalog" :collapse="item.getCollapse(element)" :folders="element.folders" :catalogId="element.id" :catalogName="element.name" @create-item="(elem) => $emit('create-item', elem)" @edit-item="(ele) => $emit('edit-item', ele)" @delete-item="(element) => $emit('delete-item', element)" @start="(event) => $emit('start', event)" @end="(event) => $emit('end', event)" />
      </li>
    </template>
  </draggableComponent>
</template>
<script>

import {computed} from 'vue'
import draggableComponent from 'vuedraggable'

export default {
  props: {
    catalogId: {
      type: Number
    },
    catalogName: {
      type: String
    },
    folders: {
      required: true,
      type: Array
    },
    collapse: {
      type: Object
    },
    editCatalog: {
      type: Boolean
    },
    draggableCatalog: {
      type: Boolean
    },
  },
  components: {
    draggableComponent
  },

  setup(props) {
    let dragOptions = computed(() => {
      return {
        disabled: !props.draggableCatalog
      }
    })

    const item = {
      getName: (element) => {
        return element["name"]
      },
      getCollapse: (element) => {
        return element["isCollapse"]
      },
      toggleCollapse: (element) => {
        element["isCollapse"].collapse = !element["isCollapse"].collapse
      },
    }

    return {
      dragOptions,
      item,
    }
  },
  name: "nested-draggable"
};
</script>

<style scoped src="./css/style.css"></style>