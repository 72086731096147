import cfg from "@/services/Config"
import Permission from "@/services/Permission"

export default class PermissionBase {
    hasPermission(channelId, action) {
        let permission = this.getMap(channelId)

        if (permission === false) {
            return false;
        }

        return Permission.hasPermission(action + "_" + permission)
    }

    getMap(channelId) {
        let list = cfg._("post", "channel_id_to_permissions")

        return list[channelId] !== undefined ? list[channelId] : false;
    }
}
