<template>
  <template v-if="enable">
    <div class="card">
      <div class="card-body">
        <div class="form-label">Положение картинки</div>
        <div>
          <label class="form-check form-check-inline">
            <input class="form-check-input" type="radio" value="1" @change="onChangeImagePosition" v-model="fields.image_position">
            <span class="form-check-label">Вверху</span>
          </label>
          <label class="form-check form-check-inline">
            <input class="form-check-input" type="radio" value="2" @change="onChangeImagePosition" v-model="fields.image_position">
            <span class="form-check-label">Внизу</span>
          </label>
        </div>

        <span :class="{'is-invalid': v$.image_position.$invalid && v$.image_position.$dirty}"></span>
        <div class="invalid-feedback" v-for="error of v$.image_position.$silentErrors" :key="error.$uid">
          {{ error.$message }}
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import {defineComponent, reactive, watch} from "vue"
import {useVuelidate} from "@vuelidate/core";
import {between} from "@/plugins/vuelidator"

export default defineComponent({
  name: "ImagePosition",
  props: {
    position: {
      type: Number,
      default: null,
    },
    enable: {
      type: Boolean,
      default: false,
    },
    validations: {
      //type: Object,
    },
  },
  setup(props, {emit}) {
    let $externalResults = reactive({})

    const fields = reactive({
      image_position: props.position,
    })

    const rules = {
      image_position: {
        between: between(1, 2)
      },
    }

    const v$ = useVuelidate(rules, fields, {$externalResults})
    
    watch(() => props.position, (val) => {
      fields.image_position = val

      if (val !== null) {
        return false
      }

      emit("onChange", val)
    })

    watch(() => props.validations, (val) => {
      validate(() => {
        if (val?.image_position !== undefined) {
          $externalResults.image_position = val["image_position"][0]
        }
      })
    })

    function validate(fn) {
      v$.value.$clearExternalResults()
      return v$.value.$validate().then(() => {
        fn()
      })
    }

    function onChangeImagePosition(e) {
      emit("onChange", e.target.value)
    }

    return {
      onChangeImagePosition,
      v$,
      fields,
    }
  }
})
</script>

<style scoped>

</style>
