<template>
    <div v-if="getUser().id">
        <div class="row">
            <div class="col-lg-6">
                <div class="mb-3">
                    <label class="form-label">Имя</label>
                    <input type="text" class="form-control" placeholder="Имя пользователя" v-model="formProfile.user_name" />
                    <div v-if="v$.user_name.$invalid">
                        <span :class="{'is-invalid': v$.user_name.$invalid && v$.user_name.$dirty}"></span>
                        <div class="invalid-feedback" v-for="error of v$.user_name.$silentErrors" :key="error.$uid">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label class="form-label">E-mail</label>
                    <input type="email" class="form-control" placeholder="E-mail" v-model="formProfile.user_email" />
                    <div v-if="v$.user_email.$invalid">
                        <span :class="{'is-invalid': v$.user_email.$invalid && v$.user_name.$dirty}"></span>
                        <div class="invalid-feedback" v-for="error of v$.user_email.$silentErrors" :key="error.$uid">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="mb-3">
                    <label class="form-label">Логин</label>
                    <input type="text" class="form-control" placeholder="Логин" v-model="formProfile.user_login" />
                    <div v-if="v$.user_login.$invalid">
                        <span :class="{'is-invalid': v$.user_login.$invalid && v$.user_name.$dirty}"></span>
                        <div class="invalid-feedback" v-for="error of v$.user_login.$silentErrors" :key="error.$uid">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label class="form-label">Telegram ID</label>
                    <input type="text" class="form-control" placeholder="Telegram ID" v-model="formProfile.telegram_id" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="form-check form-switch mb-3">
                    <input class="form-check-input" type="checkbox" role="switch" id="phantom" v-model="formProfile.user_fantom" />
                    <label class="form-check-label" for="phantom">Фантом</label>
                </div>
            </div>
            <div class="col-auto ms-auto">
                <div class="mb-3">
                    <button class="btn btn-primary ms-auto" @click="handleForm">Сохранить</button>
                </div>
            </div>
        </div>

        <Avatar :user="getUser()" />
    </div>
</template>


<script>
import {defineComponent, onMounted, reactive, ref} from "vue"
import Api from "@/modules/user/components/edit/services/Api"
import Avatar from "@/modules/user/components/edit/components/Avatar"
import Notify from "@/services/Notify"
import { required } from "@/plugins/vuelidator"
import { useVuelidate } from "@vuelidate/core/dist/index.esm"
  
export default defineComponent({
    name: "Profile",
    components: {
        Avatar
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    setup(props) {//, {emit}
        let user = ref({})

        function getUser() {
            return user.value
        }

        const formProfile = reactive({
            id: null,
            telegram_id: null,
            user_email: null,
            user_login: null,
            user_name: null,
            user_fantom: false
        })

        const rules = {
            id: { required },
            user_email: { required },
            user_login: { required },
            user_name: { required },
        }

        onMounted(() => {
            user.value = props.user
            formProfile.id = props.user.id
            formProfile.telegram_id = props.user.telegram_id
            formProfile.user_email = props.user.email
            formProfile.user_login = props.user.login
            formProfile.user_name = props.user.name
            formProfile.user_fantom = props.user.fantom
        })

        const v$ = useVuelidate(rules, formProfile)

        async function validate(fn) {
            if (!await v$.value.$validate()) return

            await fn()
        }

        function handleForm() {
            validate(sendForm)
        }

        function sendForm() {
            Api.userEdit(props.user.id, formProfile).then(() => {
                Notify.success("Профиль обновлен");
            }).catch(() => {
                Notify.error('Не удалось изменить профиль')
            })
        }

        return {
            getUser,
            handleForm,
            formProfile,
            v$
        }
    }
})
</script>
  
<style scoped>
#user-edit .hr-text::after, #user-edit .hr-text::before {
    height: 1px;
}
</style>
  