<template>
  <metainfo></metainfo>

  <div id="post-card">
    <template v-if="conditions.isReadyPage()">
      <div class="container-xl">
        <div class="page-header d-print-none">
          <div class="row g-2 align-items-center">
            <div class="col-sm">
              <h2 class="page-title">Просмотр</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="page-body">
        <div class="container-xl">
          CNT
        </div>
      </div>

    </template>
    <template v-else>
      <div class="page-body">
        <div class="container-xl">
          <div class="row">
            <div class="mb-3">
              <div class="loader-posts">
                <div class="spinner-border"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue"
import {useMeta} from "vue-meta"

export default defineComponent({
  name: "Index",
  setup() {
    let readyPage = ref(false)

    useMeta({title: "Просмотр промо"})

    onMounted(() => {
      readyPageOff()

      setTimeout(() => {
        readyPageOn()
      }, 1000)
    })

    let conditions = {
      isReadyPage: () => {
        return readyPage.value === true
      },
    }

    function readyPageOn() {
      readyPage.value = true
    }

    function readyPageOff() {
      readyPage.value = false
    }

    return {
      conditions,
    }
  }
})
</script>

<style scoped>

</style>