import values from "./values"

export default {
    getTypeOfCoupon: (item) => {
        let typeOfCoupon = values.types.coupons.find((type) => type.id === item.type_of_coupon)
        return typeOfCoupon?.name
    },
    getTypeOfSteps: (item) => {
        let typeOfSteps = values.types.steps.find((type) => type.id === item.type_of_steps)
        return typeOfSteps?.name
    },
}
