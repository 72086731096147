import Store from "@/services/Store"
import store from "@/plugins/store"
import Helper from "@/services/Helper"

export default class StoreCommon extends Store {
    static update(key, val) {
        let oldData = this.get(key)

        let newData = {
            [key]: Helper.mergeObject(oldData, val),
        }

        super.set("common", newData)

        return this.get(key)
    }

    static set(key, val) {
        super.set("common", {
            [key]: val
        })

        return this.get(key)
    }

    static get(key = null) {
        let val = store.getters.get("common")

        if (key !== null) {
            return val[key] ?? {}
        } else {
            return val
        }
    }
}
