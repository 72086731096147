<template>
  <button class="btn btn-red w-100 mb-3" @click="onCancel">
    <span class="spinner-border spinner-border-sm me-2" v-show="isShowLoadingBtn()"></span>
    Отменить
  </button>
</template>

<script>
import {defineComponent, ref} from "vue"
import Api from "./services/Api"
import Notify from "@/services/Notify"

export default defineComponent({
  name: "PostCancel",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    let canceling = ref(false)

    let postId = ref(props.data["post_id"])

    function onCancel() {
      if (confirm("Вы действительно хотите отменить пост?")) {
        startCanceling()

        Api.cancel(postId.value).then(() => {
          emit('onCancel', true)
        }).then(() => {
          Notify.success("Пост отменен")
        }).catch(() => {
          emit('onCancel', false)
        }).finally(() => {
          stopCanceling()
        })
      }
    }

    function isDisabledBtn() {
      return canceling.value === true
    }

    function isShowLoadingBtn() {
      return isDisabledBtn()
    }

    function startCanceling() {
      canceling.value = true
    }

    function stopCanceling() {
      canceling.value = false
    }

    return {
      onCancel,
      isDisabledBtn,
      isShowLoadingBtn,
    }
  },
})
</script>

<style scoped>

</style>