import PostApiBase from "@/modules/post/services/ApiBase"

export default class Api {
    static delete(postId) {
        return PostApiBase.delete({
            post_id: postId,
        }).then((resp) => {
            return resp.data
        })
    }

    static deleteChain(postId) {
        return PostApiBase.deleteChain({
            post_id: postId,
        }).then((resp) => {
            return resp.data
        })
    }
}
