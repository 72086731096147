import {axios as http} from "@/plugins/http"

export default class ApiBase {
    static categories() {
        return http.get('/api/v1/catalog/list')
    }

    static update(id, name) {
        return http.post('/api/v1/catalog/update', {
            "id": id,
            "name": name,
        })
    }

    static create(id, parentId, name) {
        return http.post('/api/v1/catalog/create', {
            "id": id,
            "parent_id": parentId,
            "name": name,
        })
    }

    static delete(ids) {
        return http.delete('/api/v1/catalog/delete', {
            data: {
                ids,
            },
        });
    }

    static move(items) {
        return http.post('/api/v1/catalog/replace', {
            items,
        });
    }
}
