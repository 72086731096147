import Api from "@/modules/shops/pages/categories/services/Api"

export default {
    data: {
        title: "Создание категории",
        btn: "Создать",
        page: "create",
    },
    action: ({modal, payload}) => {
        return new Promise((resolve, reject) => {
            return Api.create(payload).then(() => {
                modal.hide();
                resolve("Создан")
            }).catch(() => {
                reject("Ошибка при создании")
            }).finally(() => {})
        })
    }
}