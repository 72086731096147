import PostApiBase from "@/modules/post/services/ApiBase";

export default class Api {
    static addVideo(q) {
        return PostApiBase.addVideo(q).then((resp) => {
            return resp.data["item"]
        })
    }

    static deleteVideo(q) {
        return PostApiBase.deleteVideo(q).then((resp) => {
            return resp.data
        })
    }
}