import RouteRegister from "@/plugins/router/services/RouteRegister"
import routers from '@/modules/post/routers'
import plugin from "./plugins/index"
import StoreRegister from "@/plugins/store/services/StoreRegister";
import stores from "./stores";
import "@/modules/post/styles.css"

export default {
    install: (app) => {
        StoreRegister.handle("post", stores)
        RouteRegister.handle(routers)

        app.use(plugin);
    }
}
