import PostApiBase from "@/modules/post/services/ApiBase";

export default class Api {
    static addImage(q) {
        return PostApiBase.addImage(q).then((resp) => {
            return resp.data["item"]
        })
    }
    static deleteImage(q) {
        return PostApiBase.deleteImage(q).then((resp) => {
            return resp.data
        })
    }
}