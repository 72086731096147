import UserApiBase from "@/modules/user/services/ApiBase"

export default class Api {
    static async user(id) {
        const resp = await UserApiBase.user(id)
        return resp.data
    }

    static async userEdit(id, query) {
        const resp = await UserApiBase.userEdit(id, query)
        return resp.data
    }

    static async userPassword(id, query) {
        const resp = await UserApiBase.userPassword(id, query)
        return resp.data
    }

    static async userActivate(id, query) {
        const resp = await UserApiBase.userActivate(id, query)
        return resp.data
    }

    static async userDelete(id) {
        const resp = await UserApiBase.userDelete(id)
        return resp.data
    }

    static async userAvatar(query) {
        const resp = await UserApiBase.userAvatar(query)
        return resp.data
    }
    
    static async userAvatarDelete(id) {
        const resp = await UserApiBase.userAvatarDelete(id)
        return resp.data
    }

    static async permissions(query) {
        const resp = await UserApiBase.permissions(query)
        return resp.data
    }

    static async permissionsUser(id) {
        const resp = await UserApiBase.permissionsUser(id)
        return resp.data
    }

    static async permissionsUserSet(query) {
        const resp = await UserApiBase.permissionsUserSet(query)
        return resp.data
    }

    static async permissionsUserDelete(userId, permissionId, sourceId) {
        const resp = await UserApiBase.permissionsUserDelete(userId, permissionId, sourceId)
        return resp.data
    }
}
