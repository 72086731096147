import PostApiBase from "@/modules/post/services/ApiBase"

export default class Api {
    static republished(query) {
        return PostApiBase.republished(query).
        then((resp) => {
            return resp.data
        })
    }
}
