<template>
    <div>
        <div class="row">
            <div class="col-lg-6">
                <div class="mb-3">
                    <label class="form-label">Пароль</label>
                    <input type="password" class="form-control" placeholder="Пароль" v-model="formPassword.user_password" />
                    <div v-if="v$.user_password.$invalid">
                        <span :class="{'is-invalid': v$.user_password.$invalid && v$.user_password.$dirty}"></span>
                        <div class="invalid-feedback" v-for="error of v$.user_password.$silentErrors" :key="error.$uid">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-3">
                    <label class="form-label">Повторите пароль</label>
                    <input type="password" class="form-control" placeholder="Повторите пароль" v-model="formPassword.user_password_confirmation" />
                    <div v-if="v$.user_password_confirmation.$invalid">
                        <span :class="{'is-invalid': v$.user_password_confirmation.$invalid && v$.user_password_confirmation.$dirty}"></span>
                        <div class="invalid-feedback" v-for="error of v$.user_password_confirmation.$silentErrors" :key="error.$uid">
                            {{ error.$message }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex mb-3">
            <button class="btn btn-primary ms-auto" @click="handlePassword">Сохранить</button>
        </div>
        <div class="hr-text">Другое</div>
        <div class="row">
            <template v-if="!getUserActive()">
                <div class="col-md-6 d-grid" v-if="!getUserActive()" @click="handleActivate">
                    <button class="btn btn-warning">Активировать пользователя</button>
                </div>
                <div class="col-md-6 d-grid">
                    <button class="btn btn-danger" @click="handleDelete">Удалить пользователя</button>
                </div>
            </template>
            <template v-else-if="!getUserDeleted()">
                <div class="col-md-12 d-grid">
                    <button class="btn btn-danger" @click="handleDelete">Удалить пользователя</button>
                </div>
            </template>
        </div>
    </div>
</template>


<script>
import {defineComponent, onMounted, reactive, ref} from "vue"
import Api from "@/modules/user/components/edit/services/Api"
import Notify from "@/services/Notify"
import { required } from "@/plugins/vuelidator"
import { useVuelidate } from "@vuelidate/core/dist/index.esm"
import { helpers } from "@vuelidate/validators"
  
export default defineComponent({
    name: "Security",
    components: {
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const formPassword = reactive({
            user_id: props.user.id,
            user_password: null,
            user_password_confirmation: null
        })

        const rules = {
            user_password: { required },
            user_password_confirmation: { 
                required, 
                sameAsUserPassword: helpers.withMessage('Пароли не совпадают!', (value) => {
                    return value == formPassword.user_password
                }),
            }
        }

        const v$ = useVuelidate(rules, formPassword)

        async function validate(fn) {
            if (!await v$.value.$validate()) return

            await fn()
        }

        function handlePassword() {
            validate(changePassword)
        }

        function changePassword() {
            Api.userPassword(props.user.id, formPassword).then(() => {
                Notify.success("Новый пароль сохранен");
            }).catch(() => {
                Notify.error('Не удалось изменить пароль')
            })
        }

        const userActive = ref(true)
        const getUserActive = () => userActive.value
        const setUserActive = (value) => userActive.value = value
        onMounted(() => {
            setUserActive(props.user.active)
        })
        
        function handleActivate() {
            if (getUserActive()) return;
            Api.userActivate(props.user.id, {
                id: props.user.id,
                user_active: true
            }).then(() => {
                setUserActive(true)
                Notify.success("Пользователь активирован");
            }).catch(() => {
                Notify.error('Не удалось активировать пользователя')
            })
        }

        const userDeleted = ref(true)
        const getUserDeleted = () => userDeleted.value
        const setUserDeleted = (value) => userDeleted.value = value
        onMounted(() => {
            setUserDeleted(props.user.delst)
        })
        
        function handleDelete() {
            if (getUserDeleted()) return;
            if (!confirm('Вы уверены что хотите удалить пользователя "' + props.user.name + '" ?')) return false;
            Api.userDelete(props.user.id).then(() => {
                setUserDeleted(true)
                Notify.success("Пользователь удален");
            }).catch(() => {
                Notify.error('Не удалось удалить пользователя')
            })
        }

        return {
            formPassword,
            v$,
            handlePassword,
            handleActivate,
            getUserActive,
            handleDelete,
            getUserDeleted,
        }
    }
})
</script>
  
<style scoped>
#user-edit .hr-text::after, #user-edit .hr-text::before {
    height: 1px;
}
</style>
  