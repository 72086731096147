export default {
    getId: (item) => {
        return item["id"]
    },
    getTitle: (item) => {
        return item["title"]
    },
    getDesc: (item) => {
        return item["desc"]
    },
    getCategoryId: (item) => {
        return item["category"]["category_id"]
    },
    getCategoryName: (item) => {
        return item["category"]["category_name"]
    },
    getImage: (item) => {
        return item["image"]
    },
    getActive: (item) => {
        return item["active"]
    },
}
