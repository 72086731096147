<template>
  <span id="post-list-title">
    <span v-html="getTitle()" class="title"></span><span v-html="getCountPostsTitle()" class="count"></span>
  </span>
</template>

<script>
import "./css/style.css"
import {defineComponent, onMounted, ref, watch} from "vue"

export default defineComponent({
  name: "Title",
  props: {
    countPosts: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    let countPosts = ref(0)

    watch(() => props.countPosts, (val) => {
      setCountPosts(val)
    })

    onMounted(() => {
      setCountPosts(props.countPosts)
    })

    function setCountPosts(val) {
      countPosts.value = val
    }

    function getTitle() {
      let result = ""

      result += "Глобальный поиск"

      return result
    }

    function getCountPostsTitle() {
      let result = ""

      if (countPosts.value > 0) {
        result += ": <span>" + countPosts.value + "</span>"
      }

      return result
    }

    return {
      getTitle,
      getCountPostsTitle,
    }
  },
})
</script>

<style scoped>

</style>
