<template>
  <h2 class="page-title">
    <span v-html="getTitle()" class="title"></span><span v-html="getCount()" class="count"></span>
  </h2>
</template>

<script>
import {defineComponent, onMounted, ref, watch} from "vue"

export default defineComponent({
  name: "Title",
  props: {
    title: {
      type: String,
      required: false
    },
    count: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    let count = ref(0)
    let title = ref('')

    watch(() => props.title, (val) => {
      title.value = val ?? ''
    })

    watch(() => props.count, (val) => {
      setCount(val)
    })

    onMounted(() => {
      setCount(props.count)
      title.value = props.title ?? ''
    })

    function setCount(val) {
      count.value = val
    }

    function getTitle() {
      return title.value
    }

    function getCount() {
      let result = ""

      if (count.value > 0) {
        result += ": <span>" + count.value + "</span>"
      }

      return result
    }

    return {
      getTitle,
      getCount,
    }
  },
})
</script>

<style scoped>

</style>
