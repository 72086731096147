export default {
    getCompositeRootPostId: (item) => {
        return item["composite_root_post_id"]
    },
    isCompositeActive: (item) => {
        return item["composite_active"] === 1
    },
    getCompositeSlice: (item) => {
        return item["composite_slice"]
    },
    getCompositeCreatedAt: (item) => {
        return item["composite_created_at"]
    },
    getPostUsername: (item) => {
        return item["post_username"]
    },
    getPostUserAvatarHost: (item) => {
        return item["post_user_avatar_host"]
    },
    getPostUserAvatarName: (item) => {
        return item["post_user_avatar_name"]
    },
    getChannelImageHost: (item) => {
        return item["post_channel_image_host"]
    },
    getChannelImageName: (item) => {
        return item["post_channel_image_name"]
    },
    getPostChannelName: (item) => {
        return item["post_channel_name"]
    },
    getShow: (item) => {
        return item["show"]
    },
    setShow: (item, val) => {
        item["show"] = val
    },
}
