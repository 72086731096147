import Index from "@/modules/statistic/pages/index/Index"
import authMiddleware from "@/plugins/router/middlewares/auth"

export default [{
    path: "/statistic",
    name: "IndexStatistic",
    component: Index,
    meta: {
        middleware: authMiddleware,
    },
},]
