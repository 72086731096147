import $ from "../../../../packages/editor/src/components/dom";
import './style.css';
export default class DeleteTune {
    /**
     * DeleteTune constructor
     *
     * @param {API} api - Editor's API
     */
    constructor({ api }) {
        /**
         * Styles
         */
        this.CSS = {
            button: 'ce-settings__item',
            buttonDelete: 'ce-settings__item--delete',
            buttonConfirm: 'ce-settings__item--confirm',
        };
        /**
         * Tune nodes
         */
        this.nodes = {
            button: null,
        };
        this.api = api;
        this.resetConfirmation = () => {
            this.setConfirmation(false);
        };
    }
    /**
     * Create "Delete" button and add click event listener
     *
     * @returns {HTMLElement}
     */
    render() {
        this.createBtn();
        this.api.listeners.on(this.nodes.button, 'click', (event) => this.handleClick(event), false);
        /**
         * Enable tooltip module
         */
        this.api.tooltip.onHover(this.nodes.button, this.api.i18n.t('Delete a block'), {
            hidingDelay: 300,
        });
        return this.nodes.button;
    }
    createBtn() {
        this.nodes.button = $.make('div', [this.CSS.button, this.CSS.buttonDelete], {});
        let text = $.make('span', ['remove-tool__text'], {
            innerHTML: this.api.i18n.t('Delete a block')
        });
        let icon = $.make('span', null, {
            innerHTML: '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
                '  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.002 5.5A2.498 2.498 0 0110.5 3.002h3A2.498 2.498 0 0115.998 5.5v1.003H20a.998.998 0 010 1.995h-.526l-1.15 9.008a3.998 3.998 0 01-3.965 3.491H9.64a3.997 3.997 0 01-3.965-3.491l-1.15-9.008H4a.997.997 0 110-1.995h4.002V5.5zm1.995 1.003h4.005V5.5a.503.503 0 00-.502-.503h-3a.503.503 0 00-.502.503v1.003zm-3.46 1.995l1.118 8.755c.128 1 .978 1.75 1.986 1.75h4.718c1.008 0 1.858-.75 1.986-1.75l1.118-8.755H6.537zM10 10.253c.55 0 .998.446.998.997v4.5a.998.998 0 01-1.995 0v-4.5c0-.55.446-.998.997-.998zm4 0c.55 0 .998.446.998.997v4.5a.998.998 0 01-1.995 0v-4.5c0-.55.446-.998.997-.998z" fill="#000"></path>' +
                '</svg>'
        });
        this.nodes.button.appendChild(icon);
        this.nodes.button.appendChild(text);
    }
    /**
     * Delete block conditions passed
     *
     * @param {MouseEvent} event - click event
     */
    handleClick(event) {
        /**
         * if block is not waiting the confirmation, subscribe on block-settings-closing event to reset
         * otherwise delete block
         */
        if (!this.needConfirmation) {
            this.setConfirmation(true);
            /**
             * Subscribe on event.
             * When toolbar block settings is closed but block deletion is not confirmed,
             * then reset confirmation state
             */
            this.api.events.on('block-settings-closed', this.resetConfirmation);
        }
        else {
            /**
             * Unsubscribe from block-settings closing event
             */
            this.api.events.off('block-settings-closed', this.resetConfirmation);
            this.api.blocks.delete();
            this.api.toolbar.close();
            this.api.tooltip.hide();
            /**
             * Prevent firing ui~documentClicked that can drop currentBlock pointer
             */
            event.stopPropagation();
        }
    }
    /**
     * change tune state
     *
     * @param {boolean} state - delete confirmation state
     */
    setConfirmation(state) {
        this.needConfirmation = state;
        this.nodes.button.classList.add(this.CSS.buttonConfirm);
        this.setConfirmChange();
    }
    setConfirmChange() {
        let text = this.nodes.button.querySelector('.remove-tool__text');
        text.innerHTML = this.api.i18n.t('Are you sure delete?');
    }
}
/**
 * Set Tool is Tune
 */
DeleteTune.isTune = true;
