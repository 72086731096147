<template>
  <metainfo></metainfo>

  <div class="container-xl">
    <div class="page-header d-print-none">
      <div class="row g-2 align-items-center">
        <div class="col-sm">
          <h2 class="page-title">
            Статистика
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="page-body">
    <div class="container-xl">
      <div class="mb-3">
        <StatisticFilter @onFilter="events.onFilter" :data="{
          'enable': true,
          'params': {
            'date': null,
            'users': [],
          },
        }" />
      </div>
      <div ref="scrollComponent">

      </div>
    </div>
  </div>
</template>

<script>
import StatisticFilter from "@/modules/statistic/components/filter/Filter"
export default {
  name: "Statistic",
  components: {
    StatisticFilter,
  },
  setup() {
    let events = {
      onFilter: (q) => {
        console.log(q)
      }
    }

    return {
      events,
    }
  },
}
</script>

<style scoped>

</style>