<template>
  <div class="card" id="post-ref-posts">
    <div class="card-header">
      <h3 class="card-title">Связанные посты</h3>

      <div class="card-actions" v-if="isViewBtnBind()">
        <a href="#" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#post-bind">
          <IconPlus />
          Связать
        </a>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table card-table">
        <thead>
          <tr>
            <th width="55%">Текст</th>
            <th width="15%">Канал</th>
            <th width="15%">Дата публикации</th>
            <th width="15%">Автор</th>
            <th class="w-1"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item of getItems()" :key="item.id" :class="getClassBg(item)">
            <td>
              <div class='' :class="{ 'row flex-xl-row': row.isShow(item), 'flex-xl-row-reverse flex-column-reverse': row.isShow(item) }">
                <div class="image" :class="{ 'open col-xl-6': row.isShow(item), 'mb-3 mb-xl-0': true }">
                  <Lightbox :images="{ thumb: getImage(item, 560), origin: getImage(item) }" />
                </div>
                <div class="content" :class="{ 'open col-xl-6': row.isShow(item), 'mb-3 mb-xl-0': row.isShow(item) }">
                  <div v-if="row.isNotShow(item)" class="hide-element" @click="row.toggleShowPost(item)"></div>
                  {{ row.isNotShow(item) ? '...' : '' }}
                  <div class="mb-3" :class="{ 'inline-content': row.isNotShow(item) }" v-html="item.show ? row.getContent(item) : row.getContentShort(item)"></div>
                  <span class="button-link" v-if="row.isShow(item)" @click="row.toggleShowPost(item)">
                    Скрыть
                  </span>
                </div>
              </div>
            </td>
            <td class="channel">
              <img width="20" :src="row.getChannelIcon(item)" alt="">
              <template v-if="row.isObjectTypePost(item)">
                <router-link :to="{ name: 'Update', params: { id: row.getId(item) } }">{{ row.getChannelName(item) }}</router-link>
              </template>
              <template v-if="row.isObjectTypePromopushka(item)">
                <router-link :to="{ name: 'CouponUpdate', params: { id: row.getId(item) } }">{{ row.getChannelName(item) }}</router-link>
              </template>
            </td>
            <td class="datetime" v-html="row.getPublishedAt(item)"></td>
            <td class="user">
              <img width="20" :src="row.getUserAvatar(item, 40, 40)">{{ row.getPostAuthorName(item) }}
            </td>
            <td>
              <div class="card-actions">
                <div class="dropdown">
                  <a href="#" class="btn-action dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <IconDotsVertical />
                  </a>
                  <div class="dropdown-menu dropdown-menu-end">
                    <UnbindPost :post_id="row.getId(item)" :type_id="row.getObjectTypeId(item)" @unbindSuccess="unbindSuccess" />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-center p-1" v-if="isLoad()">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <div class="modal modal-blur fade" id="post-bind" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Связывание постов</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <BindPost :bind="bindPost" :post_id="getPostId()" :type_id="getTypeId()" @bindFinally="bindFinally" @bindSuccess="bindSuccess" />
        </div>
        <div class="modal-footer">
          <a href="#" class="btn btn-link link-secondary" data-bs-dismiss="modal">
            Отменить
          </a>
          <button class="btn btn-primary ms-auto" :class="{ 'disabled': isPending() }" @click="onBindPost">
            <span class="spinner-border spinner-border-sm me-2" v-show="isPending()"></span>
            <IconPlus />
            Связать
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BindPost from "./BindPost"
import UnbindPost from "./UnbindPost"
import {defineComponent, onMounted, ref} from "vue"
import Api from "@/components/object_chains/services/Api"
import element from "@/components/object_chains/services/element"
import Image from "@/services/Image"
import Helper from "@/services/Helper"
import DTBackend from "@/services/DTBackend"
//import PostPermission from "@/modules/post/services/permissions/Permission"
import Lightbox from "@/components/lightbox/Lightbox.vue"
import IconPlus from "@/assets/icons/IconPlus"
import IconDotsVertical from "@/assets/icons/IconDotsVertical"
import ObjectImage from "@/components/object_chains/services/ObjectImage"
import cfg from "@/services/Config";

export default defineComponent({
  name: "PostRefPosts",
  components: {
    BindPost,
    UnbindPost,
    Lightbox,
    IconPlus,
    IconDotsVertical,
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    typeId: {
      type: Number,
      required: true
    },
  },
  setup(props) {
    let load = ref(null)
    let items = ref([])
    let bindPost = ref(false)
    let pending = ref(false)
    let row = {
      getPublishedAt: (item) => {
        let result, dt, publishedAt = element.getPostPublishedAt(item)

        if (publishedAt === null) {
          result = "Не опубликован";
        } else {
          dt = new DTBackend(publishedAt)
          result = dt.get("%D.%M.%y <span>%H:%I</span>")
        }
        return result
      },
      getContent: (item) => {
        let status = element.getPostStatus(item)
        let content = element.getPostContent(item)
        if (status === "draft") {
          content = "Текст отсутствует";
        }
        return content
      },

      getContentShort: (item) => {

        let contentShort = row.getContent(item)
        if (contentShort) {
          let regExpBr = /<br>{1,3}/gmi
          contentShort = contentShort.replace(regExpBr, ' ')
          let regExpP = /<p>|<\/p>/g
          contentShort = contentShort.replace(regExpP, ' ')
          contentShort = '<p>' + contentShort + '</p>'
        }
        return contentShort
      },

      getChannelName: (item) => {
        let title = element.getPostChannelName(item)

        return title
      },

      getChannelIcon: (item) => {
        let host = element.getPostChannelImageHost(item)
        let name = element.getPostChannelImageName(item)

        if (host === null || name === null) {
          throw Error("Отсутствует иконка")
        }

        return Image.handle(host, name, 20, 20)
      },

      getUserAvatar: (item, w = null, h = null) => {
        let host = element.getPostUserAvatarHost(item)
        let name = element.getPostUserAvatarName(item)

        return Image.handle(host, name, w, h)
      },

      getPostAuthorName: (item) => {
        return element.getPostAuthorName(item)
      },

      getId: (item) => {
        return element.getPostId(item)
      },

      getStatus: (item) => {
        return element.getPostStatus(item)
      },

      isShow: (item) => {
        return element.getShow(item)
      },

      isNotShow: (item) => {
        return !element.getShow(item)
      },

      hasImage: (item) => {
        return row.isNotShow(item) && item["post_image_name"];
      },

      toggleShowPost: (item) => {
        element.setShow(item, !element.getShow(item))
      },

      getObjectTypeId: (item) => {
        return element.getObjectTypeId(item)
      },

      isObjectTypePost: (item) => {
        let typeId = cfg._("object_chains", "types", "post")

        return element.getObjectTypeId(item) === typeId
      },

      isObjectTypePromopushka: (item) => {
        let typeId = cfg._("object_chains", "types", "promopushka")

        return element.getObjectTypeId(item) === typeId
      },
    }

    onMounted(() => {
      init()
    })

    function init() {
      startLoad()
      Api.postChains(getPostId()).then((resp) => {

        let items = Helper.objectFilter(resp, (val) => {
          return element.getPostId(val) !== getPostId()
        })

        for (let key in items) {
          items[key].show = false
        }

        setItems(items)
      }).finally(() => {
        stopLoad()
      })
    }

    function isLoad() {
      return load.value === true
    }

    function startLoad() {
      load.value = true
    }

    function stopLoad() {
      load.value = false
    }

    function setItems(val) {
      items.value = val
    }

    function getItems() {
      return items.value
    }

    function onBindPost() {
      if (isPending()) {
        return false
      }

      startPending()
      bindPost.value = !bindPost.value
    }

    function getPostId() {
      return props.data["id"]
    }

    function getTypeId() {
      return props.typeId
    }

    function getChannelId() {
      return props.data["channel_id"]
    }

    getChannelId()

    function bindSuccess() {
      init()
    }

    function bindFinally() {
      stopPending()
    }

    function startPending() {
      pending.value = true
    }

    function stopPending() {
      pending.value = false
    }

    function isPending() {
      return pending.value
    }

    function unbindSuccess() {
      init()
    }

    function isViewBtnBind() {
      let allowStatus = (
        props.data["status"] === "published"
        || props.data["status"] === "scheduled"
        || props.data["status"] === "draft"
      )

      // todo вернуть, как разберемся с разрешениями
      //let allowPermission = PostPermission.isUpdate(getChannelId())

      let allowPermission = true

      return allowStatus && allowPermission
    }

    function getClassBg(item) {
      return Helper.getClassBgItems(item["post_status"])
    }

    function getImage(item, w = null, h = null) {
      return [
        ObjectImage.handle(item["post_image_host"], item["post_image_name"], w, h)
      ]
    }

    return {
      isLoad,
      getItems,
      onBindPost,
      bindPost,
      getPostId,
      getTypeId,
      bindFinally,
      bindSuccess,
      isPending,
      unbindSuccess,
      row,
      isViewBtnBind,
      getClassBg,
      getImage,
    }
  },
})
</script>

<style scoped src="./css/style.css"></style>
