<template>
  <div class="row row-form" id="statistic-filter" v-if="isEnable()">
    <div class="col-xl-3">
      <label class="form-label">Дата</label>
      <Datepicker auto-apply v-model="filter.date" @update:modelValue="events.onDate" range multi-calendars locale="ru" format="dd.MM.yyyy" placeholder="Выбрать дату" :enable-time-picker="false"/>
    </div>
    <div class="col-xl-2">
      <label class="form-label">Пользователь</label>
      <Multiselect
          @change="events.onUser"
          v-model="filter.users"
          mode="tags"
          placeholder="Не выбрано"
          noResultsText="Результатов не найдено"
          :close-on-select="true"
          :options="data.users"
      >
        <template v-slot:tag="{option, handleTagRemove, disabled}">
          <div class="multiselect-tag element" :class="{'is-disabled': disabled}">
            <img :src="option.image">{{ option.name }}
            <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
              <span class="multiselect-tag-remove-icon"></span>
            </span>
          </div>
        </template>

        <template v-slot:option="{ option }">
          <img class="user-image" :src="option.image"> {{ option.name }}
        </template>
      </Multiselect>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "vue"
import DT from "@/services/DT"
import Datepicker from "@/plugins/datepicker"
import Multiselect from "@/plugins/multiselect"

export default defineComponent({
  name: "Filter",
  components: {
    Datepicker,
    Multiselect,
  },
  props: {
    props: {
      type: Object,
      required: true
    },
  },
  setup(props, {emit}) {
    let filter = reactive({
      date: props.props["params"]["date"] ?? null,
      users: props.props["params"]["users"] ?? [],
    })

    let events = {
      onDate: () => {
        onFilter()
      },
      onUser: (value) => {
        // тут приходится подменять вручную, а не использовать v-model
        // так как v-model реагирует перед событием и не учитывает текущий выбор
        // и наполняется предыдущим набором данных
        filter.users = value
        onFilter()
      },
    }

    const data = reactive({
      users: [],
    })

    function onFilter() {
      pushParent()
    }

    function pushParent() {
      emit("onFilter", getResult())
    }

    function forDateResult(date) {
      if (date === null) {
        return [
          null,
          null,
        ]
      }

      let from = new DT(date[0]).startTimeOfDay()
      let to = new DT(date[1]).endTimeOfDay()

      from.setMonth(from.getMonthNatural())
      to.setMonth(to.getMonthNatural())

      return [
        from.get("%Y-%M-%D %H:%I:%S"),
        to.get("%Y-%M-%D %H:%I:%S"),
      ]
    }

    function getResult() {
      let date = forDateResult(filter.date)

      return {
        date: {
          from: date[0],
          to: date[1],
        },
        users: filter.users,
      }
    }

    function isEnable() {
      return props.props["enable"] === true
    }

    return {
      filter,
      events,
      data,
      isEnable,
    }
  },
})
</script>

<style scoped>

</style>