import { useToast } from "vue-toastification"

export default class Notify {
    static success(msg) {
        const toast = useToast()

        toast.success(msg, {
            timeout: 2000
        });
    }

    static error(msg) {
        const toast = useToast()

        toast.error(msg, {
            timeout: 2000
        });
    }
}
