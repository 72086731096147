import Helper from "@/services/Helper"

export default class Drafts {
    filterParams() {
        return {
            params: {
                search: "",
                date: null,
                users: [],
                statuses: {
                    published: false,
                    scheduled: false,
                    removed: false,
                    draft: true,
                },
            },
            saveForm: false,
            enable: false,
        }
    }

    prepareQuery(q) {
        let query = Helper.clone(q)

        return query
    }
}
