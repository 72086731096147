<template>
  <metainfo></metainfo>

  <div class="page-body">
    <div class="container-xl">
      <div class="spinner-border" role="status"></div>
    </div>
  </div>
</template>

<script>
import {useRouter} from "vue-router/dist/vue-router"
import {defineComponent} from "vue"
import AuthStore from '@/modules/auth/services/Store'
import {useMeta} from "vue-meta";

export default defineComponent({
  name: "Index.vue",
  setup() {
    useMeta({title: "Главная HPanel"})

    const router = useRouter()

    if (AuthStore.isAuth()) {
      router.push({name: "Login"})
    } else {
      router.push({name: "Dashboard"})
    }
  }
})
</script>

<style scoped>

</style>
