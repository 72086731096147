import StoreRegister from "@/plugins/store/services/StoreRegister"
import stores from "@/modules/auth/stores"

import RouteRegister from "@/plugins/router/services/RouteRegister"
import routers from "@/modules/auth/routers"
//import MyPlugin from "@/modules/auth/plugins/index"

import "./css/index.css"

export default {
    install() {
        StoreRegister.handle("auth", stores)
        RouteRegister.handle(routers)
        //app.use(MyPlugin);
    },
}
