<template>
  <div class="text-center mb-4">
    Страница не найдена!
  </div>
</template>

<script>
export default {
  name: "PathNotFound"
}
</script>

<style scoped>

</style>
