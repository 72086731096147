export default {
    namespaced: true,
    state: {
        userData: {},
        auth: false,
        tryAuth: false,
    },
    getters: {
        getUserData: (state) => {
            return state.userData
        },
        getAuth: (state) => {
            return state.auth
        },
        getTryAuth: (state) => {
            return state.tryAuth
        }
    },
    mutations: {
        setUserData: (state, payload) => {
            state.userData = payload
        },
        setAuth: (state, payload) => {
            state.auth = payload
        },
        markTryAuth: (state) => {
            state.tryAuth = true
        }
    },
}
