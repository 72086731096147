<template>
  <div class="card" id="button">
    <div class="card-body">
      <div class="divide-y">
        <label class="row">
          <span class="col">
            <span class="title">Кнопка</span>
          </span>
          <span class="col-auto">
            <label class="form-check form-check-single form-switch">
              <input class="form-check-input" type="checkbox" v-model="isEnable">
            </label>
          </span>
        </label>
      </div>

      <template v-if="isEnable">
        <div>
          <div class="mb-2 mt-2">
            <div class="row g-2">
              <div class="col-6">
                <input type="text" class="form-control" placeholder="Название" v-model="v$.name.$model" @keyup="onChange('name', $event)" :class="{'is-invalid': v$.name.$invalid && v$.name.$dirty}">
                <div class="invalid-feedback" v-for="error of v$.name.$silentErrors" :key="error.$uid">
                  {{ error.$message }}
                </div>
              </div>
            </div>
          </div>
          <div class="mb-2 mt-2">
            <div class="row g-2">
              <div class="col-6">
                <input type="text" class="form-control" placeholder="Ссылка" v-model="v$.link.$model" @keyup="onChange('link', $event)" :class="{'is-invalid': v$.link.$invalid && v$.link.$dirty}">
                <div class="invalid-feedback" v-for="error of v$.link.$silentErrors" :key="error.$uid">
                  {{ error.$message }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import {defineComponent, ref, watch, reactive, onMounted} from "vue"
import {useVuelidate} from "@vuelidate/core"
import {url} from "@/plugins/vuelidator"

export default defineComponent({
  name: "Button",
  props: {
    data: {
      type: Object,
      default: null,
    },
    validations: {},
  },
  setup(props, {emit}) {
    let isEnable = ref(false)

    let data = ref(props.data)

    let $externalResults = reactive({})

    const fields = reactive({
      name: "",
      link: "",
    })

    const rules = {
      name: {},
      link: {
        url,
      },
    }

    const v$ = useVuelidate(rules, fields, {$externalResults})

    onMounted(() => {
      if (!data.value["name"] && !data.value["link"]) {
        return false
      }

      enableStatus()
      initFields()
    })

    watch(() => props.validations, (val) => {
      validate(() => {
        if (val?.button !== undefined) {
          $externalResults.scheduled_removed = val["button"][0]
        }
      })
    })

    function enableStatus() {
      isEnable.value = true
    }

    function initFields() {
      fields.name = data.value["name"]
      fields.link = data.value["link"]
    }

    watch(() => isEnable.value, (status) => {
      if (status === false) {
        resetAllFields()
        changeEvent(false)
      }
    })

    function onChange(key, e) {
      fields[key] = e.target.value
      changeEvent(true)
    }

    function resetAllFields() {
      resetName()
      resetLink()
    }

    function resetName() {
      fields.name = ""
    }

    function resetLink() {
      fields.link = ""
    }

    function changeEvent(checkValidate = true) {
      if (checkValidate === true) {
        validate(changeEmit)
      } else {
        changeEmit()
      }
    }

    function validate(fn) {
      v$.value.$clearExternalResults()
      return v$.value.$validate().then((resp) => {
        if (resp === true) {
          fn()
        }
      })
    }

    function changeEmit() {
      emit("onChange", {
        "name": fields.name,
        "link": fields.link,
      })
    }

    return {
      onChange,
      isEnable,
      fields,
      v$,
    }
  }
})
</script>

<style scoped>

</style>
