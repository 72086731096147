<template>
  <template v-if="!isPending()">
    <div class="col-md-12" id="post-info">
      <div class="card card-sm">
        <div class="card-header" :class="getClassBg()">
          <div class="common-info">
            <div class="row align-items-center">
              <div class="col-auto">
                <span class="bg-primary text-white avatar">
                  <img width="50" :src="data.getUserAvatar(50, 50)">
                </span>
              </div>
              <div class="col">
                <div class="font-weight-medium">
                  <b>{{ data.getUsername() }}</b>
                </div>
                <div class="text-muted" @click="data.events.goToTelegramChannelLink()" v-html="data.getStatus()"></div>
              </div>
              <div class="col-1">
                <a href="#" @click.prevent="onMoreShow()">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-maximize" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 8v-2a2 2 0 0 1 2 -2h2"></path>
                    <path d="M4 16v2a2 2 0 0 0 2 2h2"></path>
                    <path d="M16 4h2a2 2 0 0 1 2 2v2"></path>
                    <path d="M16 20h2a2 2 0 0 0 2 -2v-2"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body" v-if="isMoreShow()">
          <div class="px-1 mb-3">
            <Actions :post-id="postId" />
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-if="isPending()">
    <div class="text-center p-1">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </template>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue"
import "./css/style.css"
import Api from "@/modules/post/components/info/services/Api"
import element from "./services/element"
import Image from "@/services/Image"
import DTBackend from "@/services/DTBackend"
import Helper from "@/services/Helper"
import Actions from "./components/actions/Actions"

export default defineComponent({
  name: "Index",
  props: {
    postId: {
      type: Number,
      required: true,
    },
  },
  components: {
    Actions,
  },
  setup(props) {
    let expand = ref(false)
    let pending = ref(true)
    let item = ref({})

    let data = {
      getUsername: () => {
        return element.getUsername(getItem())
      },
      getUserAvatar: (w, h) => {
        let host = element.getUserAvatarHost(getItem())
        let name = element.getUserAvatarName(getItem())

        return Image.handle(host, name, w, h)
      },
      status: {
        isPublished: () => {
          return element.getStatus(getItem()) === "published"
        },
        isScheduled: () => {
          return element.getStatus(getItem()) === "scheduled"
        },
        isDeleted: () => {
          return element.getStatus(getItem()) === "deleted"
        },
        isScheduledRemoved: () => {
          return element.getStatus(getItem()) === "scheduled"
        },
        isDraft: () => {
          return element.getStatus(getItem()) === "draft"
        },
        isPrePublished: () => {
          return element.getStatus(getItem()) === "prePublished"
        },
        isPreDeleted: () => {
          return element.getStatus(getItem()) === "preDeleted"
        },
        isCanceled: () => {
          return element.getStatus(getItem()) === "canceled"
        },
      },
      getStatus() {
        let result = ""
        let date = null

        if (this.status.isDeleted()) {
          result = "Удален"
          date = element.getRemovedAt(getItem())
        } else if (this.status.isPublished()) {
          let telegramChannelLink = element.getTelegramLink(getItem())

          if (telegramChannelLink) {
            result = "<span class='published'>Опубликован</span>"
          } else {
            result = "Опубликован"
          }

          date = element.getPublishedAt(getItem())
        } else if (this.status.isScheduled()) {
          result = "Запланирован"
          date = element.getScheduledPublished(getItem())
        } else if (this.status.isDraft()) {
          date = element.getCreatedAt(getItem())
          result = "Черновик"
        } else if (this.status.isPrePublished()) {
          date = element.getCreatedAt(getItem())
          result = "Публикуется..."
        } else if (this.status.isPreDeleted()) {
          date = element.getCreatedAt(getItem())
          result = "Удаляется..."
        } else if (this.status.isCanceled()) {
          date = element.getCreatedAt(getItem())
          result = "Отменен"
        }

        let dt = new DTBackend(date)

        result += " " + dt.get("%D.%M.%y <span>%H:%I</span>")

        result = "<b>" + result + "</b>"

        return result
      },

      events: {
        goToTelegramChannelLink() {
          if (data.status.isPublished()) {
            let telegramChannelLink = element.getTelegramLink(getItem())

            if (!telegramChannelLink) {
              return false
            }

            window.open(telegramChannelLink, '_blank');
          }
        }
      },
    }

    onMounted(() => {
      info()
    })

    function setItem(val) {
      item.value = val
    }

    function getItem() {
      return item.value
    }

    function info() {
      Api.info(props["postId"]).then((resp) => {
        setItem(resp)
        stopPending()
      })
    }

    function onMoreShow() {
      expand.value = !expand.value
    }

    function isMoreShow() {
      return expand.value === true
    }

    function stopPending() {
      pending.value = false
    }

    function isPending() {
      return pending.value
    }

    function getClassBg() {
      let item = getItem()
      
      return Helper.getClassBgItems(item["status"])
    }

    return {
      onMoreShow,
      isMoreShow,
      isPending,
      data,
      getClassBg,
    }
  },
})
</script>

<style scoped></style>