<template>
  <div class="statistic">
    <table class="table table-vcenter card-table table-striped">
      <tbody>
      <tr v-if="chart.hasClick()">
        <td colspan="3">
          <LinearChart :labelGraph="chart.getLabel()" nameGraph="Клики" :datasets="chart.getClick()" :color="chart.getColor()" />
        </td>
      </tr>

      <tr v-if="chart.hasSell()">
        <td colspan="3">
          <LinearChart :labelGraph="chart.getLabel()" nameGraph="Продажи" :datasets="chart.getSell()" :color="chart.getColor()" />
        </td>
      </tr>

      <tr v-if="!hasData() && !chart.getLoading()">
        <td colspan="3">Нет данных по статистике</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {defineComponent, onMounted, watch} from "vue"
import LinearChart from "@/components/graphics/LinearChart"
import clickSellChart from "./services/click_sell_chart"

export default defineComponent({
  name: "ClickSellChart",
  components: {
    LinearChart,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    const chart = clickSellChart();

    watch(() => chart.getLoading(), () => {
      emit("onAction", {
        "loading": chart.getLoading(),
      })
    })

    onMounted(() => {
      chart.getStatistic(
          props.data['post_id'],
          props.data['type'],
      )
    })

    function hasData() {
      return chart.hasClick() && chart.hasSell()
    }

    return {
      chart,
      hasData,
    }
  }
})
</script>
