<template>
    <div id="user-list">
        <div class="cards row row-cards">
            <template v-if="isNotFound(items) && !busy">
                <span class="items-not-found">Ничего не найдено</span>
            </template>
            <template v-else>
                <div class="col-md-6 col-lg-3" v-for="item in items" :key="item.id">
                    <div class="card">
                        <div class="card-body p-4 text-center">
                            <span class="avatar avatar-xl mb-3 rounded-circle" :style="{ backgroundImage: 'url(' + getImage(item.avatar_host, item.avatar_name, 60) + ')' }"></span>
                            <h3 class="m-0 mb-2">
                                <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" v-text="item.name"></a>
                            </h3>
                            <div class="text-secondary mb-2" v-text="item.login"></div>
                            <div class="mb-2">
                                <span class=" badge bg-purple-lt" v-text="item.role"></span>
                            </div>
                        </div>

                        <div class="d-flex">
                            <button @click="openEditModal(item)" class="card-btn">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon me-2 text-muted icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                <path d="M16 5l3 3" />
                                </svg>
                                Редактировать
                            </button>
                        </div>
                    </div>
                </div>
            </template>
            <div class="loader-posts">
                <div class="spinner-border" v-show="busy"></div>
            </div>
        </div>
        <Teleport to="body">
            <EditModal v-if="getSelectedUser() && getOpenedEditModal()" :user="getSelectedUser()" @close="closeEditModal" />
        </Teleport>
    </div>
</template>
  
<script>
import {defineComponent, ref, watch} from "vue"
import Helper from "@/services/Helper"
import Image from "@/services/Image";
import Api from "@/modules/user/components/list/services/Api"
import EditModal from "@/modules/user/components/edit/Index"
import "./css/style.css"
  
export default defineComponent({
    name: "List",
    components: {
        EditModal,
    },
    props: {
        query: {
            type: Object,
            required: true
        },
        clearResult: {
            type: Boolean,
            required: true
        }
    },
    setup(props, {emit}) {
        let busy = ref(false)
        let items = ref([])
        let eofItems = ref(false)
        let countItems = ref(0)
        let actions = ref([])

        watch(() => props.query, (query) => {
            busy.value = true

            if (props.clearResult) {
                clearResult()
            }

            load(query)
        })

        function clearResult() {
            eofItems.value = false
            countItems.value = 0
            items.value = []
        }
        
        function load(query, offset = null) {
            if (offset !== null) {
                query.offset = offset;
            }
        
            Api.users(query).then((resp) => {
                items.value.push(...resp.item)
        
                if (resp.item.length === 0) {
                    eofItems.value = true
                }
        
                countItems.value = resp.param.cnt
        
            }).finally(() => {
                busy.value = false
        
                emit("onRender", {
                    "oef_items": eofItems.value,
                    "count_items": countItems.value
                })
            })
        }
    
        function getImage(host, name, w = null, h = null) {
            return Image.handle(host, name, w, h)
        }
    
        function getImageStub() {
            return "/assets/static/promopushka/no_image.jpg"
        }
    
        function isNotFound(items) {
            return Helper.isEmptyObject(items)
        }

        let selectedUser = ref(false)
        let openedEditModal = ref(false)

        function openEditModal(user) {
            selectedUser.value = user.id
            openedEditModal.value = true
        }

        function closeEditModal() {
            selectedUser.value = false
            openedEditModal.value = false
        }

        function getOpenedEditModal() {
            return openedEditModal.value
        }

        function getSelectedUser() {
            return selectedUser.value
        }
    
        return {
            busy,
            items,
            isNotFound,
            actions,
            getImageStub,
            getImage,
            openEditModal,
            closeEditModal,
            getOpenedEditModal,
            getSelectedUser
        }
    }
})
</script>
  
<style scoped></style>
  