import Users from "@/modules/user/pages/Users"
import authMiddleware from "@/plugins/router/middlewares/auth"

export default [{
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
        middleware: authMiddleware,
    }
}, ]
