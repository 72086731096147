import $ from "../../../../packages/editor/src/components/dom";
import SelectionUtils from "../../../../packages/editor/src/components/selection";
import "./style.css";
/**
 * Bold Tool
 *
 * Inline Toolbar Tool
 *
 * Makes selected text bolder
 */
export default class CodeInlineTool {
    constructor({ api }) {
        this.state = false;
        /**
         * Styles
         */
        this.CSS = {
            button: 'ce-inline-tool',
            buttonActive: 'ce-inline-tool--active',
            buttonModifier: 'ce-inline-tool--code',
        };
        /**
         * Elements
         */
        this.nodes = {
            button: undefined,
        };
        this.api = api;
        this.selection = new SelectionUtils();
    }
    /**
     * Sanitizer Rule
     * Leave <b> tags
     *
     * @returns {object}
     */
    static get sanitize() {
        return {
            code: {},
        };
    }
    /**
     * Create button for Inline Toolbar
     */
    render() {
        this.nodes.button = document.createElement('button');
        this.nodes.button.type = 'button';
        this.nodes.button.classList.add(this.CSS.button, this.CSS.buttonModifier);
        let icon = $.make('span', ['icon--code'], {
            innerHTML: '<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-code" width="26" height="26" viewBox="0 0 26 26" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> ' +
                '<path stroke="none" d="M0 0h24v24H0z" fill="none"></path> ' +
                '<path d="M7 8l-4 4l4 4"></path> ' +
                '<path d="M17 8l4 4l-4 4"></path> ' +
                '<path d="M14 4l-4 16"></path> ' +
                '</svg>'
        });
        this.nodes.button.appendChild(icon);
        return this.nodes.button;
    }
    /**
     * Wrap range with <b> tag
     *
     * @param {Range} range - range to wrap
     */
    surround(range) {
        if (this.state) {
            this.unwrap(range);
            return;
        }
        this.wrap(range);
    }
    wrap(range) {
        const selectedText = range.extractContents();
        const mark = document.createElement('CODE');
        mark.appendChild(selectedText);
        range.insertNode(mark);
        this.api.selection.expandToTag(mark);
    }
    unwrap(range) {
        const mark = this.api.selection.findParentTag('CODE');
        const text = range.extractContents();
        mark.remove();
        range.insertNode(text);
    }
    /**
     * Check selection and set activated state to button if there are <b> tag
     *
     * @param {Selection} selection - selection to check
     *
     * @returns {boolean}
     */
    checkState(selection) {
        const mark = this.api.selection.findParentTag("CODE");
        this.state = !!mark;
        this.nodes.button.classList.toggle(this.CSS.buttonActive, this.state);
        return this.state;
    }
}
/**
 * Specifies Tool as Inline Toolbar Tool
 *
 * @returns import BoldInlineTool from "../../../editor/src/components/inline-tools/inline-tool-bold";{boolean}
 */
CodeInlineTool.isInline = true;
/**
 * Title for hover-tooltip
 */
CodeInlineTool.title = 'Code';
