import store from "@/plugins/store"
import Helper from "@/services/Helper";

export default new class Store {
    /**
     * Аунтифицирован ли пользователь?
     */
    isAuth() {
        return store.getters["auth/getAuth"]
    }

    /**
     * Пометить аутентификацию успешной.
     */
    markAuth() {
        return store.commit("auth/setAuth", true)
    }

    /**
     * Пометить аутентификацию неуспешной.
     */
    unMarkAuth() {
        return store.commit("auth/setAuth", false)
    }

    /**
     * Получить пользовательские данные.
     */
    getUserData() {
        const payload = store.getters["auth/getUserData"]

        return {
            id: payload["id"],
            name: payload["name"],
            role: payload["role"],
            avatar: payload["avatar"],
        };
    }

    /**
     * Записать пользовательские данные.
     */
    setUserData(payload) {
        let data = {}

        if (!Helper.isEmptyObject(payload)) {
            data = {
                id: payload["id"],
                name: payload["name"],
                role: payload["role"],
                avatar: {
                    "host": payload["avatar"]["host"],
                    "name": payload["avatar"]["name"],
                },
            }
        }

        return store.commit("auth/setUserData", data)
    }

    /**
     * Записать данные о пользователе в хранилище.
     */
    setDataLogin(payload) {
        this.setUserData(payload)
        this.markAuth()
    }

    /**
     * Очистить данные о пользователе в хранилище.
     */
    clearDataLogin() {
        this.setUserData([])
        this.unMarkAuth()
    }

    /**
     * Была ли попытка аутентификации?
     */
    isTryAuth() {
        return store.getters["auth/getTryAuth"]
    }

    /**
     * Зафиксировать попытку аутентификации.
     */
    markTryAuth() {
        return store.commit("auth/markTryAuth")
    }
}
