export default class Config {
    static data = JSON.parse(process.env.VUE_APP_CONFIG)

    static _(...params) {
        let result = undefined;

        Object.entries(params).forEach(entry => {
            const val = entry[1];

            if (!result) {
                result = this.data[val]
            } else {
                result = result[val]
            }
        })

        return result
    }
}
