<template>
  <div class="filter-user" id="filter-user" v-if="isEnable()">
      <div class="filter-user__search-name">
        <label class="form-label">Поиск по имени</label>
        <div class="input-icon">
          <input @keyup.enter="events.onSearch" type="text" v-model="bootData.user_name" class="form-control" placeholder="Напишите что-нибудь">
          <button class="btn input-icon-addon__button" @click="events.onSearch">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="10" cy="10" r="7" />
              <line x1="21" y1="21" x2="15" y2="15" />
            </svg>
          </button>
        </div>
      </div>
      <div class="filter-user__search-email">
        <label class="form-label">Поиск по email</label>
        <div class="input-icon">
          <input @keyup.enter="events.onSearch" type="text" v-model="bootData.user_email" class="form-control" placeholder="Напишите что-нибудь">
          <button class="btn input-icon-addon__button" @click="events.onSearch">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="10" cy="10" r="7" />
              <line x1="21" y1="21" x2="15" y2="15" />
            </svg>
          </button>
        </div>
      </div>
      <div class="filter-user__search-login">
        <label class="form-label">Поиск по логину</label>
        <div class="input-icon">
          <input @keyup.enter="events.onSearch" type="text" v-model="bootData.user_login" class="form-control" placeholder="Напишите что-нибудь">
          <button class="btn input-icon-addon__button" @click="events.onSearch">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="10" cy="10" r="7" />
              <line x1="21" y1="21" x2="15" y2="15" />
            </svg>
          </button>
        </div>
      </div>

      <div class="filter-user__role">
        <label class="form-label">Роль</label>
        <Multiselect @change="events.onPermissions" v-model="bootData.permission_ids" mode="tags" placeholder="Не выбрано" noResultsText="Результатов не найдено" :close-on-select="true" :options="getPermissions()">
          <template v-slot:tag="{ option, handleTagRemove, disabled }">
            <div class="multiselect-tag element" :class="{ 'is-disabled': disabled }">
              <div class="user-name">{{ option.name }}</div>
              <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                <span class="multiselect-tag-remove-icon"></span>
              </span>
            </div>
          </template>
          <template v-slot:option="{ option }">
            <div class="option-user">
              <div class="user-name"> {{ option.name }}</div>
            </div>
          </template>
        </Multiselect>
      </div>

      <div class="filter-user__buttons">
        <label class="form-label">Статус</label>
        <div class="form-selectgroup">
          <label class="form-selectgroup-item">
            <input @change="events.onSearch" type="checkbox" v-model="bootData.user_active" class="form-selectgroup-input">
            <span class="form-selectgroup-label">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-sun me-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="12" cy="12" r="4"></circle>
                <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"></path>
              </svg>
              Активные
            </span>
          </label>
          <label class="form-selectgroup-item">
            <input @change="events.onSearch" type="checkbox" v-model="bootData.user_not_active" class="form-selectgroup-input">
            <span class="form-selectgroup-label">
              <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-sun-off me-1"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 3l18 18" /><path d="M16 12a4 4 0 0 0 -4 -4m-2.834 1.177a4 4 0 0 0 5.66 5.654" /><path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7" /></svg>
              Не активные
            </span>
          </label>
          <label class="form-selectgroup-item">
            <input @change="events.onSearch" type="checkbox" v-model="bootData.user_fantom" class="form-selectgroup-input">
            <span class="form-selectgroup-label">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-ghost-2">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M10 9h.01" />
                <path d="M14 9h.01" />
                <path d="M12 3a7 7 0 0 1 7 7v1l1 0a2 2 0 1 1 0 4l-1 0v3l2 3h-10a6 6 0 0 1 -6 -5.775l0 -.226l-1 0a2 2 0 0 1 0 -4l1 0v-1a7 7 0 0 1 7 -7z" />
                <path d="M11 14h2a1 1 0 0 0 -2 0z" />
              </svg>
            </span>
          </label>
          <label class="form-selectgroup-item">
            <input @change="events.onSearch" type="checkbox" v-model="bootData.user_delst" class="form-selectgroup-input">
            <span class="form-selectgroup-label">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="4" y1="7" x2="20" y2="7"></line>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
            </span>
          </label>
        </div>
      </div>
  </div>
</template>

<script>
import "./css/style.css"
import Multiselect from "@/plugins/multiselect"
import {defineComponent, onMounted, reactive, ref} from "vue"
import Api from "@/modules/user/components/filter/services/Api"

export default defineComponent({
  name: "Filter",
  components: {
    Multiselect
  },
  props: {
    enable: {
      type: Boolean,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
  },
  setup(props, {emit}) {
    let bootData = reactive({
      user_name: props.params.user_name ?? '',
      user_email: props.params.user_email ?? '',
      user_login: props.params.user_login ?? '',
      permission_ids: props.params.permission_ids ?? '',
      user_active: props.params.user_active ?? true,
      user_not_active: props.params.user_not_active ?? false,
      user_fantom: props.params.user_fantom ?? false,
      user_delst: props.params.user_delst ?? false
    })
    
    let permissionsArray = ref([])

    const events = {
      onSearch: () => {
        onFilter()
      },
      onPermissions: (value) => {
        bootData.permission_ids = value.join(',')
        onFilter()
      },
    }

    onMounted(() => {
      onFilter()
      permissions()
    })

    function onFilter() {
      emit("onFilter", getResult())
    }

    function getResult() {
      let data = {}
      if (bootData.user_name) data.user_name = bootData.user_name
      if (bootData.user_email) data.user_email = bootData.user_email
      if (bootData.user_login) data.user_login = bootData.user_login
      if (bootData.permission_ids) data.permission_ids = bootData.permission_ids
      
      if (bootData.user_active && !bootData.user_not_active) {
        data.user_active = bootData.user_active
      } else if (!bootData.user_active && bootData.user_not_active) {
        data.user_active = false
      }

      if (bootData.user_fantom) data.user_fantom = bootData.user_fantom
      data.user_delst = bootData.user_delst
      return data
    }

    function isEnable() {
      return props.enable === true
    }

    function permissions() {
      return Api.permissions().then((resp) => {
        permissionsMultiSelect(resp.item)
      })
    }

    function permissionsMultiSelect(items) {
      let data = []
      for (let prop in items) {
        let obj = items[prop]

        data.push({
          value: obj.permission_id,
          name: obj.permission_name,
        })
      }
      permissionsArray.value = data;
    }

    function getPermissions() {
      return permissionsArray.value
    }

    return {
      events,
      bootData,
      isEnable,
      getPermissions
    }
  }
})
</script>

<style scoped></style>
